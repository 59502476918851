.gated{
	max-height:900px;
	overflow:hidden;
	position:relative; 
	&:after{
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
		content:'';
		display:block;
		position: absolute;
		bottom:0;
		min-height:400px;
		width:100%;
	}
}

.gated--form{
	position: absolute;
	z-index:100;
	bottom:$baseline*2;
	width:100%;
	z-index:$zIndex-1;
}

.feature-report{
	position:relative;	
}

.feature-report__container{
	position:relative;
	overflow:hidden;
	@media (min-width: $bp-medium){
		min-height:500px;
	}
}

.feature-report__image{
	@media (min-width: $bp-medium){
		position: absolute;
		max-width: none;
		min-width: 100%;
		min-height: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}