// Typography
//
// Style guide: typography

// Primary font
//
// <div class="row primary-font">
//   <div class="col large-6 soft-right--large"> 
//     <p>For general body copy, and tables and charts, we use Roboto.</p>
//     <p>Roboto is an open source font and can be downloaded from <a href="https://fonts.google.com/specimen/Roboto">Google Fonts</a></p>
//   </div>
//   <div class="col small-6 large-3 primary-color">
//     <div class="peta">Aa1</div>
//     <div class="primary-font">Regular</div>
//  </div>
//   <div class="col small-6 large-3 primary-color">
//     <div class="peta">Aa1</div>
//     <div class="beta font-bold">Bold</div>
//   </div>
// </div>
//
// Weight: 1
//
// Style guide: typography.Primary font

// Weights
//
// <h2>Font weight classes</h2>
// <p>These classes can be added to any element to display primary or secondary fonts in different weights.</p>
// <div class="primary-color">
//   <div class="mega font-light push-half--bottom"> .font-light </div>
//   <div class="mega font-regular push-half--bottom"> .font-regular </div>
//   <div class="mega font-semibold push-half--bottom"> .font-semibold </div>
//   <div class="mega font-bold push-half--bottom"> .font-bold </div>
//   <div class="mega font-black push-half--bottom"> .font-black </div>
// </div>
//
// Weight: 3
//
// Style guide: typography.Weights

// Sizes
//
// <h2>A modular scale</h2>
// <p>The term Modular Scale refers to a series of harmonious values. As part of the patterns library _typography.scss has a default responsive modular scale tied to the classes below. Note, this is for illutrative purposes only. Each project and a suitable modular scale should be just on an individual basis. </p>
// <div class="primary-color">
//   <div class="peta push-half--bottom"> .Peta </div>
//   <div class="tera push-half--bottom"> .Tera </div>
//   <div class="giga push-half--bottom"> .Giga </div>
//   <div class="mega push-half--bottom"> .Mega </div>
//   <div class="kilo push-half--bottom"> .Kilo </div>
//   <div class="alpha push-half--bottom"> .Alpha </div>
//   <div class="beta push-half--bottom"> .Beta </div>
//   <div class="gamma push-half--bottom"> .Gamma </div>
//   <div class="delta push-half--bottom"> .Delta </div>
//   <div class="epsilon push-half--bottom"> .Epsilon </div>
// </div>
//
// Weight: 4
//
// Style guide: typography.Sizes

// Blockquotes
//
//Markup:
//<blockquote class="blockquote push-double--bottom">
//    <p class="blockquote__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ergo id est convenienter naturae vivere, a natura discedere. Quamquam te quidem video minime esse deterritum</p>
//    <div class="avatar push--bottom">
//        <img class="avatar__image" alt="" src="/content/img/placeholders/placeholder-avatar.jpg">
//        <div class="avatar__content">
//           <h4 class="primary-font--regular"><a href="">Sarah Jenkinson</a></h4>
//            <p class="">Lead analyst, London</p>
//        </div>
//    </div>  
//</blockquote>
//<blockquote class="blockquote">
//   <p class="blockquote__text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ergo id est convenienter naturae vivere, a natura discedere. Quamquam te quidem video minime esse deterritum</p>
//   <img class="blockquote__logo" src="/content/img/placeholders/blockquote-logo-ft.jpg">
//   <time class="">17 February 2017</time>
//</blockquote>
//
// Weight: 6
//
// Style guide: typography.Blockquotes

// Title decoration
//
//Markup:
//<h2 class="mega title-underline soft--bottom push--bottom">Related content</h2>
//Markup:
//<h2 class="tera title-underline title-underline--long soft--bottom push--bottom">We turn complex challenges into profitable opportunities</h2>
//
// Weight: 6
//
// Style guide: typography.Title decoration


body,
button,
input,
select,
textarea {
    font:$base-font-weight $base-font-size $primary-font;
    line-height:$base-line-height;
    color:$body-font-color;
}

.primary-font{
    font-family:$primary-font;
}

.primary-font--light {
    font-weight:$light;
}

.primary-font--regular{
    font-weight:$regular;
}

.primary-font--semibold{
    font-weight:$semibold;
}

.primary-font--bold{
    font-weight:$bold;
}

.primary-font--black{
    font-weight:$thick;
}
// = Typefaces
//-----------------------------------------------------------------------------//

.font-light {
    font-weight:$light;
    }

.font-regular {
    font-weight:$regular;
    }

.font-semibold {
    font-weight:$semibold;
    }

.font-bold {
    font-weight:$bold;
    }

.font-black {
    font-weight:$thick;
    }

// = Text Align
//-----------------------------------------------------------------------------//

.text-center {
    text-align:center;
    }
.text-left{
    text-align:left;
    }

.text-right {
    text-align:right;
    }

.uppercase {
    text-transform:uppercase;
    }
    
.letterspace {
    letter-spacing:2px;
    }

.bold,
strong,
b {
    font-weight:$bold;
    }

.italic{
    font-style:italic;
}

time{
    display:block;
}

// Modular scale
//-----------------------------------------------------------------------------//

@mixin peta {
    font-size:2rem;
    line-height:1.05;
    @media (min-width: $bp-medium){
        font-size:3rem;
        line-height:1.2;
        }
    }

.peta {
    @include peta;
    }

@mixin tera {
    font-size:1.7rem;
    line-height: 1.2;
    @media (min-width: $bp-medium){
        font-size:1.8rem;
        }
    @media (min-width: $bp-large){
        font-size: 2rem;
        }
    @media (min-width: $bp-xlarge){
        font-size: 2.4rem;
        }
    }

.tera {
    @include tera;
    }

@mixin giga {
    font-size:1.4rem;
    line-height:1.3;
    p {
        margin-bottom:1.5rem;
        }
    @media (min-width: $bp-medium){
        font-size:1.6rem;
        p {
            margin-bottom:2.375rem;
            }
        }
    @media (min-width: $bp-large){
        font-size:1.7rem;
        }
    @media (min-width: $bp-xlarge){
        font-size:1.9rem;
        }
        p {
            margin-bottom:2.5rem;
            }
        }

.giga {
    @include giga;
    }

@mixin mega {
    font-size:1.3rem;
    line-height: 1.3;
    @media (min-width: $bp-large){
        font-size:1.4rem;
        }
    }

 .mega {
    @include mega;
    }

@mixin kilo {
    font-size:1.2rem;
    line-height: 1.3;
    @media (min-width: $bp-large){
        font-size:1.3rem;
        }
    }

 .kilo {
    @include kilo;
    }

@mixin alpha {
    font-size:1.1rem;
    line-height: 1.4;
    @media (min-width: $bp-large){
        font-size:1.2rem;
        }
    }

 .alpha {
    @include alpha;
    }

@mixin beta {
    font-size:1.04rem;
    line-height:1.6;
    }

.beta {
    @include beta;
    }

@mixin gamma {
    font-size:0.875rem;
    line-height:1.4;
    }

.gamma {
    @include gamma;
    }

@mixin delta {
    font-size:0.75rem;
    line-height:1.4;
    }

.delta {
    @include delta;
    }

@mixin epsilon {
    font-size:0.625rem;
    line-height:1.4;
    }

.epsilon {
    @include epsilon;
    }

// = Styling
//-----------------------------------------------------------------------------//

.section-heading{
    @include mega;
    position:relative;
    margin-bottom:$baseline /2;

    span{
        background:$white;
        padding-right:$baseline;
    }

    &:after{
        background:$light-grey-2;
        content:'';
        display:block;
        height:1px;
        width:100%;
        position:absolute;
        top:50%;
        z-index:-1;
    }
}

h1, h2, h3, h4, h5 {
    font-weight:$semibold;
    }

a,.link {
    color:$link;
    &:hover {
        color:$link-hover;
        text-decoration:none;
        }
    }

.mono-link,
a.mono-link,
.mono-link a{
    color:$white;
}

.mono-link:hover,
a.mono-link:hover,
.mono-link a:hover{
    color:tint($off-black, 92%);
}

.dark-link{
    color:$off-black;
    &:hover{
        color:tint($off-black, 30%);
    }
}

.line-link {
    border-top: 2px solid tint($off-black, 90%);
    -webkit-transition: 0.5s;
    transition: 0.5s;

    &:hover{
        border-top: 2px solid $secondary-color;
    }
}

.text--center{
    text-align:center;
}

.tracking{
    letter-spacing: -2px;
}

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.title-pull-up{
    margin-top:-$baseline/2;
}

.circle {
    @include border-radius(100px);
}

//font-fallback
.wf-loading:not(.no-js) * {
    font-family:sans-serif;
}

.blockquote { 
    figcaption{
        @include alpha;
        color:$tertiary-color;
    }
    .avatar{
        margin-top:$baseline *3;    
    }
}

.blockquote--centered { 
    padding:0;
    @media (min-width: $bp-small-medium){
        padding:$baseline*2;
    }
}

.blockquote__text {
    @include mega;
    @media (min-width: $bp-xlarge){
        @include giga;
    }
    color:$primary-color;
    display:block;
    font-weight:$light;
    line-height:1.4;
    position:relative;
    margin-bottom:$baseline * 2;
    margin-top:$baseline * 2;
    &:before,
    &:after  {
        color:$light-grey-2;
        display:block;
        font-family:$primary-font;
        font-size:9rem;
        font-weight:$light;
        left:-$baseline / 4;
        line-height:0;
        position:absolute;
    }
    &:before {
        content:'\201C';
        top:1rem;
    }
    &:after  {
        bottom:-4.5rem;
        content:'\201D';
    }
}

.blockquote__logo-wrap {
	border-top:1px solid $light-grey-2;
	margin-top:$baseline *3;
	padding-top:$baseline;     
}

.blockquote__logo {
    margin-bottom:$baseline/2;
    max-width:160px;
    @media (min-width: $bp-small-medium){
        max-width:200px;
    }
}

.title-underline {
    position:relative;
    &:before{
        background:$secondary-color;
        content:'';
        display:block;
        position: absolute;
        left:0;
        bottom:0;
        width:60px;
        height:3px;
        @media (min-width: $bp-medium){
            width:120px;
        }
    }
}

.title-underline--long {
    &:before{
        width:120px;
        @media (min-width: $bp-medium){
            width:200px;
        }
    }
}

.fit-text {
     width: 100%;
     display: block;
 }
 .fit-text__content {
    fill: #222;
 }

 .js-fit {
     opacity:0;
        &.is--fitted {
            opacity:1;
        }
 }

.status{
    @include beta;
    background:100%;
    letter-spacing: 5px;
    margin-bottom:$baseline *8;
    position:relative;
    text-transform:uppercase;
    margin-left:auto;
    margin-right:auto;
    @media (min-width: $bp-medium){
        width:60%;
    }

    p{
        background:$light-grey-1;
        display:inline-block;
        padding:0 $baseline;
        position:relative;
        z-index:2;
    }

    &:after{
        background:$light-grey-2;
        content:'';
        position:absolute;
        left:0;
        right:0;
        top:50%;
        height:1px;
    }
}

.status--error{
    color:$red;
}

.status--searching{
    animation: blink-animation 1s linear infinite;
    color:$green;
}

.status--empty{
    color:$dark-grey-2;
}