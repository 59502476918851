.avatar {
	border-top:1px solid $light-grey-2;
	margin-top:$baseline;
	padding-top:$baseline;      
	position:relative;
	min-height:50px;
}

.avatar__image {
    position: absolute;
    width: 50px;
    height: 50px;
}

.avatar__content{
	margin-left: $baseline*3;	
}

.avatar--large{
	min-height:94px;
	.avatar__image{
	    width: 94px;
	    height: 94px;
	}
	.avatar__content{
		margin-left: $baseline*4.5;	
	}
}