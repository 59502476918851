// Aspect ratios
//
// <p>A sass mixin to maintain an elements aspect ratio, even as it scales.</p>
//
// <p>Using these classes along with an image helps maintain a consistent layout before the image has loaded.</p>
//
// Style guide: aspect-ratio

// 5x3
//
// <p>Add the class `.ratio-5x3`</p>
//
// Markup:
//  <div class="row">
//      <div class="col small-12 medium-8">
//          <div class="clearfix ratio-5x3 bg-light-grey-2 relative">
//              <div class="giga font-semibold vertical-align vertical-align--centered soft inline-block text-center">5x3</div>
//          </div>
//      </div>
//  </div>
//
// Weight: 1
//
// Style guide: aspect-ratio.5x3

// 2x1
//
// <p>Add the class `.ratio-2x1`</p>
//
// Markup:
//  <div class="row">
//      <div class="col small-12 medium-8">
//          <div class="clearfix ratio-2x1 bg-light-grey-2 relative">
//              <div class="giga font-semibold vertical-align vertical-align--centered soft inline-block text-center">2x1</div>
//          </div>
//      </div>
//  </div>
//
// Weight: 2
//
// Style guide: aspect-ratio.2x1

// 1x1
//
// <p>Add the class `.ratio-1x1`</p>
//
// Markup:
//  <div class="row">
//      <div class="col small-12 medium-8">
//          <div class="clearfix ratio-1x1 bg-light-grey-2 relative">
//              <div class="giga font-semibold vertical-align vertical-align--centered soft inline-block text-center">1x1</div>
//          </div>
//      </div>
//  </div>
//
// Weight: 3
//
// Style guide: aspect-ratio.1x1

// 21x9
//
// <p>Add the class `.ratio-21x9`</p>
//
// Markup:
//  <div class="row">
//      <div class="col small-12 medium-8">
//          <div class="clearfix ratio-21x9 bg-light-grey-2 relative">
//              <div class="giga font-semibold vertical-align vertical-align--centered soft inline-block text-center">21x9</div>
//          </div>
//      </div>
//  </div>
//
// Weight: 3
//
// Style guide: aspect-ratio.21x9

// = Ratios
//-----------------------------------------------------------------------------//


.ratio-5x29{
    @include maintain-ratio(5 2.9);
}

.ratio-5x3{
    @include maintain-ratio(5 3);
}

.ratio-2x1{
    @include maintain-ratio(2 1);
}

.ratio-1x1{
    @include maintain-ratio(1 1);
}

.ratio-21x9{
    @include maintain-ratio(21 9);
}

.ratio-1x1-5x3{
    @include maintain-ratio(1 1);
    @media (min-width: $bp-small-medium){
        @include maintain-ratio(5 3);
    }     
}

.ratio-5x3-1x1{
    @include maintain-ratio(5 3);
    @media (min-width: $bp-small-medium){
        @include maintain-ratio(1 1);
    }     
}