// placeholder
// usage: @include placeholder(#000);
@mixin placeholder($color) {
    ::-webkit-input-placeholder {color:$color;}
    ::-moz-placeholder {color:$color;} /* FF 19+ */
    :-moz-placeholder {color:$color;} /* FF 18- */
    :-ms-input-placeholder {color:$color;}
}

// = Border radius
//-----------------------------------------------------------------------------//

@mixin border-radius($radius: $radius) {
    border-radius: ($radius);
}

// = Maintain ratio
//-----------------------------------------------------------------------------//
@mixin maintain-ratio($ratio: 1 1) {
  @if length($ratio) < 2 or length($ratio) > 2 {
    @warn "$ratio must be a list with two values.";
  }

  $width: 100%;
  $height: percentage(nth($ratio, 2) / nth($ratio, 1));

  width: $width;
  height: 0;
  padding-bottom: $height;
}

//keyframes
//usage @include keyframes(slide-down) { 0% { opacity: 1; }  90% { opacity: 0; }}
@mixin keyframes($animation-name) {
  @-webkit-keyframes $animation-name {
    @content;
  }
  @-moz-keyframes $animation-name {
    @content;
  }  
  @-ms-keyframes $animation-name {
    @content;
  }
  @-o-keyframes $animation-name {
    @content;
  }  
  @keyframes $animation-name {
    @content;
  }
}

//animation
//usage @include animation('slide-down 5s 3');
@mixin animation($str) {
    backface-visibility: hidden; 
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};      
}

//columnise, css columns
//usage @include columnise(2, 20px);
@mixin columnise($num, $gap) {
    columns: $num;
    column-gap: $gap;
}

// clearfix
// usage: @include clearfix;
@mixin clearfix {
 
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  }

//visible
// usage: @include visible;
@mixin visible {
    position: static;
    visibility: visible;
    width: auto;
    height: auto;
    padding: 0;
    margin: auto;
    overflow:inherit;
    clip:auto;
}

// image replacement
// usage: @include ir;
@mixin ir {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
}

//vertical align
@mixin vertical-align($position: absolute) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
    }

// = Colors Tint/Shade
//-----------------------------------------------------------------------------//

// Add percentage of white to a color
@function tint($color, $percent){
    @return mix(white, $color, $percent);
}

// Add percentage of black to a color
@function shade($color, $percent){
    @return mix(black, $color, $percent);
}

@mixin transitions {    
    transition: all 0.25s ease-in-out;
    }