.nav-actions{
	padding-bottom:$baseline/2;
}

.nav-actions__link{
	display:inline-block;
	margin-right:$baseline/4;
}

.nav-actions__follow{
	&:hover {
		.nav-actions__shape {
			fill: darken($secondary-color, 10%);
		}
		.nav-actions__path,
		.nav-actions__text {
			fill: $white;
		}
	}
}

.nav-actions--inline{
	position: absolute;
	left:0;
	top:0;
	@media (min-width: $bp-large){
		left:-$baseline*3;
	}
}

.nav-actions--block{
	position:static;
	@media (min-width: $bp-large){
		position: absolute;
	}
}

.nav-actions__heading{
	@include mega;
	padding-left:$baseline*2;
	margin-bottom:$baseline;
	margin-top:$baseline/4;
	@media (min-width: $bp-large){
		margin-bottom:$baseline/2;
		margin-top:0;
		padding:0;
	}
}