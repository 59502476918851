.expert{
	background-color:$light-grey-1; 
}

.expert__panel{
	@include clearfix;
	padding:$baseline 0;
	position:relative;
    @media (min-width: $bp-medium){
        padding:$baseline*2 0 $baseline*2 0;
    }   
}

.expert__card{
	margin-left:$gutter/2;
	margin-right:$gutter/2;
	margin-top:$baseline;
	@media (min-width:$bp-small-medium){
	    &:after {
	        background: linear-gradient(to bottom, rgba(0,0,0,0) 20%,rgba(0,0,0,0.8) 100%);
	        bottom:0;
	        content:'';
	        left:0;
	        opacity:1;
	        position:absolute;
	        right:0;
	        top:0;
	        transition:opacity .5s ease;
	        z-index:1;
		}
	}
    @media (min-width: $bp-medium){
		position: absolute;
		right:$gutter;
		top:-$baseline*2;
		max-width:300px;
    }    
}

.expert__title{
    font-size:2rem;
    line-height:1.05;
    @media (min-width: $bp-medium){
        font-size:2.4rem;
        line-height:1.2;
	}
    @media (min-width: $bp-large){
        font-size:3rem;
	}
}

.expert__content{
	padding-bottom:$baseline;
    @media (min-width: $bp-medium){
        padding:$baseline/2 0;
    }  
    @media (min-width: $bp-large){
        padding:$baseline 0;
    } 	
}
.expert__contact{
	margin-top:$baseline/2;
    @media (min-width: $bp-small-medium){
		bottom:0;
		margin:0;
		padding:$baseline;
		position: absolute;
		z-index:2;
    }    
}