// Icons
//
// Style guide: Icons

// System Icons
//
// These are the icons included by default for use in the user interface. The icons are SVG based, so there is no pixelation or blurring on high-resolution screens as there would be if the graphic was raster and needed to scale up. Inline SVG icons are used which allow multi-colour icons and greater control over individual parts of the icon.
//
//  <ul class="row text--center hard list--plain">
//    <li class="col small-4 medium-2 icon-box push--bottom bg-light-grey-1 mid-grey-3 gamma soft-half relative"> <i class="block vertical-align vertical-align--centered"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><title>Group</title><path fill="none" stroke="#333" stroke-width="2" stroke-miterlimit="10" d="M0 0l20 20M20 0L0 20"/></svg></i> Close </li>
//    <li class="col small-4 medium-2 icon-box push--bottom bg-light-grey-1 mid-grey-3 gamma soft-half relative"> <i class="block vertical-align vertical-align--centered"><svg class="" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38"><path fill="#FFF" d="M0 0h38v38H0V0z"></path><title>Expand</title><path fill="#2197CA" d="M36.6 1.4v35.2H1.4V1.4h35.2M38 0H0v38h38V0z"></path><path fill="#2197CA" d="M6.9 6.3c-.3.1-.6.3-.6.7v4.5c0 .4.3.7.6.7.4 0 .7-.3.6-.7v-3l6.7 6.7c.2.2.7.3.9 0 .2-.2.2-.7 0-.9L8.6 7.6h3c.4 0 .7-.3.7-.6 0-.4-.3-.7-.7-.6l-4.7-.1c.1 0 0 0 0 0zm19.4 0c-.4 0-.6.3-.6.7 0 .4.3.6.7.6h3l-6.7 6.7c-.3.2-.3.7 0 .9.2.2.7.2.9 0l6.7-6.7v3c0 .4.3.7.6.7.4 0 .7-.3.6-.7V7c0-.4-.3-.6-.6-.6h-4.6c.1-.1.1-.1 0-.1zM14.7 22.5c-.1 0-.3.1-.4.2l-6.7 6.7v-3c0-.4-.3-.7-.7-.7-.3 0-.6.3-.6.7v4.5c0 .4.3.6.6.6h4.5c.4 0 .7-.3.7-.6 0-.4-.3-.7-.7-.6h-3l6.7-6.7c.2-.2.3-.5.1-.8 0-.2-.3-.3-.5-.3zm8.4 0c-.2 0-.5.2-.5.4-.1.2 0 .5.2.7l6.7 6.7h-3c-.4 0-.7.3-.7.6 0 .4.3.7.7.6H31c.4 0 .6-.3.6-.6v-4.5c0-.4-.3-.7-.7-.7-.3 0-.6.3-.6.7v3l-6.7-6.7c-.1-.1-.3-.2-.5-.2z"></path></svg></i> Expand </li>
//    <li class="col small-4 medium-2 icon-box push--bottom bg-light-grey-1 mid-grey-3 gamma soft-half relative"> <i class="block vertical-align vertical-align--centered"><svg class="" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16"><title>Search</title><path fill="#4D4D4D" d="M15.6 14.9L10.8 10c1.1-1.1 1.8-2.5 1.8-4.2C12.6 2.6 10 0 6.8 0S1 2.6 1 5.8c0 3.2 2.6 5.8 5.8 5.8 1 0 1.9-.3 2.7-.7l5 5.1 1.1-1.1zm-13-9.1c0-2.3 1.9-4.2 4.2-4.2S11 3.5 11 5.8 9.1 10 6.8 10 2.6 8.2 2.6 5.8z"></path></svg></i> Search </li>
//    <li class="col small-4 medium-2 icon-box push--bottom bg-light-grey-1 mid-grey-3 gamma soft-half relative"> <i class="block vertical-align vertical-align--centered"><svg width="44" height="60"><polyline points="30 10 10 30 30 50" stroke="#333333" stroke-width="2" stroke-linecap="butt" fill="none" stroke-linejoin="round"></polyline></svg></i> Large left </li>
//    <li class="col small-4 medium-2 icon-box push--bottom bg-light-grey-1 mid-grey-3 gamma soft-half relative"> <i class="block vertical-align vertical-align--centered"><svg role="button" width="44" height="60"><polyline points="14 10 34 30 14 50" stroke="#333333" stroke-width="2" stroke-linecap="butt" fill="none" stroke-linejoin="round"></polyline></svg></i> Large right </li>
//    <li class="col small-4 medium-2 icon-box push--bottom bg-light-grey-1 mid-grey-3 gamma soft-half relative"> <i class="block vertical-align vertical-align--centered"><svg class="icon-pull-up" xmlns="http://www.w3.org/2000/svg" width="25" height="19" viewBox="0 0 25 19"><title>cart</title><g fill="#333"><circle cx="10.9" cy="17.5" r="1.5"></circle><path d="M6.4 3.6L5 0H.2L1 1.9h3.1l.4 1.8 2.8 10.5h15.1l1.8-10.5H6.4zm14.2 8.7H8.9L6.8 5.5H22l-1.4 6.8z"></path><circle cx="19.2" cy="17.5" r="1.5"></circle></g></svg></i> Cart </li>
//    <li class="col small-4 medium-2 icon-box push--bottom bg-light-grey-1 mid-grey-3 gamma soft-half relative"> <i class="block vertical-align vertical-align--centered"><a class="inline-block" href=""><svg class="btn-delete" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><title>Delete</title><path class="outline" fill="#15A3DE" d="M17 1v16H1V1h16m1-1H0v18h18V0z"></path><path fill="#15A3DE" d="M4.066 4.123L4.49 3.7l9.688 9.686-.424.425z"></path><path fill="#15A3DE" d="M13.72 3.675l.423.424-9.687 9.687-.424-.425z"></path></svg></a></i> Delete </li>
//    <li class="col small-4 medium-2 icon-box push--bottom bg-light-grey-1 mid-grey-3 gamma soft-half relative"> <i class="block vertical-align vertical-align--centered"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14.8px" height="9.5px" viewBox="0 0 14.8 9.5" enable-background="new 0 0 14.8 9.5" xml:space="preserve"><polygon fill="#00A0C4" points="7.4,5.2 2.2,0 0,2.2 7.4,9.5 14.8,2.2 12.6,0 "></polygon></svg></i> Down </li>
//    <li class="col small-4 medium-2 icon-box push--bottom bg-light-grey-1 mid-grey-3 gamma soft-half relative"> <i class="block vertical-align vertical-align--centered"><svg class="" width="13.9" height="17.2" viewBox="0 0 13.9 17.2"><title>Document</title><g fill="#232323"><path d="M13.8 3.5L10.3.2c-.1-.2-.2-.2-.3-.2H1.5C.7 0 0 .7 0 1.5v14.2c0 .8.7 1.5 1.5 1.5h10.9c.8 0 1.5-.7 1.5-1.6V3.8c0-.1 0-.2-.1-.3zm-3.4-1.8l1.7 1.6h-1.7V1.7zm2 14.4H1.5c-.2 0-.5-.2-.5-.5V1.5c0-.2.3-.5.5-.5h7.8v2.9c0 .2.2.5.5.5h3.1v11.3c0 .2-.2.4-.5.4z"></path><path d="M11.1 6.3H3.7c-.2 0-.5.2-.5.5s.2.5.5.5H11c.3 0 .6-.2.6-.5s-.3-.5-.5-.5zM11.1 12.6H3.7c-.2 0-.5.2-.5.5 0 .2.2.5.5.5H11c.3 0 .6-.2.6-.5-.1-.3-.3-.5-.5-.5zM3.7 10.4H11c.3 0 .6-.2.5-.5 0-.2-.2-.5-.5-.5H3.6c-.2 0-.5.2-.5.5 0 .2.2.5.6.5z"></path></g></svg></i> Document </li>
//  </ul>
//
// Weight: 1
//
// Style guide: Icons.System Icons

// Action Icons
//
// Markup:
// <nav class="nav-actions">
//	<div class="inline-block relative">
//		<a class="nav-actions__link js-toggle-local" href="#share" role="button" aria-controls="share" aria-expanded="false">
//			<svg class="btn-svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><title>Share</title><circle fill="#2297C9" cx="27" cy="28" r="20" transform="translate(-7 -8)"></circle><path fill="#FFF" d="M24 15.7c1.6 0 3-1.3 3-2.9 0-1.6-1.3-2.9-3-2.9-1.6 0-3 1.3-3 2.9 0 .3.1.6.2.9L16 17.4c-.5-.5-1.3-.8-2.1-.8-1.6 0-3 1.3-3 2.9 0 1.6 1.3 2.9 3 2.9.8 0 1.6-.3 2.1-.8l5.2 3.7c-.1.3-.2.6-.2.9 0 1.6 1.3 2.9 3 2.9 1.6 0 3-1.3 3-2.9 0-1.6-1.3-2.9-3-2.9-.8 0-1.6.3-2.1.8l-5.2-3.7c.1-.3.2-.6.2-.9 0-.3-.1-.6-.2-.9l5.2-3.7c.6.5 1.3.8 2.1.8zm0-4.4c.9 0 1.6.7 1.6 1.5 0 .9-.7 1.5-1.6 1.5-.9 0-1.6-.7-1.6-1.5s.7-1.5 1.6-1.5zM14 21c-.9 0-1.6-.7-1.6-1.5 0-.9.7-1.5 1.6-1.5.9 0 1.6.7 1.6 1.5S14.8 21 14 21zm10 3.5c.9 0 1.6.7 1.6 1.5 0 .9-.7 1.5-1.6 1.5-.9 0-1.6-.7-1.6-1.5s.7-1.5 1.6-1.5z"></path></svg>
//		</a>
//		<span id="share" class="panel-share panel-toggle">
//			<a href="" class="panel-share__link"><svg class="panel-share__svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 45 40"><path fill="#1AA3DE" d="M34.5 25.5c0 .5-.1.9-.4 1.3L26.5 19l7.5-6c.3.4.5.9.5 1.5v11zm-12-5l10.4-8.4c-.4-.2-.9-.3-1.4-.3h-18c-.5 0-1 .1-1.4.3l10.4 8.4zm2.9-.5L23 21.9c-.1.1-.3.2-.5.2s-.4-.1-.5-.2L19.6 20l-7.7 7.9c.5.3 1 .4 1.6.4h18c.6 0 1.1-.2 1.6-.4L25.4 20zM11 13c-.3.4-.5.9-.5 1.5v11.1c0 .5.1.9.4 1.3l7.6-7.8L11 13z"></path></svg></a> 
//			<a href="" class="panel-share__link"><svg class="panel-share__svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 45 40"><path fill="#1AA3DE" d="M24.1 16.4v-1.8-.6s.1-.3.2-.4c.1-.1.2-.2.4-.3.2-.1.5-.1.8-.1h1.8V9.6h-2.9c-1.7 0-2.8.4-3.6 1.2-.7.8-1.1 1.9-1.1 3.5v2.1h-2.1V20h2.1v10.4H24V20h2.9l.4-3.6h-3.2z"></path></svg></a> 
//			<a href="" class="panel-share__link"><svg class="panel-share__svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 45 40"><path fill="#1AA3DE" d="M33.9 12.9c-.8.4-1.7.6-2.7.7 1-.6 1.7-1.5 2.1-2.6-.9.5-1.9.9-3 1.1-.9-.9-2.1-1.5-3.4-1.5-2.6 0-4.7 2.1-4.7 4.7 0 .4 0 .7.1 1.1-3.9-.2-7.3-2.1-9.6-4.9-.4.7-.6 1.5-.6 2.4 0 1.6.8 3.1 2.1 3.9-.8 0-1.5-.2-2.1-.6v.1c0 2.3 1.6 4.2 3.8 4.6-.4.1-.8.2-1.2.2-.3 0-.6 0-.9-.1.6 1.9 2.3 3.2 4.4 3.2-1.6 1.3-3.6 2-5.8 2-.4 0-.7 0-1.1-.1 2.1 1.3 4.5 2.1 7.2 2.1 8.6 0 13.3-7.1 13.3-13.3v-.6c.7-.6 1.5-1.4 2.1-2.4z"></path></svg></a> 
//			<a href="" class="panel-share__link"><svg class="panel-share__svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 45 40"><path fill="#1AA3DE" d="M31 21.8v6.3h-3.6v-5.9c0-1.5-.5-2.5-1.8-2.5-1 0-1.6.7-1.9 1.3-.1.2-.1.6-.1.9V28H20V17h3.6v1.6c.5-.7 1.3-1.8 3.3-1.8 2.3.1 4.1 1.7 4.1 5zm-14.9-9.9c-1.2 0-2.1.8-2.1 1.9s.8 1.9 2 1.9c1.3 0 2.1-.8 2.1-1.9s-.8-1.9-2-1.9zm-1.9 16.2h3.6v-11h-3.6v11z"></path></svg></a> 
//			<a href="" class="panel-share__link"><svg class="panel-share__svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 45 40"><g fill="#1AA3DE"><path d="M8 20c-.1 3.8 2.6 7.5 6.2 8.7 3.4 1.2 7.7.3 9.9-2.6 1.7-2.1 2-4.8 1.9-7.4h-8.8v3.1c1.8 0 3.5 0 5.3.1-.4 1.5-1.5 2.9-3 3.5-3 1.3-6.7-.4-7.7-3.5-1.1-3 .7-6.6 3.8-7.4 1.9-.7 3.8.1 5.4 1.1.8-.8 1.6-1.6 2.3-2.4-1.8-1.5-4.2-2.5-6.6-2.3-4.7 0-8.9 4.4-8.7 9.1zM31.7 16.1v2.6h-2.6v2.6h2.6v2.6h2.6v-2.6h2.6v-2.6h-2.6v-2.6h-2.6z"></path><path d="M8 20c-.2-4.8 4-9.1 8.7-9.2 2.4-.2 4.8.7 6.6 2.3-.8.8-1.5 1.6-2.3 2.4-1.6-1-3.6-1.7-5.4-1.1-3 .9-4.9 4.5-3.8 7.4.9 3.1 4.7 4.8 7.7 3.5 1.5-.5 2.5-2 3-3.5-1.8 0-3.5 0-5.3-.1v-3.1H26c.2 2.6-.2 5.3-1.9 7.4-2.3 2.9-6.5 3.8-9.9 2.6-3.6-1.1-6.3-4.7-6.2-8.6zM31.7 16.1h2.6v2.6h2.6v2.6h-2.6v2.6h-2.6v-2.6h-2.6v-2.6h2.6v-2.6z"></path></g></svg></a>
//		</span>
//	</div>
//	<a class="nav-actions__link" href="">
//		<svg class="btn-svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><title>Bookmark</title><ellipse fill="#2297CB" cx="18" cy="19" rx="20" ry="20" transform="translate(2 1)"></ellipse><path fill="#FFF" d="M15.8 11c-1 0-1.8.8-1.8 1.8V28c0 .2 0 .5.3.7.2.3.5.3.7.3.1 0 .2 0 .3-.1l4.7-3 4.7 3c.1.1.2.1.3.1.2 0 .5 0 .7-.3.3-.2.3-.5.3-.7V12.8c0-1-.8-1.8-1.8-1.8h-8.4zm0 1.2h8.4c.3 0 .6.3.6.6v14.7l-4.5-2.9c-.2-.1-.5-.1-.6 0l-4.5 2.9V12.8c0-.3.3-.6.6-.6zm4.2 2c-.3 0-.6.3-.6.6V17h-2.2c-.3 0-.6.3-.6.6s.3.6.6.6h2.2v2.2c0 .3.3.6.6.6s.6-.3.6-.6v-2.2h2.2c.3 0 .6-.3.6-.6s-.3-.6-.6-.6h-2.2v-2.2c0-.3-.3-.6-.6-.6z"></path></svg>
//	</a>
//	<a class="nav-actions__link" href="">
//		<svg class="btn-svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><title>Download</title><ellipse fill="#2197CA" cx="20" cy="20" rx="20" ry="20"></ellipse><g fill="#FFF"><path d="M28.1 20c-.3 0-.6.3-.6.6v5.8H12.2v-5.8c0-.3-.3-.6-.6-.6s-.6.3-.6.6V27c0 .3.3.6.6.6h16.6c.3 0 .6-.3.6-.6v-6.4c0-.3-.3-.6-.7-.6z"></path><path d="M23.4 20l-2.5 2.4V11.6c0-.3-.3-.6-.6-.6s-.6.3-.6.6v10.7L17 19.9c-.1-.1-.3-.2-.4-.2-.2 0-.3.1-.4.2-.2.2-.2.6 0 .9l3.7 3.3c.2.2.6.2.8 0l3.5-3.3c.2-.2.3-.6 0-.9-.2-.2-.6-.2-.8.1z"></path></g></svg>
//	</a>
//	<a class="nav-actions__link" href="">
//		<svg class="btn-svg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><title>Print</title><ellipse fill="#2197CA" cx="20" cy="20" rx="20" ry="20"></ellipse><path fill="#FFF" d="M26.9 15.9h-.8V12H14.5v3.9h-.8c-1.5 0-2.7 1.2-2.7 2.6v3.4c0 1.4 1.2 2.6 2.7 2.6h.8v3h11.7v-3h.8c1.5 0 2.7-1.2 2.7-2.6v-3.4c-.1-1.4-1.3-2.6-2.8-2.6zm-11.2-2.7h9.2v2.7h-9.2v-2.7zm0 13.2v-4h9.2v4h-9.2zm12.7-4.5c0 .8-.6 1.4-1.5 1.4h-.8v-1h.4c.4 0 .6-.3.6-.6s-.3-.6-.6-.6H14.1c-.4 0-.6.3-.6.6s.3.6.6.6h.4v1h-.8c-.8 0-1.5-.6-1.5-1.4v-3.4c0-.8.6-1.4 1.5-1.4h13.2c.8 0 1.5.6 1.5 1.4v3.4z"></path></svg>
//	</a>
//	<a class="nav-actions__link" href="">
//		<svg class="nav-actions__follow" xmlns="http://www.w3.org/2000/svg" width="81.6" height="40" viewBox="0 0 81.6 40"><title>Follow this content</title><path class="nav-actions__shape" fill="#2197CA" d="M61.6 0H20C9 0 0 9 0 20s9 20 20 20h41.6c11 0 20-9 20-20s-8.9-20-20-20z"></path><text class="nav-actions__text" transform="translate(32.865 24)" fill="#FFF" font-family="Roboto" font-size="13">Follow</text><path class="nav-actions__path" fill="#FFF" d="M18.6 19.4v-5.7h-1.2v5.7h-5.7v1.2h5.7v5.7h1.2v-5.7h5.7v-1.2"></path></svg>
//	</a>
// </nav>
//
// Weight: 2
//
// Style guide: Icons.Actions

/* Icons
-------------------------------------------------------------- */

.icon-right{
  &:after {
    background:url(../img/icons/icon-chevron-right.svg) no-repeat center right;
    content:'';
    display:inline-block;
    height:12px;
    position:relative;
    top:-1px;
    vertical-align:middle;
    width:12px;
  }
}

.icon-pull-up{
    margin-bottom:-5px;
}