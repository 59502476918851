.gallery {
    border: 1px solid $light-grey-2;
    width:100%;
    position: relative;

    .nav-actions--inline{
        left:1rem;
        top:1.5rem;
        @media (min-width: $bp-large){
            left:1.5rem;
        }
        @media (min-width: $bp-xlarge){
            left:2rem;
        }
    }
}

.gallery__image {
    width: 100%;
    height:auto;
    display: block;
}

.gallery__trigger {
    background-color:transparent;
    position: absolute;
    cursor:pointer;
    left:0;
    right:0;
    bottom:0;
    top:0;
    width:100%;
}

.gallery__trigger-icon {
    display: block;
    fill:#fff;
    position: absolute;
    top: $baseline/2;
    right:$baseline/2;
}

.gallery__trigger:hover {
    background-color:rgba(0,0,0, .1);
    .gallery__trigger-icon{
        circle{
            fill:$white;
        }
        g{
            fill:$secondary-color;
        }
    }
}

.gallery__overlay {
    background-color:$dark-grey-3;
    padding:$baseline $baseline $baseline $baseline*3;
    color:#fff;
    min-height:90px;
    @media (min-width: $bp-medium){
        padding:$baseline 8.5%;
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        background-color:rgba(0,0,0, .8);
    }
}

.gallery__intro{
    @media (min-width: $bp-large){
        max-width:80%;
    }
}

.modal-gallery__outer {
    display: none;
    opacity: 0;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    background-color: rgba(0,0,0,.9);
    transition: opacity .5s ease;
}
.modal-gallery__outer.active {
    display: block;
    opacity: 1;
}
.modal-gallery__img-container {
    text-align:center;
}
.modal-gallery__img {
    margin:80px auto 0 auto;
    max-width:80%;
    max-height: 80vh;
}
.modal-gallery__img--scrollable {
    max-height:none;
}
.modal-gallery__item {
    position: fixed;
    overflow-y: scroll;
    top:0;
    left:0;
    right:0;
    bottom:0;
    opacity:0;
    visibility:hidden;
}
.modal-gallery__item.active {
    opacity:1;
    visibility:visible;
}
.modal-gallery__next {
    position: fixed;
    bottom:50%;
    right:25px;
}
.modal-gallery__previous {
    position: fixed;
    bottom:50%;
    left:25px;
}
.modal-gallery__close {
    position: fixed;
    top:15px;
    right:25px;
}
.modal-gallery__close:hover svg,
.modal-gallery__previous:hover svg,
.modal-gallery__next:hover svg{
    opacity:.8
}
.modal-gallery__details {
    position: fixed;
    bottom:0;
    left:120px;
    right:120px;
    padding:0 0 40px 0;
    color:#fff;
}
.modal-gallery__total {
    position: absolute;
    bottom:15px;
    right:25px;
    color:#fff;
}
.loading:after {
    color:#fff;
    z-index:1;
    width:100%;
    position: fixed;
    display: block;
    text-align:center;
    top:45%;
    content:'Loading...'
}