.video{
	background:$light-grey-1;
    cursor:pointer;
    position: relative;
	opacity:1;
	&:hover{
        .video__icon{
            background-color:$white;
            &:after {
                border-left-color:$off-black;
            }
        }
		.video__trigger{
			&:after{
				opacity:0.5;
			}
		}
	}
	&.is--started {
		.video__trigger:after {
			display: none;
		}
		.video__image {
			@include visuallyhidden;
		}
		@media (min-width: $bp-large){
			.video__intro,
			.video__trigger{
				@include visuallyhidden;
			}
		}
	}
	.nav-actions{
		left:-$baseline*1.5;
		@media (min-width: $bp-xlarge){
			left:-$baseline*2;
		}
	}
}

.video__trigger {
	@include visuallyhidden;
	@media (min-width: $bp-large) {
		@include visible;
		cursor: pointer;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.video__intro {
	@include clearfix;
	background-color: rgba(0, 0, 0, 0.95);
	padding: $baseline $baseline $baseline $baseline*2;
	width: 100%;
	@media (min-width: $bp-medium) {
		padding: $baseline $baseline*2;
	}

	@media (min-width: $bp-xlarge) {
		bottom: 0;
		padding: $baseline*2 $baseline*4;
		position: absolute;
	}
}

.video__summary {
	position: relative;
	@media (min-width: $bp-large) {
		padding-left: $baseline;
		&:before {
			background: $white;
			content: '';
			display: block;
			height: 95%;
			left: 0;
			position: absolute;
			top: 4px;
			width: 1px;
		}
	}
}

.video__caption {
	@include clearfix;
	background-color: rgba(0, 0, 0, 0.7);
	bottom: 0;
	padding: $baseline $baseline $baseline $baseline*3.5;
	position: absolute;
	width: 100%;
	@media (min-width: $bp-medium) {
		padding: $baseline $baseline $baseline $baseline*5;
	}
}

.video__badge {
	margin: $gutter $gutter/2;
	position: relative;
	z-index: 1;
	@media (min-width: $bp-medium) {
		margin: $baseline *1.5;
	}
}

.video__icon {
	background-color: rgba(0, 0, 0, 0.9);
	border: 1px solid $white;
	height: 60px;
	left: $gutter/2;
	position: absolute;
	top: 20px;
	transition: background-color 0.5s ease-in-out;
	width: 60px;
	@media (min-width: $bp-medium) {
		left: $baseline *1.5;
		top: 30px;
	}
	&:after {
		border: solid transparent;
		border-left-color: $white;
		border-width: 10px 0 10px 15px;
		content: " ";
		height: 0;
		left: 40%;
		margin-top: -10px;
		pointer-events: none;
		position: absolute;
		top: 50%;
		width: 0;
	}
}