/* Header
-------------------------------------------------------------- */
.banner {
	background:$white;
	box-shadow: 0px 1px 4px -2px rgba(0,0,0,0.5);
	height:55px;
	position: fixed;
	width:100%;
	z-index:$zIndex-10;
	top:0;

    @media (min-width: $bp-medium) {
    	height:118px;
		&:before{
			background:$primary-color;
			content:'';
			display:block;
			height:40px;
		}
    }
}

.on--search-modal{
	.banner {
		box-shadow: none;
	}
}

.banner__logo{
	margin-top:$baseline/2;
	position:absolute;
	left:$gutter/2;
	z-index:99;
	img{
		width:125px;
	}
	@media (min-width: $bp-medium) {
		margin-top:1.2rem;
		left:$gutter;
		img{
			width:158px;
		}
	}
}

.header-top{
	background:$primary-color;
	padding:$baseline/3 0
}