.editor{	
	overflow:hidden;		
	h2, h3 ,h4, h5 {
		color:$off-black;
		font-weight:$semibold;
		}

    h1{
        @include giga;
        margin-bottom:$baseline /4;
    }
			
	h2 { 
        @include giga;
		margin-bottom:$baseline/2;
		}
	
	h3 { 
        @include mega;
		margin-bottom:$baseline /4;	
		}
	
	h4 { 
        @include alpha;
		margin-bottom:$baseline /4;
		}

	h5 { 
        @include beta;
		margin-bottom:$baseline /4;
	}

	h6 { 
        @include gamma;
		margin-bottom:$baseline /4;
	}
			
	p, ul, ol {
		@include alpha;
		color:$off-black;
		margin-bottom:$baseline;
		@media (min-width: $bp-xlarge){
			@include kilo;
			line-height:1.5;
		}
	}

	ul li{
		list-style-type:disc;
	}

	ol li{
		list-style-type:decimal;
	}
		
	ul, ol {
		margin-left:1.4rem;
		margin-right:1.5rem;
		}
		
	li {
		margin-bottom:$baseline /8;
		}

	b,strong{
		font-weight:$semibold;
	}

	:last-child{
		margin-bottom:0;
	}
		
	a {
		&:hover {
			@include transitions();
			text-decoration:underline;
			}

		}

	}

.editor--mono{
	h2, h3 ,h4, h5 {
		color:$white;
	}
	p, ul, ol {
		color:$white;
	}
	a{
		color:$white;
		text-decoration: underline;
		&:hover{
			color:$light-grey-1;
		}
	}	
}

.editor--small{
	p, ul, ol {
		@include beta;
	}
}

.indicator__item{
	position:relative;
	padding-left:$baseline*1.5;
	&:after{
		content:'';
		display:block;
		height:100%;
		left:0;
		top:$baseline/2;
		width:1px;
		background-color:$secondary-color;
		position: absolute;		
	}
	&:before{
		content:'';
		@include border-radius(100%);
		display:block;
		height:8px;
		width:8px;
		top:8px;
		left:-3px;
		background-color:$secondary-color;
		position: absolute;	
		z-index:2;
	    @media (min-width:$bp-large) {
	        top:12px;
	    }
	}
	&:last-of-type{
		&:after{
			background-color:rgba(255, 255, 255, 0);
		}
	}
}