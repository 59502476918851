// Lists
//
// Style guide: lists

// Bulleted list
//
//Markup:
//<ul class="list list--bullets alpha">
//  <li class="list__item push-half--bottom">
//    Reliable analysis to help you evaluate LNG supply
//  </li>
//  <li class="list__item push-half--bottom">
//    Understand LNG shipping availability and fleet details
//  </li>
//  <li class="list__item push-half--bottom">
//    Long and short term LNG price outlooks and insightful commentary on emerging LNG trends
//  </li>
//  <li class="list__item push-half--bottom">
//    Evaluate and benchmark LNG company, asset and country positions
//  </li>
//</ul>
//
// Weight: 1
//
// Style guide: lists.Bulleted list

// Lined list
//
//Markup:
//<ul class="list alpha">
//  <li class="list__item-lined">
//    Reliable analysis to help you evaluate LNG supply
//  </li>
//  <li class="list__item-lined">
//    Understand LNG shipping availability and fleet details
//  </li>
//  <li class="list__item-lined">
//    Long and short term LNG price outlooks and insightful commentary on emerging LNG trends
//  </li>
//  <li class="list__item-lined">
//    Evaluate and benchmark LNG company, asset and country positions
//  </li>
//</ul>
//
// Weight: 2
//
// Style guide: lists.Lined list

// Icon list
//
//Markup:
//<ul class="list">
//<li class="list__item-icon push-half--bottom">
//	<svg class="list__image-icon" width="13.9" height="17.2" viewBox="0 0 13.9 17.2"><title>Document</title><g fill="#232323"><path d="M13.8 3.5L10.3.2c-.1-.2-.2-.2-.3-.2H1.5C.7 0 0 .7 0 1.5v14.2c0 .8.7 1.5 1.5 1.5h10.9c.8 0 1.5-.7 1.5-1.6V3.8c0-.1 0-.2-.1-.3zm-3.4-1.8l1.7 1.6h-1.7V1.7zm2 14.4H1.5c-.2 0-.5-.2-.5-.5V1.5c0-.2.3-.5.5-.5h7.8v2.9c0 .2.2.5.5.5h3.1v11.3c0 .2-.2.4-.5.4z"></path><path d="M11.1 6.3H3.7c-.2 0-.5.2-.5.5s.2.5.5.5H11c.3 0 .6-.2.6-.5s-.3-.5-.5-.5zM11.1 12.6H3.7c-.2 0-.5.2-.5.5 0 .2.2.5.5.5H11c.3 0 .6-.2.6-.5-.1-.3-.3-.5-.5-.5zM3.7 10.4H11c.3 0 .6-.2.5-.5 0-.2-.2-.5-.5-.5H3.6c-.2 0-.5.2-.5.5 0 .2.2.5.6.5z"></path></g></svg>
//	Reliable analysis to help you evaluate LNG supply
//</li>
//<li class="list__item-icon push-half--bottom">
//	<svg class="list__image-icon" width="13.9" height="17.2" viewBox="0 0 13.9 17.2"><title>Document</title><g fill="#232323"><path d="M13.8 3.5L10.3.2c-.1-.2-.2-.2-.3-.2H1.5C.7 0 0 .7 0 1.5v14.2c0 .8.7 1.5 1.5 1.5h10.9c.8 0 1.5-.7 1.5-1.6V3.8c0-.1 0-.2-.1-.3zm-3.4-1.8l1.7 1.6h-1.7V1.7zm2 14.4H1.5c-.2 0-.5-.2-.5-.5V1.5c0-.2.3-.5.5-.5h7.8v2.9c0 .2.2.5.5.5h3.1v11.3c0 .2-.2.4-.5.4z"></path><path d="M11.1 6.3H3.7c-.2 0-.5.2-.5.5s.2.5.5.5H11c.3 0 .6-.2.6-.5s-.3-.5-.5-.5zM11.1 12.6H3.7c-.2 0-.5.2-.5.5 0 .2.2.5.5.5H11c.3 0 .6-.2.6-.5-.1-.3-.3-.5-.5-.5zM3.7 10.4H11c.3 0 .6-.2.5-.5 0-.2-.2-.5-.5-.5H3.6c-.2 0-.5.2-.5.5 0 .2.2.5.6.5z"></path></g></svg>
//	Understand LNG shipping availability and fleet details
//</li>
//<li class="list__item-icon push-half--bottom">
//	<svg class="list__image-icon" width="13.9" height="17.2" viewBox="0 0 13.9 17.2"><title>Document</title><g fill="#232323"><path d="M13.8 3.5L10.3.2c-.1-.2-.2-.2-.3-.2H1.5C.7 0 0 .7 0 1.5v14.2c0 .8.7 1.5 1.5 1.5h10.9c.8 0 1.5-.7 1.5-1.6V3.8c0-.1 0-.2-.1-.3zm-3.4-1.8l1.7 1.6h-1.7V1.7zm2 14.4H1.5c-.2 0-.5-.2-.5-.5V1.5c0-.2.3-.5.5-.5h7.8v2.9c0 .2.2.5.5.5h3.1v11.3c0 .2-.2.4-.5.4z"></path><path d="M11.1 6.3H3.7c-.2 0-.5.2-.5.5s.2.5.5.5H11c.3 0 .6-.2.6-.5s-.3-.5-.5-.5zM11.1 12.6H3.7c-.2 0-.5.2-.5.5 0 .2.2.5.5.5H11c.3 0 .6-.2.6-.5-.1-.3-.3-.5-.5-.5zM3.7 10.4H11c.3 0 .6-.2.5-.5 0-.2-.2-.5-.5-.5H3.6c-.2 0-.5.2-.5.5 0 .2.2.5.6.5z"></path></g></svg>
//	Long and short term LNG price outlooks and insightful commentary on emerging LNG trends
//</li>
//<li class="list__item-icon push-half--bottom">
//	<svg class="list__image-icon" width="13.9" height="17.2" viewBox="0 0 13.9 17.2"><title>Document</title><g fill="#232323"><path d="M13.8 3.5L10.3.2c-.1-.2-.2-.2-.3-.2H1.5C.7 0 0 .7 0 1.5v14.2c0 .8.7 1.5 1.5 1.5h10.9c.8 0 1.5-.7 1.5-1.6V3.8c0-.1 0-.2-.1-.3zm-3.4-1.8l1.7 1.6h-1.7V1.7zm2 14.4H1.5c-.2 0-.5-.2-.5-.5V1.5c0-.2.3-.5.5-.5h7.8v2.9c0 .2.2.5.5.5h3.1v11.3c0 .2-.2.4-.5.4z"></path><path d="M11.1 6.3H3.7c-.2 0-.5.2-.5.5s.2.5.5.5H11c.3 0 .6-.2.6-.5s-.3-.5-.5-.5zM11.1 12.6H3.7c-.2 0-.5.2-.5.5 0 .2.2.5.5.5H11c.3 0 .6-.2.6-.5-.1-.3-.3-.5-.5-.5zM3.7 10.4H11c.3 0 .6-.2.5-.5 0-.2-.2-.5-.5-.5H3.6c-.2 0-.5.2-.5.5 0 .2.2.5.6.5z"></path></g></svg>
//	Evaluate and benchmark LNG company, asset and country positions
//</li>
//</ul>
//
// Weight: 3
//
// Style guide: lists.Icon list

// Dashed list
//
// <p>Recommended as a method for introducing sections or topics, to be used with the text size <code>class="kilo"</code></p>
//
//Markup:
//<ul class="list kilo">
//  <li class="list__item-dash">
//    Reliable analysis to help you evaluate LNG supply
//  </li>
//  <li class="list__item-dash">
//    Understand LNG shipping availability and fleet details
//  </li>
//  <li class="list__item-dash">
//    Long and short term LNG price outlooks and insightful commentary on emerging LNG trends
//  </li>
//  <li class="list__item-dash">
//    Evaluate and benchmark LNG company, asset and country positions
//  </li>
//</ul>
//
// Weight: 4
//
// Style guide: lists.Dashed list


.list {
    @include clearfix;
    list-style-type:none;
}

/* Bulleted list
-------------------------------------------------------------- */

.list--bullets {
	list-style-type: disc;
	margin-left:$baseline;
    .list__item {
        padding-left:$baseline/4;
        position:relative;
    }        
}

/* Min-height
-------------------------------------------------------------- */

.list__item--height {
    @media (min-width: $bp-large){
        min-height:60px;
	}	
}        

/* Lined
-------------------------------------------------------------- */

.list__item-lined {
    border-bottom:1px solid $light-grey-2;
    margin-bottom:$baseline/1.5;
    padding-bottom:$baseline/1.5;
    position:relative;
    &:last-child{
    	border-bottom:0;
		margin-bottom:0;
		padding-bottom:0;
    }
}  

/* Dotted
-------------------------------------------------------------- */

.list__item-dotted {
    border-bottom:1px dotted $light-grey-2;
    margin-bottom:$baseline/2;
    padding-bottom:$baseline/2;
    position:relative;
    &:last-child{
    	border-bottom:0;
		margin-bottom:0;
		padding-bottom:0;
    }
}  

/* Grid
-------------------------------------------------------------- */

.list__item-grid {
    
    margin-bottom:$baseline/4;
    padding-bottom:$baseline/4;
    position:relative;
    @media (min-width: $bp-small-medium){
        height:4rem;
	}
    @media (min-width: $bp-medium){
        border-bottom:1px solid rgba(255, 255, 255, 0.2);
		margin-bottom:$baseline/2;
		padding-bottom:$baseline/2;
	}
    @media (min-width: $bp-xlarge){

	}
}  

/* Inline
-------------------------------------------------------------- */

.list__item-inline {
    display:inline-block;
	float:left;
}

/* Icons
-------------------------------------------------------------- */

.list__item-icon {
    padding-left:$baseline;
    position:relative;
}

.list__image-icon{
	position: absolute;
	left:0;
	top:4px;
}

.list__item-dash {
    @include clearfix;
    margin-bottom:$baseline;
    padding-left:$baseline*2;
    position:relative;
    &:before{
        background:$secondary-color;
        content:'';
        display:block;
        position: absolute;
        left:0;
        top:16px;
        width:20px;
        height:2px;
    }
}  

.list-counter__item{
   @include clearfix;
   @include alpha;
   counter-increment: item;
   margin-bottom: $baseline;
   padding-left:$baseline*3;
   position:relative;

   &:before{
	   content: counter(item);
	   border:3px solid $white;
	   color: white;
	   text-align: center;
	   display: inline-block;
	   float:left;
	   font-size:1.4rem;
	   font-weight:$semibold;
	   margin-left:-$baseline*3;
	   padding:$baseline/3 $baseline/1.5;
   }
}

.list__item-toggle{
		@include clearfix;
		padding-left:$baseline;
		margin-bottom:$baseline/3;
		&.active{
			.list__link-toggle{
				&:before{
					transform:rotate(90deg);
				}
			}
		}
	}

.list__link-toggle{
	display:inline-block;
	position: relative;
	&:before{
		background:url("../img/icons/icon-chevron-right.svg") no-repeat;
		content:'';
		display:block;
		width:8px;
		height:12px;
		position:absolute;
		left:-18px;
		top:6px;
		transition:0.2s all;
	}
}

.list__item-indent{
	margin-bottom:$baseline;
	padding-left:$baseline*2.5;
	position: relative;
}

.list__icon-indent{
	position: absolute;
	left:0;
	top:$baseline*1.5;
}

.list__item-meta{
	border-bottom:1px solid $light-grey-2;
	float:left;
	margin-bottom:$baseline/6;
	padding-left:0;
	margin-right:$baseline/2;
	padding-right:$baseline/2;
	padding-bottom:$baseline/6;
	position: relative;
	width:100%;
    &:last-child{
	    border:0;
    }
    @media (min-width: $bp-small-medium){
    	border:0;
    	width:auto;
    	margin-bottom:0;
    	padding-bottom: 0;
	    &:before{
	        background:$light-grey-3;
	        content:'';
	        display:block;
	        position: absolute;
	        right:0;
	        top:5px;
	        width:1px;
	        height:15px;
	    }
	    &:last-child{
		    &:before{
		        background:transparent;
		    }	
	    }
	}
}