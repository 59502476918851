.bg-hexagon {
	background-image: url('../img/branding/hexagon.svg');
	background-position: 80px -280px;
	background-repeat: no-repeat;
}

.bg-hexagon--right {
	background-position: 80px right;
}

.bg-hexagon--large {
	background-size: 130%;
}

.bg-error{
	background-color:tint($primary-color, 20%);
	@media (min-width: $bp-medium) {
		background-image: url('../img/branding/hexagon-half.svg');
		background-position: bottom right;
		background-repeat: no-repeat;
		background-size:40%;
	}
	@media (min-width: $bp-large) {
		background-size:45%;
	}
}

.bg-overlay {
	&:before {
		background-color: rgba(0, 0, 0, 0.6);
		bottom: 0;
		content: '';
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: $zIndex-1;
	}
}
.bg-overlay-medium-up {
	@media (min-width: $bp-medium) {
		&:before {
			background-color: rgba(0, 0, 0, 0.6);
			bottom: 0;
			content: '';
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: $zIndex-1;
		}
	}
}
.bg-gradient {
	@media (min-width: $bp-medium) {
		&:before {
			background: linear-gradient(to right, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 40%);
			bottom: 0;
			content: '';
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: $zIndex-1;
		}
	}
}

.bg-gradient--mid {
	@media (min-width: $bp-medium) {
		&:before {
			background: linear-gradient(to right, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 60%);
		}
	}
}

.bg-gradient--bottom {
	@media (min-width: $bp-medium) {
		&:after {
			background: linear-gradient(to top, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 40%);
			bottom: 0;
			content: '';
			display: block;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: $zIndex-1;
		}
	}
}