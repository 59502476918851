/* Footer
--------------------------------------------------------------*/

.site-footer{
	@include clearfix;
	background:shade($primary-color, 20%);
    padding:$baseline 0;
    @media (min-width:$bp-medium){
        padding:$baseline*2 0 $baseline*2 0;
    }
    .list__link{
        display:block;
        font-size:$gamma-size;
        margin-right:$baseline;
        margin-bottom: $baseline/2;
        @media (min-width:$bp-medium){
            display:inline-block;
            margin-bottom:0;
        }
    }     
}

.social-label{
    line-height:40px;
    margin-right:$baseline/2;
}

.site-footer__item{
	border-bottom:1px solid tint($primary-color, 10%);
	padding:$baseline 0;
    position: relative;
    &:last-child{
        border:none;
        padding-left:0;
        padding-right:0;
    }
    @media (min-width:$bp-medium){
        border-left:1px solid tint($primary-color, 10%);
        border-bottom:0;
        padding:$baseline $baseline*2 0 $baseline;
        margin-bottom:$baseline;
        margin-top:$baseline*2;
        min-height:110px;
    }
    @media (min-width:$bp-large){
        padding:$baseline/2 $baseline 0 $baseline;
        margin-bottom:0;
        min-height:118px;
    }
}

.site-footer__wrap{
    @media (min-width:$bp-large){
        position: absolute;
        bottom:0;
    }
}