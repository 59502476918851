.off-canvas-right {
    background-color:$primary-color;
    bottom:0;
    display:block;
    overflow-y:scroll;
    position:fixed;
    right:0;
    top:0;
    width:100%;
    transition:transform 300ms ease;
    transform: translateX(100%);
    z-index:$zIndex-10;

    @media (min-width: $bp-small-medium) {
        width:45%;
        }
    @media (min-width: $bp-large) {
        width:30%;
        }
    }

.off-canvas-right__close-btn {
    background:$primary-color;
    color:$white;
    line-height: 60px;
    text-align: center;
    height:50px;
    position:absolute;
    left:0;
    top:0;
    width:50px;    
    &:hover {
        background:$secondary-color;
        cursor:pointer;
        }
    }

.off-canvas-right__close {
    background-color:transparent;
    bottom:0;
    clip: rect(0, 0, 0, 0);
    left:0;
    position:fixed;
    right:0;
    transition:background-color 300ms ease;
    top:0;
    visibility: hidden;
    z-index:$zIndex-10;
    }

.off-canvas-right--half {
    @media (min-width: $bp-small-medium) {
        width:450px;
        }
    @media (min-width: $bp-large) {
        width:500px;
        }
    }

.active {
    .off-canvas-right--half {
        transform: translateX(0%);
        }
    .off-canvas-right__close--half {
        background-color:rgba(0,0,0, .5);
        clip:auto;
        visibility: visible;
        }
    }