/* Normalise
-------------------------------------------------------------- */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  background: #fff; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, main {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block; }

strong,
b {
  font-weight: 700; }

em,
i {
  font-style: italic; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

ins {
  text-decoration: none; }

mark {
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

dfn {
  font-style: italic; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

hr {
  border: 0;
  display: block;
  height: 1px;
  margin: 0;
  padding: 0; }

address {
  font-style: normal; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

figure {
  margin: 0; }

[hidden] {
  display: none; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  display: block;
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none; }

a:hover,
a:focus,
a:active {
  text-decoration: none; }

button {
  background-color: transparent; }

input,
button,
select {
  -moz-appearance: none;
  -webkit-appearance: none; }

input,
button,
select {
  border: 0 none;
  border-radius: 0;
  padding: 0;
  outline: 0;
  vertical-align: middle; }

select::-ms-expand {
  display: none; }

input[type="search"] {
  border-radius: 0;
  -moz-appearance: none;
  -webkit-appearance: none; }

input[type="checkbox"] {
  -moz-appearance: checkbox;
  -webkit-appearance: checkbox; }

input[type="radio"] {
  -moz-appearance: radio;
  -webkit-appearance: radio; }

input[type="range"] {
  -moz-appearance: slider-horizontal;
  -webkit-appearance: slider-horizontal; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-size: 99%;
  line-height: normal;
  margin: 0;
  vertical-align: baseline; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
input[disabled] {
  cursor: default; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%; }

a,
.btn,
button,
[role=button] {
  transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out, opacity 0.2s ease-in-out, border 0.2s ease-in-out; }

ellipse, circle, path {
  transition: fill 0.5s; }

/*= Keyframe animations
-----------------------------------------------*/
@keyframes blink-animation {
  50% {
    opacity: 0; } }

@keyframes fade-in-out {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fade-in-out {
  opacity: 0;
  opacity: 1 \9;
  /*just in case ie*/
  animation: fade-in-out ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-delay: 0; }

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translate(0, 20px); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

.fade-in-up {
  opacity: 0;
  opacity: 1 \9;
  /*just in case ie*/
  animation: fade-in-up ease-out 1;
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
  animation-duration: .75s;
  animation-delay: .33s; }

.fade-in-up--1 {
  animation-delay: .25s; }

.fade-in-up--2 {
  animation-delay: .45s; }

.fade-in-up--3 {
  animation-delay: .75s; }

.fade-in-up--4 {
  animation-delay: 1s; }

/* Keyframes for the fade-in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fade-in {
  opacity: 0;
  opacity: 1 \9;
  /*just in case ie*/
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s; }

.fade-in--1 {
  animation-delay: .3s; }

.fade-in--2 {
  animation-delay: .6s; }

.fade-in--3 {
  animation-delay: .9s; }

.fade-in--4 {
  animation-delay: 1.2s; }

.fade-in--5 {
  animation-delay: 1.5s; }

.fade-in--6 {
  animation-delay: 1.8s; }

.fade-in--7 {
  animation-delay: 2.1s; }

.fade-in--8 {
  animation-delay: 2.4s; }

.fade-in--9 {
  animation-delay: 2.7s; }

.fade-in--10 {
  animation-delay: 3s; }

.fade-in--11 {
  animation-delay: 3.3s; }

/*= Thumbnail image overlay
-----------------------------------------------*/
.img-overlay {
  display: block; }

.img-overlay__wrap {
  position: relative;
  overflow: hidden;
  display: block;
  animation: anima 2s;
  backface-visibility: hidden; }

.img-overlay__image {
  transform: scale(1); }

.img-overlay:hover .img-overlay__image,
.img-overlay:focus .img-overlay__image,
.img-overlay__wrap:hover .img-overlay__image,
.img-overlay__wrap:focus .img-overlay__image {
  transform: scale(1.03); }

.img-overlay__wrap .img-overlay__image,
.img-overlay__wrap:hover img,
.img-overlay__wrap:focus img {
  transition: all 0.8s ease; }

.img-overlay__blend {
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.65) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%; }

.img-overlay__content {
  padding: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }

.bg-country-report {
  background-color: #126474; }
  .bg-country-report .btn-baseline {
    background-color: #0d4651; }
  .bg-country-report .js-wall-trigger:after,
  .bg-country-report .js-wall-row-trigger:after {
    border-top: 18px solid #0d4651; }

.bg-company-report {
  background-color: #85102E; }
  .bg-company-report .btn-baseline {
    background-color: #6a0d25; }
  .bg-company-report .js-wall-trigger:after,
  .bg-company-report .js-wall-row-trigger:after {
    border-top: 18px solid #6a0d25; }

.bg-commodity-report {
  background-color: #5A1B47; }
  .bg-commodity-report .btn-baseline {
    background-color: #481639; }
  .bg-commodity-report .js-wall-trigger:after,
  .bg-commodity-report .js-wall-row-trigger:after {
    border-top: 18px solid #481639; }

.bg-asset-report {
  background-color: #776791; }
  .bg-asset-report .btn-baseline {
    background-color: #473e57; }
  .bg-asset-report .js-wall-trigger:after,
  .bg-asset-report .js-wall-row-trigger:after {
    border-top: 18px solid #473e57; }

.bg-insight-report {
  background-color: #14472A; }
  .bg-insight-report .btn-baseline {
    background-color: #103922; }
  .bg-insight-report .js-wall-trigger:after,
  .bg-insight-report .js-wall-row-trigger:after {
    border-top: 18px solid #103922; }

.bg-yellow {
  background-color: #EBA912; }

.swatch {
  padding: 1.5rem;
  margin-bottom: 0.75rem;
  min-height: 100px; }

.primary-color {
  color: #002257; }

.bg-primary-color {
  background-color: #002257; }

.secondary-color {
  color: #00a4e3; }

.bg-secondary-color {
  background-color: #00a4e3; }

.tertiary-color {
  color: #adafb2; }

.bg-tertiary-color {
  background-color: #adafb2; }

.bg-primary-color-tint--10 {
  background-color: #e6e9ee; }

.bg-primary-color-tint--60 {
  background-color: #667a9a; }

.bg-primary-color-shade--20 {
  background-color: #001b46; }

.bg-secondary-color-shade--40 {
  background-color: #006288; }

.bg-secondary-color-shade--20 {
  background-color: #0083b6; }

.bg-secondary-color-tint--10 {
  background-color: #e6f6fc; }

.bg-slate {
  background-color: #425A66; }

.bg-slate-shade--20 {
  background-color: #354852; }

.bg-primary-color--20 {
  background-color: rgba(0, 34, 87, 0.2); }

.light-grey-1 {
  color: #f4f4f4; }

.light-grey-2 {
  color: #e6e6e6; }

.light-grey-3 {
  color: #cccccc; }

.bg-light-grey-1 {
  background-color: #f4f4f4; }

.bg-light-grey-2 {
  background-color: #e6e6e6; }

.bg-light-grey-3 {
  background-color: #cccccc; }

.mid-grey-1 {
  color: #b3b3b3; }

.mid-grey-2 {
  color: #999999; }

.mid-grey-3 {
  color: #808080; }

.bg-mid-grey-1 {
  background-color: #b3b3b3; }

.bg-mid-grey-2 {
  background-color: #999999; }

.bg-mid-grey-3 {
  background-color: #808080; }

.dark-grey-1 {
  color: #666666; }

.dark-grey-2 {
  color: #4d4d4d; }

.dark-grey-3 {
  color: #333333; }

.bg-dark-grey-1 {
  background-color: #666666; }

.bg-dark-grey-2 {
  background-color: #4d4d4d; }

.bg-dark-grey-3 {
  background-color: #333333; }

.white {
  color: #fff; }

.bg-white {
  background-color: #fff; }

.off-black {
  color: #232323; }

.bg-off-black {
  background: #232323; }

.green {
  color: #2dbe60; }

/* Border position ---------------------- */
.border {
  border: 1px solid #e6e6e6; }

.border--top {
  border-top: 1px solid #e6e6e6; }

.border--bottom {
  border-bottom: 1px solid #e6e6e6; }

.border--bottom:last-child {
  border-bottom: 10px solid #e6e6e6; }

.border--dotted {
  border-style: dotted; }

.border--top-thin {
  border-top: 1px solid #e6e6e6; }

.border--ends {
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6; }

.border--top-opacity {
  border-top: 1px solid rgba(255, 255, 255, 0.2); }

/* Border size ---------------------- */
.border--2 {
  border-width: 2px; }

/* Border style ---------------------- */
.border--dotted {
  border-style: dotted; }

body,
button,
input,
select,
textarea {
  font: 300 100% "Roboto", sans-serif;
  line-height: 1.5;
  color: #232323; }

.primary-font {
  font-family: "Roboto", sans-serif; }

.primary-font--light {
  font-weight: 300; }

.primary-font--regular {
  font-weight: 400; }

.primary-font--semibold {
  font-weight: 500; }

.primary-font--bold {
  font-weight: 700; }

.primary-font--black {
  font-weight: 900; }

.font-light {
  font-weight: 300; }

.font-regular {
  font-weight: 400; }

.font-semibold {
  font-weight: 500; }

.font-bold {
  font-weight: 700; }

.font-black {
  font-weight: 900; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.uppercase {
  text-transform: uppercase; }

.letterspace {
  letter-spacing: 2px; }

.bold,
strong,
b {
  font-weight: 700; }

.italic {
  font-style: italic; }

time {
  display: block; }

.peta {
  font-size: 2rem;
  line-height: 1.05; }
  @media (min-width: 48em) {
    .peta {
      font-size: 3rem;
      line-height: 1.2; } }

.tera {
  font-size: 1.7rem;
  line-height: 1.2; }
  @media (min-width: 48em) {
    .tera {
      font-size: 1.8rem; } }
  @media (min-width: 60em) {
    .tera {
      font-size: 2rem; } }
  @media (min-width: 90.1em) {
    .tera {
      font-size: 2.4rem; } }

.giga {
  font-size: 1.4rem;
  line-height: 1.3; }
  .giga p {
    margin-bottom: 1.5rem; }
  @media (min-width: 48em) {
    .giga {
      font-size: 1.6rem; }
      .giga p {
        margin-bottom: 2.375rem; } }
  @media (min-width: 60em) {
    .giga {
      font-size: 1.7rem; } }
  @media (min-width: 90.1em) {
    .giga {
      font-size: 1.9rem; } }
  .giga p {
    margin-bottom: 2.5rem; }

.mega {
  font-size: 1.3rem;
  line-height: 1.3; }
  @media (min-width: 60em) {
    .mega {
      font-size: 1.4rem; } }

.kilo {
  font-size: 1.2rem;
  line-height: 1.3; }
  @media (min-width: 60em) {
    .kilo {
      font-size: 1.3rem; } }

.alpha {
  font-size: 1.1rem;
  line-height: 1.4; }
  @media (min-width: 60em) {
    .alpha {
      font-size: 1.2rem; } }

.beta {
  font-size: 1.04rem;
  line-height: 1.6; }

.gamma {
  font-size: 0.875rem;
  line-height: 1.4; }

.delta {
  font-size: 0.75rem;
  line-height: 1.4; }

.epsilon {
  font-size: 0.625rem;
  line-height: 1.4; }

.section-heading {
  font-size: 1.3rem;
  line-height: 1.3;
  position: relative;
  margin-bottom: 0.75rem; }
  @media (min-width: 60em) {
    .section-heading {
      font-size: 1.4rem; } }
  .section-heading span {
    background: #ffffff;
    padding-right: 1.5rem; }
  .section-heading:after {
    background: #e6e6e6;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    z-index: -1; }

h1, h2, h3, h4, h5 {
  font-weight: 500; }

a, .link {
  color: #00A0C4; }
  a:hover, .link:hover {
    color: #2197CA;
    text-decoration: none; }

.mono-link,
a.mono-link,
.mono-link a {
  color: #ffffff; }

.mono-link:hover,
a.mono-link:hover,
.mono-link a:hover {
  color: #ededed; }

.dark-link {
  color: #232323; }
  .dark-link:hover {
    color: #656565; }

.line-link {
  border-top: 2px solid #e9e9e9;
  -webkit-transition: 0.5s;
  transition: 0.5s; }
  .line-link:hover {
    border-top: 2px solid #00a4e3; }

.text--center {
  text-align: center; }

.tracking {
  letter-spacing: -2px; }

.dont-break-out {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto; }

.title-pull-up {
  margin-top: -0.75rem; }

.circle {
  border-radius: 100px; }

.wf-loading:not(.no-js) * {
  font-family: sans-serif; }

.blockquote figcaption {
  font-size: 1.1rem;
  line-height: 1.4;
  color: #adafb2; }
  @media (min-width: 60em) {
    .blockquote figcaption {
      font-size: 1.2rem; } }

.blockquote .avatar {
  margin-top: 4.5rem; }

.blockquote--centered {
  padding: 0; }
  @media (min-width: 37.5em) {
    .blockquote--centered {
      padding: 3rem; } }

.blockquote__text {
  font-size: 1.3rem;
  line-height: 1.3;
  color: #002257;
  display: block;
  font-weight: 300;
  line-height: 1.4;
  position: relative;
  margin-bottom: 3rem;
  margin-top: 3rem; }
  @media (min-width: 60em) {
    .blockquote__text {
      font-size: 1.4rem; } }
  @media (min-width: 90.1em) {
    .blockquote__text {
      font-size: 1.4rem;
      line-height: 1.3; }
      .blockquote__text p {
        margin-bottom: 1.5rem; } }
  @media (min-width: 90.1em) and (min-width: 48em) {
    .blockquote__text {
      font-size: 1.6rem; }
      .blockquote__text p {
        margin-bottom: 2.375rem; } }
  @media (min-width: 90.1em) and (min-width: 60em) {
    .blockquote__text {
      font-size: 1.7rem; } }
  @media (min-width: 90.1em) and (min-width: 90.1em) {
    .blockquote__text {
      font-size: 1.9rem; } }
  @media (min-width: 90.1em) {
      .blockquote__text p {
        margin-bottom: 2.5rem; } }
  .blockquote__text:before, .blockquote__text:after {
    color: #e6e6e6;
    display: block;
    font-family: "Roboto", sans-serif;
    font-size: 9rem;
    font-weight: 300;
    left: -0.375rem;
    line-height: 0;
    position: absolute; }
  .blockquote__text:before {
    content: '\201C';
    top: 1rem; }
  .blockquote__text:after {
    bottom: -4.5rem;
    content: '\201D'; }

.blockquote__logo-wrap {
  border-top: 1px solid #e6e6e6;
  margin-top: 4.5rem;
  padding-top: 1.5rem; }

.blockquote__logo {
  margin-bottom: 0.75rem;
  max-width: 160px; }
  @media (min-width: 37.5em) {
    .blockquote__logo {
      max-width: 200px; } }

.title-underline {
  position: relative; }
  .title-underline:before {
    background: #00a4e3;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 3px; }
    @media (min-width: 48em) {
      .title-underline:before {
        width: 120px; } }

.title-underline--long:before {
  width: 120px; }
  @media (min-width: 48em) {
    .title-underline--long:before {
      width: 200px; } }

.fit-text {
  width: 100%;
  display: block; }

.fit-text__content {
  fill: #222; }

.js-fit {
  opacity: 0; }
  .js-fit.is--fitted {
    opacity: 1; }

.status {
  font-size: 1.04rem;
  line-height: 1.6;
  background: 100%;
  letter-spacing: 5px;
  margin-bottom: 12rem;
  position: relative;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 48em) {
    .status {
      width: 60%; } }
  .status p {
    background: #f4f4f4;
    display: inline-block;
    padding: 0 1.5rem;
    position: relative;
    z-index: 2; }
  .status:after {
    background: #e6e6e6;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px; }

.status--error {
  color: #EF5350; }

.status--searching {
  animation: blink-animation 1s linear infinite;
  color: #2dbe60; }

.status--empty {
  color: #4d4d4d; }

.bg-hexagon {
  background-image: url("../img/branding/hexagon.svg");
  background-position: 80px -280px;
  background-repeat: no-repeat; }

.bg-hexagon--right {
  background-position: 80px right; }

.bg-hexagon--large {
  background-size: 130%; }

.bg-error {
  background-color: #334e79; }
  @media (min-width: 48em) {
    .bg-error {
      background-image: url("../img/branding/hexagon-half.svg");
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: 40%; } }
  @media (min-width: 60em) {
    .bg-error {
      background-size: 45%; } }

.bg-overlay:before {
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100; }

@media (min-width: 48em) {
  .bg-overlay-medium-up:before {
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100; } }

@media (min-width: 48em) {
  .bg-gradient:before {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, transparent 40%);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100; } }

@media (min-width: 48em) {
  .bg-gradient--mid:before {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 0%, transparent 60%); } }

@media (min-width: 48em) {
  .bg-gradient--bottom:after {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, transparent 40%);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100; } }

/* Icons
-------------------------------------------------------------- */
.icon-right:after {
  background: url(../img/icons/icon-chevron-right.svg) no-repeat center right;
  content: '';
  display: inline-block;
  height: 12px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 12px; }

.icon-pull-up {
  margin-bottom: -5px; }

/* Header
-------------------------------------------------------------- */
.banner {
  background: #ffffff;
  box-shadow: 0px 1px 4px -2px rgba(0, 0, 0, 0.5);
  height: 55px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  top: 0; }
  @media (min-width: 48em) {
    .banner {
      height: 118px; }
      .banner:before {
        background: #002257;
        content: '';
        display: block;
        height: 40px; } }

.on--search-modal .banner {
  box-shadow: none; }

.banner__logo {
  margin-top: 0.75rem;
  position: absolute;
  left: 12px;
  z-index: 99; }
  .banner__logo img {
    width: 125px; }
  @media (min-width: 48em) {
    .banner__logo {
      margin-top: 1.2rem;
      left: 24px; }
      .banner__logo img {
        width: 158px; } }

.header-top {
  background: #002257;
  padding: 0.5rem 0; }

.nav-actions {
  padding-bottom: 0.75rem; }

.nav-actions__link {
  display: inline-block;
  margin-right: 0.375rem; }

.nav-actions__follow:hover .nav-actions__shape {
  fill: #007fb0; }

.nav-actions__follow:hover .nav-actions__path,
.nav-actions__follow:hover .nav-actions__text {
  fill: #ffffff; }

.nav-actions--inline {
  position: absolute;
  left: 0;
  top: 0; }
  @media (min-width: 60em) {
    .nav-actions--inline {
      left: -4.5rem; } }

.nav-actions--block {
  position: static; }
  @media (min-width: 60em) {
    .nav-actions--block {
      position: absolute; } }

.nav-actions__heading {
  font-size: 1.3rem;
  line-height: 1.3;
  padding-left: 3rem;
  margin-bottom: 1.5rem;
  margin-top: 0.375rem; }
  @media (min-width: 60em) {
    .nav-actions__heading {
      font-size: 1.4rem; } }
  @media (min-width: 60em) {
    .nav-actions__heading {
      margin-bottom: 0.75rem;
      margin-top: 0;
      padding: 0; } }

.gated {
  max-height: 900px;
  overflow: hidden;
  position: relative; }
  .gated:after {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 70%);
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    min-height: 400px;
    width: 100%; }

.gated--form {
  position: absolute;
  z-index: 100;
  bottom: 3rem;
  width: 100%;
  z-index: 100; }

.feature-report {
  position: relative; }

.feature-report__container {
  position: relative;
  overflow: hidden; }
  @media (min-width: 48em) {
    .feature-report__container {
      min-height: 500px; } }

@media (min-width: 48em) {
  .feature-report__image {
    position: absolute;
    max-width: none;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

.expert {
  background-color: #f4f4f4; }

.expert__panel {
  padding: 1.5rem 0;
  position: relative; }
  .expert__panel:before, .expert__panel:after {
    content: "";
    display: table; }
  .expert__panel:after {
    clear: both; }
  @media (min-width: 48em) {
    .expert__panel {
      padding: 3rem 0 3rem 0; } }

.expert__card {
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 1.5rem; }
  @media (min-width: 37.5em) {
    .expert__card:after {
      background: linear-gradient(to bottom, transparent 20%, rgba(0, 0, 0, 0.8) 100%);
      bottom: 0;
      content: '';
      left: 0;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity .5s ease;
      z-index: 1; } }
  @media (min-width: 48em) {
    .expert__card {
      position: absolute;
      right: 24px;
      top: -3rem;
      max-width: 300px; } }

.expert__title {
  font-size: 2rem;
  line-height: 1.05; }
  @media (min-width: 48em) {
    .expert__title {
      font-size: 2.4rem;
      line-height: 1.2; } }
  @media (min-width: 60em) {
    .expert__title {
      font-size: 3rem; } }

.expert__content {
  padding-bottom: 1.5rem; }
  @media (min-width: 48em) {
    .expert__content {
      padding: 0.75rem 0; } }
  @media (min-width: 60em) {
    .expert__content {
      padding: 1.5rem 0; } }

.expert__contact {
  margin-top: 0.75rem; }
  @media (min-width: 37.5em) {
    .expert__contact {
      bottom: 0;
      margin: 0;
      padding: 1.5rem;
      position: absolute;
      z-index: 2; } }

/* Navigation
-------------------------------------------------------------- */
.nav-primary {
  display: block; }
  @media (max-width: 47.938em) {
    .nav-primary {
      bottom: 0;
      display: none;
      background: #ffffff;
      position: fixed;
      left: 0;
      right: 0;
      top: 55px;
      z-index: 100; } }
  @media (min-width: 60em) {
    .nav-primary {
      text-align: center;
      position: relative; } }
  .on--navigation .nav-primary {
    display: block; }
    @media (max-width: 47.938em) {
      .on--navigation .nav-primary {
        overflow-y: auto; } }

@media (min-width: 60em) {
  .nav-primary__inner {
    display: inline-block; } }

.nav-primary__list:before, .nav-primary__list:after {
  content: "";
  display: table; }

.nav-primary__list:after {
  clear: both; }

@media (min-width: 48em) {
  .nav-primary__list {
    float: right;
    margin-right: 96px;
    padding-left: 3rem; } }

@media (min-width: 60em) {
  .nav-primary__list {
    float: none;
    margin-right: 0;
    padding-left: 0; } }

@media (min-width: 48em) {
  .nav-primary__item {
    float: left;
    margin-left: 1.5rem; } }
  @media (min-width: 48em) and (min-width: 60em) {
    .nav-primary__item {
      margin-right: 1rem;
      margin-left: 1rem; } }
  @media (min-width: 48em) and (min-width: 90.1em) {
    .nav-primary__item {
      margin-right: 1.5rem;
      margin-left: 1.5rem; } }

@media (min-width: 48em) {
    .nav-primary__item:last-child .nav-primary__link {
      margin-right: 0; } }

.nav-primary__link {
  color: #232323; }
  @media (max-width: 47.938em) {
    .nav-primary__link {
      border-bottom: 1px solid #e6e6e6;
      display: block;
      font-size: 1.125rem;
      padding: 0.75rem 1.5rem; } }
  @media (min-width: 48em) {
    .nav-primary__link {
      display: block;
      font-size: 1rem;
      font-weight: 300;
      line-height: 78px;
      height: 78px;
      position: relative; } }
  @media (min-width: 60em) {
    .nav-primary__link {
      font-size: 1.125rem; } }
  .nav-primary__link.active {
    font-weight: 700; }
    .nav-primary__link.active:hover {
      color: #232323; }
    @media (min-width: 48em) {
      .nav-primary__link.active:before {
        background: #00a4e3;
        bottom: 1rem;
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        position: absolute; } }

/* Secondary nav -------------------------------------------------------------- */
.nav-secondary {
  max-height: 0;
  overflow: hidden;
  width: 0; }
  .nav-secondary:before, .nav-secondary:after {
    content: "";
    display: table; }
  .nav-secondary:after {
    clear: both; }
  @media (max-width: 47.938em) {
    .nav-secondary {
      width: auto; } }
  @media (min-width: 48em) {
    .nav-secondary {
      background-color: #002257;
      clip: rect(0 0 0 0);
      display: block;
      left: 12px;
      max-height: none;
      overflow: hidden;
      padding: 1.5rem;
      position: absolute;
      text-align: left;
      top: 78px;
      visibility: hidden;
      width: calc(100% - 24px); } }
  @media (min-width: 60em) {
    .nav-secondary {
      padding: 3rem 4.5rem; } }

@media (max-width: 47.938em) {
  .nav-secondary__link {
    border-bottom: 1px solid #f4f4f4;
    color: #666666;
    display: block;
    padding: 0.75rem 1.5rem 0.75rem 2.25rem; } }

@media (min-width: 48em) {
  .nav-secondary__link {
    color: #ffffff;
    display: block;
    font-size: 0.875rem;
    font-weight: 300;
    padding: 0 0 0.375rem 0; } }

@media (min-width: 60em) {
  .nav-secondary__link {
    font-size: 1rem; } }

@media (max-width: 47.938em) {
  .nav-secondary__item:last-child .nav-secondary__link {
    border-bottom: 1px solid #e6e6e6; } }

@media (max-width: 47.938em) {
  .nav-primary__item {
    position: relative; } }

@media (min-width: 48em) {
  .nav-primary__item.is--active > .nav-secondary {
    clip: auto;
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible; } }

@media (min-width: 48em) {
  .no-js .nav-primary__item:hover > .nav-secondary {
    clip: auto;
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible; } }

.nav-secondary-toggle {
  background: #ffffff;
  border-left: 1px solid #e6e6e6;
  display: block;
  height: 51px;
  line-height: 59px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 51px; }
  @media (min-width: 47.938em) {
    .nav-secondary-toggle {
      display: none; } }
  .active .nav-secondary-toggle svg {
    transform: rotate(90deg); }

@media (max-width: 47.938em) {
  .nav-primary__item.active > .nav-secondary {
    margin: 0;
    max-height: 1000px; } }

@media (min-width: 48em) {
  .nav-secondary__intro {
    float: left;
    width: 33.33333%;
    padding-right: 1.5rem; } }

@media (min-width: 60em) {
  .nav-secondary__intro {
    padding-right: 3rem; } }

@media (min-width: 80rem) {
  .nav-secondary__intro {
    width: 38%;
    padding-right: 6rem; } }

@media (max-width: 47.938em) {
  .nav-secondary__title {
    color: #333333;
    display: block;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 1.5rem 1.5rem 0.75rem 2.25rem; } }

@media (min-width: 48em) {
  .nav-secondary__title {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 0.75rem; } }

@media (min-width: 60em) {
  .nav-secondary__title {
    font-size: 1.4rem; } }

@media (min-width: 48em) {
  .nav-secondary__col {
    float: left;
    margin-right: 24px;
    width: calc(33.33333% - 24px); } }

@media (min-width: 80rem) {
  .nav-secondary__col {
    width: calc(28% - 24px); } }

@media (min-width: 48em) {
  .nav-secondary__col,
  .nav-secondary__intro {
    opacity: 0;
    transition: opacity 0.8s ease-in-out; }
    .is--active .nav-secondary__col, .is--active
    .nav-secondary__intro {
      opacity: 1; } }

@media (min-width: 48em) {
  .no-js .nav-secondary__col,
  .no-js .nav-secondary__intro {
    opacity: 1; } }

/* Nav utility -------------------------------------------------------------- */
@media (max-width: 47.938em) {
  .nav-utility {
    background: #f4f4f4; } }

@media (min-width: 48em) {
  .nav-utility {
    margin-right: 24px;
    position: absolute;
    right: 0;
    text-align: right;
    top: -30px; } }

@media (min-width: 60em) {
  .nav-utility {
    margin-right: 12px; } }

@media (max-width: 47.938em) {
  .nav-utility__link {
    border-bottom: 1px solid #e6e6e6;
    color: #4d4d4d;
    display: block;
    padding: 0.75rem 1.5rem; } }

@media (min-width: 48em) {
  .nav-utility__link {
    color: #ffffff;
    display: inline-block;
    float: left;
    font-size: 0.875rem;
    margin-left: 1.5rem;
    position: relative; }
    .nav-utility__link:hover {
      color: #cccccc; }
    .nav-utility__link:last-of-type:before {
      background: #667a9a;
      content: '';
      display: block;
      height: 12px;
      left: -13px;
      position: absolute;
      top: 5px;
      width: 1px; } }

/* Nav tabs -------------------------------------------------------------- */
.nav-tabs__container {
  height: 67px; }

.nav-tabs {
  will-change: transform;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: inset 1px 0px 18px 1px rgba(0, 0, 0, 0.3);
  overflow-x: auto;
  padding: 0.75rem 0;
  white-space: nowrap; }
  @media (min-width: 48em) {
    .nav-tabs {
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: none; } }
  @media (min-width: 48em) {
    .nav-tabs.is--stuck {
      background-color: rgba(0, 0, 0, 0.9);
      position: fixed;
      top: 118px;
      width: 100%;
      z-index: 1000; } }

.nav-tabs__list {
  margin-left: 12px;
  margin-right: 12px; }
  .nav-tabs__list:before, .nav-tabs__list:after {
    content: "";
    display: table; }
  .nav-tabs__list:after {
    clear: both; }

.nav-tabs__item {
  display: inline-block; }

.nav-tabs__link {
  color: #ffffff;
  display: block;
  font-size: 1.125rem;
  font-weight: 300;
  margin-right: 2.25rem;
  padding: 0.5rem 0;
  position: relative; }
  .active .nav-tabs__link {
    color: #ffffff;
    font-weight: 500; }
    .active .nav-tabs__link:after {
      background: #00a4e3;
      bottom: 3px;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      width: 100%; }

.banner__kicker {
  display: none; }
  @media (min-width: 48em) {
    .banner__kicker {
      color: #e6e6e6;
      display: block;
      font-size: 0.875rem;
      left: 24px;
      position: absolute;
      top: -30px; } }

.filter {
  margin-bottom: 0.25rem;
  position: relative;
  width: 100%; }
  @media (min-width: 48em) {
    .filter {
      z-index: 200; } }
  @media (min-width: 37.5em) {
    .filter {
      float: left;
      margin: 0 0.375rem;
      width: auto; } }
  .filter .btn {
    width: 100%; }
    @media (min-width: 37.5em) {
      .filter .btn {
        width: 170px; } }
    @media (min-width: 48em) {
      .filter .btn {
        width: 220px; } }

@media (min-width: 37.5em) {
  .filter__inner {
    display: inline-block; } }

.filter__panel {
  background: #f4f4f4;
  border: 1px solid #cccccc;
  position: absolute;
  left: 0;
  top: 45px;
  opacity: 0;
  height: 0;
  z-index: 300;
  visibility: hidden;
  width: 100%; }
  @media (min-width: 37.5em) {
    .filter__panel {
      width: 170px; } }
  @media (min-width: 48em) {
    .filter__panel {
      width: 220px; } }
  .active .filter__panel {
    opacity: 1;
    height: auto;
    visibility: visible; }

.filter__close {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: none; }

.filter__close.active {
  display: block; }

/* Footer
--------------------------------------------------------------*/
.site-footer {
  background: #001b46;
  padding: 1.5rem 0; }
  .site-footer:before, .site-footer:after {
    content: "";
    display: table; }
  .site-footer:after {
    clear: both; }
  @media (min-width: 48em) {
    .site-footer {
      padding: 3rem 0 3rem 0; } }
  .site-footer .list__link {
    display: block;
    font-size: 0.875rem;
    margin-right: 1.5rem;
    margin-bottom: 0.75rem; }
    @media (min-width: 48em) {
      .site-footer .list__link {
        display: inline-block;
        margin-bottom: 0; } }

.social-label {
  line-height: 40px;
  margin-right: 0.75rem; }

.site-footer__item {
  border-bottom: 1px solid #1a3868;
  padding: 1.5rem 0;
  position: relative; }
  .site-footer__item:last-child {
    border: none;
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 48em) {
    .site-footer__item {
      border-left: 1px solid #1a3868;
      border-bottom: 0;
      padding: 1.5rem 3rem 0 1.5rem;
      margin-bottom: 1.5rem;
      margin-top: 3rem;
      min-height: 110px; } }
  @media (min-width: 60em) {
    .site-footer__item {
      padding: 0.75rem 1.5rem 0 1.5rem;
      margin-bottom: 0;
      min-height: 118px; } }

@media (min-width: 60em) {
  .site-footer__wrap {
    position: absolute;
    bottom: 0; } }

.cart {
  display: none; }
  @media (min-width: 48em) {
    .cart {
      display: inline-block;
      float: left;
      font-size: 0.875rem; } }

@media (max-width: 47.938em) {
  .cart__header {
    position: fixed;
    width: 100%;
    z-index: 1000; } }

@media (max-width: 47.938em) {
  .panel-skyscraper__wrap {
    overflow-y: auto;
    height: 100%; } }

.cart__items {
  font-weight: 500;
  margin-right: 0.375rem; }

.cart__total {
  font-weight: 500;
  margin-right: 0.75rem; }

.cart__currency {
  float: right; }

@media (min-width: 48em) {
  .wrap--store {
    float: left; }
    .wrap--store:before {
      background: #f4f4f4;
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: -300px;
      position: absolute;
      top: 0;
      width: 50%;
      z-index: -1; } }

@media (min-width: 48em) {
  .store-container {
    min-height: 1100px;
    padding-left: 2.25rem; } }

@media (min-width: 60em) {
  .store-container {
    padding-left: 3.75rem; } }

@media (min-width: 48em) {
  .store-vertical-line {
    background: #f4f4f4;
    border-right: 1px solid #e6e6e6;
    bottom: 0;
    height: 100%;
    left: 30px;
    position: absolute;
    top: 0; } }

@media (max-width: 47.938em) {
  .store-facets {
    background-color: #f4f4f4;
    height: 0;
    opacity: 0;
    padding: 0.75rem 1.5rem;
    position: absolute;
    top: 74px;
    visibility: hidden;
    width: 100%;
    z-index: 200; } }

.active .store-facets {
  height: auto;
  margin-bottom: 1.5rem;
  margin-top: -1.5rem;
  opacity: 1;
  visibility: visible; }

@media (min-width: 48em) {
  .store-facets {
    background-color: transparent; } }

.results-quickview {
  background-color: #264370;
  position: relative; }
  .results-quickview:after {
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 18px solid #ffffff;
    content: '';
    height: 0;
    margin-right: -18px;
    position: absolute;
    right: 50%;
    top: 0;
    width: 0;
    z-index: 100; }

.pipeline-steps {
  display: none;
  position: relative; }
  .pipeline-steps:before, .pipeline-steps:after {
    content: "";
    display: table; }
  .pipeline-steps:after {
    clear: both; }
  @media (min-width: 37.5em) {
    .pipeline-steps {
      display: block; } }

.pipeline-steps__item {
  float: left;
  text-align: left;
  text-transform: uppercase; }
  .pipeline-steps__item:first-child .pipeline-steps__link {
    padding-left: 0; }

.pipeline-steps__link {
  color: #8091ab;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 3px;
  margin-right: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  z-index: 100; }
  @media (min-width: 48em) {
    .pipeline-steps__link {
      font-size: 0.875rem; } }
  .active .pipeline-steps__link {
    color: #ffffff; }

.badge {
  background: #EF5350;
  color: #ffffff;
  display: inline-block;
  font-weight: 500;
  font-size: 11px;
  padding: 3px 4px;
  letter-spacing: 1px;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: baseline; }

/* Forms
-------------------------------------------------------------- */
label,
.label {
  cursor: pointer;
  display: block;
  margin-bottom: .5rem;
  font-size: 99%;
  width: 100%; }

input,
textarea {
  display: block;
  padding: 0 12px;
  line-height: 1.25rem;
  min-height: 2.5rem;
  border: 0 solid none;
  border-radius: 0; }

legend {
  font-size: 1rem;
  margin-bottom: 0.75rem; }

.form-control-label {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #232323;
  display: block;
  margin-bottom: 0.25rem; }

.form-control {
  background: #f4f4f4;
  border: 1px solid #cccccc;
  border-radius: 2px;
  color: #232323;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  max-width: 100%;
  padding: 0.75rem 0.75rem;
  position: relative;
  transition: border-color 0.15s ease;
  width: 100%; }
  .form-control:hover {
    border-color: #cccccc; }
  .form-control:focus {
    background: #ffffff;
    border-color: #00A0C4;
    color: #232323;
    outline: none; }

.form-control--small {
  max-width: 3rem; }

.form-row {
  margin-bottom: 1.5rem;
  clear: both; }

.form-row-checkbox,
.form-row-radio {
  position: relative;
  padding-left: 2rem; }
  .form-row-checkbox input,
  .form-row-radio input {
    position: absolute;
    left: 0;
    top: .5rem; }

.form-select {
  font-size: 1.04rem;
  line-height: 1.6;
  background: #f4f4f4 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11.4" height="7.1" viewBox="0 0 11.4 7.1"><title>Fill 1 Copy</title><path d="M11.4 1.1L10.3 0 5.7 4.9 1.1 0 0 1.1l5.8 6"/></svg>') no-repeat 90% center;
  border: 1px solid #cccccc;
  color: #333333;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  padding: 0.6rem 1.5rem 0.6rem 0.75rem;
  vertical-align: middle; }
  .form-select:focus {
    border-color: #00A0C4;
    outline: none; }

.form-select--cart {
  background: transparent url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11.4px" height="7.1px" viewBox="0 0 11.4 7.1" enable-background="new 0 0 11.4 7.1" xml:space="preserve"><title>Fill 1 Copy</title><path fill="#FFFFFF" d="M11.4,1.1L10.3,0L5.7,4.9L1.1,0L0,1.1l5.8,6"/></svg>') no-repeat 90% center;
  border: 0;
  color: #ffffff;
  font-size: inherit;
  padding: 0 1.5rem 0 0.375rem; }
  .form-select--cart option {
    background: #002257;
    border: 0;
    padding: 0.375rem 1.5rem 0.375rem 0.375rem; }

.sorting {
  float: left;
  width: 50%; }
  @media (min-width: 37.5em) {
    .sorting {
      display: inline-block;
      float: left;
      width: auto; } }

@media (min-width: 37.5em) {
  .sorting__input {
    margin-right: 0.75rem; } }

@media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
  .form-select {
    padding-right: 10px !important; }
  .form-select__icon {
    display: none; } }

.form--lined input {
  padding: 0.375rem 0 0.375rem 0; }

.form--lined input,
.form--lined textarea,
.form--lined select {
  background: transparent;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  transition: border 500ms ease-out; }
  .form--lined input:focus,
  .form--lined textarea:focus,
  .form--lined select:focus {
    border-bottom: 1px solid #00a4e3; }
  .form--lined input::-webkit-input-placeholder,
  .form--lined textarea::-webkit-input-placeholder,
  .form--lined select::-webkit-input-placeholder {
    color: #cccccc; }
  .form--lined input::-moz-placeholder,
  .form--lined textarea::-moz-placeholder,
  .form--lined select::-moz-placeholder {
    color: #cccccc; }
  .form--lined input:-ms-input-placeholder,
  .form--lined textarea:-ms-input-placeholder,
  .form--lined select:-ms-input-placeholder {
    color: #cccccc; }
  .form--lined input:-moz-placeholder,
  .form--lined textarea:-moz-placeholder,
  .form--lined select:-moz-placeholder {
    color: #cccccc; }

.form--lined select {
  background-color: #232323;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11.4" height="7.1" viewBox="0 0 11.4 7.1"><title>Fill 1 Copy</title><path fill="#ffffff" d="M11.4 1.1L10.3 0 5.7 4.9 1.1 0 0 1.1l5.8 6"/></svg>');
  background-repeat: no-repeat;
  background-position: 90% center;
  border: 1px solid #cccccc;
  color: #cccccc;
  transition: border 500ms ease-out; }
  .form--lined select:focus {
    border: 1px solid #00a4e3;
    color: #ffffff; }

.form--lined textarea {
  color: #ffffff;
  padding: 0.375rem; }
  .form--lined textarea:focus {
    background-color: transparent;
    color: #ffffff; }

.form--inset {
  position: relative; }
  .form--inset .form__input {
    border: 1px solid #ffffff;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.4;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    width: 100%;
    margin-bottom: 0.375rem; }
    @media (min-width: 37.5em) {
      .form--inset .form__input {
        padding: 0.75rem 12rem 0.75rem 1.5rem;
        margin: 0; } }
  .form--inset .form__btn {
    display: block;
    width: 100%; }
    @media (min-width: 37.5em) {
      .form--inset .form__btn {
        width: auto;
        position: absolute;
        right: 0;
        top: 0; } }

.checkbox,
.radio {
  list-style-type: none; }
  .checkbox:before, .checkbox:after,
  .radio:before,
  .radio:after {
    content: "";
    display: table; }
  .checkbox:after,
  .radio:after {
    clear: both; }

.checkbox__item,
.radio__item {
  padding-bottom: 0.375rem; }

.checkbox__input,
.radio__input {
  opacity: 0;
  position: absolute; }

.checkbox__label,
.checkbox__input,
.radio__label,
.radio__input {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  cursor: pointer; }

.checkbox__label,
.radio__label {
  color: #333333;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 1.8rem;
  position: relative; }
  .checkbox__label:before,
  .radio__label:before {
    content: '';
    background: #f4f4f4;
    border: 1px solid #999999;
    display: inline-block;
    left: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    padding: 2px;
    text-align: center; }

.checkbox__input:checked ~ .checkbox__label:before,
.radio__input:checked ~ .radio__label:before {
  background: #ffffff;
  background-image: url("../img/icons/icon-tick.svg");
  background-repeat: no-repeat;
  background-position: center center;
  color: #666666;
  content: ""; }

.checkbox__item input:focus ~ .checkbox__label:before,
.radio__item input:focus ~ .radio__label:before {
  border: 1px solid #00A0C4; }

.radio__label:before {
  border-radius: 100%; }

.radio__input:checked + .radio__label:before {
  background-image: url("../img/icons/icon-radio.svg"); }

.checkbox--store {
  padding: 0; }
  .checkbox--store .checkbox__item {
    padding: 0;
    margin-bottom: 0.375rem; }
  .checkbox--store .checkbox__label {
    font-size: 0.75rem;
    line-height: 21px;
    margin-bottom: 0;
    text-transform: uppercase;
    background: #ffffff;
    padding: 0.75rem 0.75rem 0.75rem 3rem;
    margin-bottom: 0.375rem;
    transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out; }
    .checkbox--store .checkbox__label:before {
      left: 0.75rem; }
  .checkbox--store .checkbox__input:checked + .checkbox__label {
    background: #666666;
    color: #ffffff; }
  .checkbox--store .checkbox--store-more {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .checkbox--store.active .checkbox--store-more {
    position: static;
    visibility: visible;
    width: auto;
    height: auto;
    padding: 0;
    margin: auto;
    overflow: inherit;
    clip: auto; }
  .checkbox--store.active .checkbox--store-more__btn {
    display: none; }

.field-validation-error {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #EF5350;
  font-weight: 400; }

.input-validation-error {
  border: 1px solid #EF5350; }

.validation-summary {
  background: #fdeeee;
  padding: 1.5rem; }
  .validation-summary .list {
    counter-reset: numbers; }
  .validation-summary .list__item {
    font-size: 0.875rem;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 1rem;
    position: relative; }
    .validation-summary .list__item:before {
      content: counter(numbers);
      counter-increment: numbers;
      position: absolute;
      left: 0;
      top: 0; }
    .validation-summary .list__item:last-child {
      margin-bottom: 0;
      padding-bottom: 0; }
    .validation-summary .list__item a {
      color: #EF5350; }
      .validation-summary .list__item a:hover {
        color: #bf4240; }

.hide-title .Form__Title {
  display: none; }

.avatar {
  border-top: 1px solid #e6e6e6;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  position: relative;
  min-height: 50px; }

.avatar__image {
  position: absolute;
  width: 50px;
  height: 50px; }

.avatar__content {
  margin-left: 4.5rem; }

.avatar--large {
  min-height: 94px; }
  .avatar--large .avatar__image {
    width: 94px;
    height: 94px; }
  .avatar--large .avatar__content {
    margin-left: 6.75rem; }

.off-canvas-right {
  background-color: #002257;
  bottom: 0;
  display: block;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  transition: transform 300ms ease;
  transform: translateX(100%);
  z-index: 1000; }
  @media (min-width: 37.5em) {
    .off-canvas-right {
      width: 45%; } }
  @media (min-width: 60em) {
    .off-canvas-right {
      width: 30%; } }

.off-canvas-right__close-btn {
  background: #002257;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  width: 50px; }
  .off-canvas-right__close-btn:hover {
    background: #00a4e3;
    cursor: pointer; }

.off-canvas-right__close {
  background-color: transparent;
  bottom: 0;
  clip: rect(0, 0, 0, 0);
  left: 0;
  position: fixed;
  right: 0;
  transition: background-color 300ms ease;
  top: 0;
  visibility: hidden;
  z-index: 1000; }

@media (min-width: 37.5em) {
  .off-canvas-right--half {
    width: 450px; } }

@media (min-width: 60em) {
  .off-canvas-right--half {
    width: 500px; } }

.active .off-canvas-right--half {
  transform: translateX(0%); }

.active .off-canvas-right__close--half {
  background-color: rgba(0, 0, 0, 0.5);
  clip: auto;
  visibility: visible; }

.editor {
  overflow: hidden; }
  .editor h2, .editor h3, .editor h4, .editor h5 {
    color: #232323;
    font-weight: 500; }
  .editor h1 {
    font-size: 1.4rem;
    line-height: 1.3;
    margin-bottom: 0.375rem; }
    .editor h1 p {
      margin-bottom: 1.5rem; }
    @media (min-width: 48em) {
      .editor h1 {
        font-size: 1.6rem; }
        .editor h1 p {
          margin-bottom: 2.375rem; } }
    @media (min-width: 60em) {
      .editor h1 {
        font-size: 1.7rem; } }
    @media (min-width: 90.1em) {
      .editor h1 {
        font-size: 1.9rem; } }
    .editor h1 p {
      margin-bottom: 2.5rem; }
  .editor h2 {
    font-size: 1.4rem;
    line-height: 1.3;
    margin-bottom: 0.75rem; }
    .editor h2 p {
      margin-bottom: 1.5rem; }
    @media (min-width: 48em) {
      .editor h2 {
        font-size: 1.6rem; }
        .editor h2 p {
          margin-bottom: 2.375rem; } }
    @media (min-width: 60em) {
      .editor h2 {
        font-size: 1.7rem; } }
    @media (min-width: 90.1em) {
      .editor h2 {
        font-size: 1.9rem; } }
    .editor h2 p {
      margin-bottom: 2.5rem; }
  .editor h3 {
    font-size: 1.3rem;
    line-height: 1.3;
    margin-bottom: 0.375rem; }
    @media (min-width: 60em) {
      .editor h3 {
        font-size: 1.4rem; } }
  .editor h4 {
    font-size: 1.1rem;
    line-height: 1.4;
    margin-bottom: 0.375rem; }
    @media (min-width: 60em) {
      .editor h4 {
        font-size: 1.2rem; } }
  .editor h5 {
    font-size: 1.04rem;
    line-height: 1.6;
    margin-bottom: 0.375rem; }
  .editor h6 {
    font-size: 0.875rem;
    line-height: 1.4;
    margin-bottom: 0.375rem; }
  .editor p, .editor ul, .editor ol {
    font-size: 1.1rem;
    line-height: 1.4;
    color: #232323;
    margin-bottom: 1.5rem; }
    @media (min-width: 60em) {
      .editor p, .editor ul, .editor ol {
        font-size: 1.2rem; } }
    @media (min-width: 90.1em) {
      .editor p, .editor ul, .editor ol {
        font-size: 1.2rem;
        line-height: 1.3;
        line-height: 1.5; } }
  @media (min-width: 90.1em) and (min-width: 60em) {
    .editor p, .editor ul, .editor ol {
      font-size: 1.3rem; } }
  .editor ul li {
    list-style-type: disc; }
  .editor ol li {
    list-style-type: decimal; }
  .editor ul, .editor ol {
    margin-left: 1.4rem;
    margin-right: 1.5rem; }
  .editor li {
    margin-bottom: 0.1875rem; }
  .editor b, .editor strong {
    font-weight: 500; }
  .editor :last-child {
    margin-bottom: 0; }
  .editor a:hover {
    transition: all 0.25s ease-in-out;
    text-decoration: underline; }

.editor--mono h2, .editor--mono h3, .editor--mono h4, .editor--mono h5 {
  color: #ffffff; }

.editor--mono p, .editor--mono ul, .editor--mono ol {
  color: #ffffff; }

.editor--mono a {
  color: #ffffff;
  text-decoration: underline; }
  .editor--mono a:hover {
    color: #f4f4f4; }

.editor--small p, .editor--small ul, .editor--small ol {
  font-size: 1.04rem;
  line-height: 1.6; }

.indicator__item {
  position: relative;
  padding-left: 2.25rem; }
  .indicator__item:after {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    top: 0.75rem;
    width: 1px;
    background-color: #00a4e3;
    position: absolute; }
  .indicator__item:before {
    content: '';
    border-radius: 100%;
    display: block;
    height: 8px;
    width: 8px;
    top: 8px;
    left: -3px;
    background-color: #00a4e3;
    position: absolute;
    z-index: 2; }
    @media (min-width: 60em) {
      .indicator__item:before {
        top: 12px; } }
  .indicator__item:last-of-type:after {
    background-color: rgba(255, 255, 255, 0); }

.table {
  border-top: 2px solid #666666;
  margin: 0 0 1.5rem 0;
  width: 100%; }
  .table tr {
    border-bottom: 1px solid #e6e6e6; }
  .table th {
    background-color: #f4f4f4;
    color: #666666;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: lh(0.75);
    min-height: lh(2);
    padding: 0.75rem 3%;
    text-align: left;
    vertical-align: bottom; }
  .table td {
    padding: 0.75rem 3%;
    text-align: left; }

.table--plain {
  border-top: none; }
  .table--plain th {
    background-color: #fff;
    color: #333333; }

.table--striped tbody > tr:nth-child(even) > td,
.table--striped tbody > tr:nth-child(even) > th {
  background-color: #f4f4f4; }

.table--hover tbody tr:hover > td,
.table--hover tbody tr:hover > th {
  background-color: #f6f6f6; }

.active .panel-aside {
  margin-bottom: 1.5rem; }

@media (min-width: 60em) {
  .panel-aside {
    position: absolute;
    top: 0;
    right: 12px;
    z-index: 200; } }

@media (min-width: 48em) {
  .panel-skyscraper {
    position: absolute;
    right: 12px;
    top: 0; } }

@media (min-width: 48em) {
  .panel-aside--overlap {
    top: -300px; } }

.panel-feature {
  padding-bottom: 2.25rem;
  padding-top: 2.25rem; }
  @media (min-width: 60em) {
    .panel-feature {
      padding-bottom: 3rem;
      padding-top: 3rem;
      min-height: 400px; } }

.panel-cookie {
  background-color: rgba(0, 0, 0, 0.9);
  border-top: 2px solid #00a4e3;
  bottom: 0;
  position: fixed;
  z-index: 1000;
  width: 100%; }

.off--banner {
  display: none; }

.panel-share {
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 0;
  position: absolute;
  width: 40px; }

.panel-share__link {
  border-bottom: 1px solid #e6e6e6;
  display: inline-block; }
  .panel-share__link:last-child {
    border: none; }

.panel-share__svg path {
  fill: #00a4e3; }

.panel-share__svg:hover path {
  fill: #007fb0; }

.panel-toggle {
  clip: rect(0 0 0 0);
  opacity: 0;
  max-height: 0;
  transition: max-height 260ms ease-out,opacity 300ms ease-out;
  overflow: hidden;
  will-change: opacity; }
  .active > .panel-toggle {
    clip: auto;
    max-height: 1500px;
    height: auto;
    overflow: visible;
    transition: max-height 260ms ease-in,opacity 300ms ease-in;
    opacity: 1; }

@media (max-width: 47.938em) {
  .panel-toggle-small {
    clip: rect(0 0 0 0);
    opacity: 0;
    max-height: 0;
    transition: max-height 260ms ease-out,opacity 300ms ease-out;
    overflow: hidden;
    will-change: opacity; }
    .active > .panel-toggle-small {
      clip: auto;
      max-height: 1500px;
      height: auto;
      overflow: visible;
      transition: max-height 260ms ease-in,opacity 300ms ease-in;
      opacity: 1; } }

@media (max-width: 59.938em) {
  .panel-toggle-medium {
    clip: rect(0 0 0 0);
    opacity: 0;
    max-height: 0;
    transition: max-height 260ms ease-out,opacity 300ms ease-out;
    overflow: hidden;
    will-change: opacity; }
    .active > .panel-toggle-medium {
      clip: auto;
      max-height: 1500px;
      height: auto;
      overflow: visible;
      transition: max-height 260ms ease-in,opacity 300ms ease-in;
      opacity: 1; } }

.list {
  list-style-type: none; }
  .list:before, .list:after {
    content: "";
    display: table; }
  .list:after {
    clear: both; }

/* Bulleted list
-------------------------------------------------------------- */
.list--bullets {
  list-style-type: disc;
  margin-left: 1.5rem; }
  .list--bullets .list__item {
    padding-left: 0.375rem;
    position: relative; }

/* Min-height
-------------------------------------------------------------- */
@media (min-width: 60em) {
  .list__item--height {
    min-height: 60px; } }

/* Lined
-------------------------------------------------------------- */
.list__item-lined {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  position: relative; }
  .list__item-lined:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0; }

/* Dotted
-------------------------------------------------------------- */
.list__item-dotted {
  border-bottom: 1px dotted #e6e6e6;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative; }
  .list__item-dotted:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0; }

/* Grid
-------------------------------------------------------------- */
.list__item-grid {
  margin-bottom: 0.375rem;
  padding-bottom: 0.375rem;
  position: relative; }
  @media (min-width: 37.5em) {
    .list__item-grid {
      height: 4rem; } }
  @media (min-width: 48em) {
    .list__item-grid {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      margin-bottom: 0.75rem;
      padding-bottom: 0.75rem; } }

/* Inline
-------------------------------------------------------------- */
.list__item-inline {
  display: inline-block;
  float: left; }

/* Icons
-------------------------------------------------------------- */
.list__item-icon {
  padding-left: 1.5rem;
  position: relative; }

.list__image-icon {
  position: absolute;
  left: 0;
  top: 4px; }

.list__item-dash {
  margin-bottom: 1.5rem;
  padding-left: 3rem;
  position: relative; }
  .list__item-dash:before, .list__item-dash:after {
    content: "";
    display: table; }
  .list__item-dash:after {
    clear: both; }
  .list__item-dash:before {
    background: #00a4e3;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 16px;
    width: 20px;
    height: 2px; }

.list-counter__item {
  font-size: 1.1rem;
  line-height: 1.4;
  counter-increment: item;
  margin-bottom: 1.5rem;
  padding-left: 4.5rem;
  position: relative; }
  .list-counter__item:before, .list-counter__item:after {
    content: "";
    display: table; }
  .list-counter__item:after {
    clear: both; }
  @media (min-width: 60em) {
    .list-counter__item {
      font-size: 1.2rem; } }
  .list-counter__item:before {
    content: counter(item);
    border: 3px solid #ffffff;
    color: white;
    text-align: center;
    display: inline-block;
    float: left;
    font-size: 1.4rem;
    font-weight: 500;
    margin-left: -4.5rem;
    padding: 0.5rem 1rem; }

.list__item-toggle {
  padding-left: 1.5rem;
  margin-bottom: 0.5rem; }
  .list__item-toggle:before, .list__item-toggle:after {
    content: "";
    display: table; }
  .list__item-toggle:after {
    clear: both; }
  .list__item-toggle.active .list__link-toggle:before {
    transform: rotate(90deg); }

.list__link-toggle {
  display: inline-block;
  position: relative; }
  .list__link-toggle:before {
    background: url("../img/icons/icon-chevron-right.svg") no-repeat;
    content: '';
    display: block;
    width: 8px;
    height: 12px;
    position: absolute;
    left: -18px;
    top: 6px;
    transition: 0.2s all; }

.list__item-indent {
  margin-bottom: 1.5rem;
  padding-left: 3.75rem;
  position: relative; }

.list__icon-indent {
  position: absolute;
  left: 0;
  top: 2.25rem; }

.list__item-meta {
  border-bottom: 1px solid #e6e6e6;
  float: left;
  margin-bottom: 0.25rem;
  padding-left: 0;
  margin-right: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.25rem;
  position: relative;
  width: 100%; }
  .list__item-meta:last-child {
    border: 0; }
  @media (min-width: 37.5em) {
    .list__item-meta {
      border: 0;
      width: auto;
      margin-bottom: 0;
      padding-bottom: 0; }
      .list__item-meta:before {
        background: #cccccc;
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 5px;
        width: 1px;
        height: 15px; }
      .list__item-meta:last-child:before {
        background: transparent; } }

.global-search {
  margin-top: 3rem;
  position: relative; }

.global-search__input {
  background: transparent;
  border-bottom: 1px solid #fff;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: normal;
  padding: 1rem 3rem 1rem 0;
  width: 100%;
  transition: border 500ms ease-out; }
  .global-search__input:focus {
    border-bottom: 1px solid #00a4e3; }
  @media (min-width: 48em) {
    .global-search__input {
      font-size: 2rem; } }
  .global-search__input::-webkit-input-placeholder {
    color: #ffffff; }
  .global-search__input::-moz-placeholder {
    color: #ffffff; }
  .global-search__input:-ms-input-placeholder {
    color: #ffffff; }
  .global-search__input:-moz-placeholder {
    color: #ffffff; }

.global-search__btn {
  color: #ffffff;
  font-size: 1.3rem;
  position: absolute;
  right: 0;
  top: 10px; }
  @media (min-width: 48em) {
    .global-search__btn {
      font-size: 2rem; } }

.search-overlay {
  background: rgba(0, 34, 87, 0.9);
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: visible;
  padding: 0;
  position: fixed;
  right: 0;
  top: 55px;
  transition: opacity .4s;
  width: 100%;
  z-index: -1;
  margin: 0 auto; }
  @media (min-width: 48em) {
    .search-overlay {
      top: 118px; } }

.on--search-modal .search-overlay {
  opacity: 1;
  visibility: visible;
  z-index: 1000; }

.search-inset {
  position: relative; }

.search-inset__input {
  border: 1px solid #e6e6e6;
  font-size: 1.125rem;
  font-weight: 400;
  padding: 1rem 1.5rem;
  transition: box-shadow 500ms ease-out,background 500ms ease-out;
  width: 100%; }
  .search-inset__input:focus {
    box-shadow: inset 0px -2px 0px 0px #00a4e3; }

.search-inset__input--flush {
  border: 0; }

.search-inset__input--small {
  font-size: 1rem;
  padding: 0.75rem 3rem 0.75rem 0.75rem; }

.search-inset__btn {
  position: absolute;
  right: 15px;
  top: 16px; }

.search-inset__btn--small {
  top: 16px; }

.search-inset--store .search-inset__input:focus {
  background: #ffffff; }

.search-inset--store .search-inset__btn svg path {
  fill: #232323; }

.search-inset__input:focus + .search-inset__btn svg path {
  fill: #00a4e3; }

.video {
  background: #f4f4f4;
  cursor: pointer;
  position: relative;
  opacity: 1; }
  .video:hover .video__icon {
    background-color: #ffffff; }
    .video:hover .video__icon:after {
      border-left-color: #232323; }
  .video:hover .video__trigger:after {
    opacity: 0.5; }
  .video.is--started .video__trigger:after {
    display: none; }
  .video.is--started .video__image {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  @media (min-width: 60em) {
    .video.is--started .video__intro,
    .video.is--started .video__trigger {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; } }
  .video .nav-actions {
    left: -2.25rem; }
    @media (min-width: 90.1em) {
      .video .nav-actions {
        left: -3rem; } }

.video__trigger {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  @media (min-width: 60em) {
    .video__trigger {
      position: static;
      visibility: visible;
      width: auto;
      height: auto;
      padding: 0;
      margin: auto;
      overflow: inherit;
      clip: auto;
      cursor: pointer;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%; } }

.video__intro {
  background-color: rgba(0, 0, 0, 0.95);
  padding: 1.5rem 1.5rem 1.5rem 3rem;
  width: 100%; }
  .video__intro:before, .video__intro:after {
    content: "";
    display: table; }
  .video__intro:after {
    clear: both; }
  @media (min-width: 48em) {
    .video__intro {
      padding: 1.5rem 3rem; } }
  @media (min-width: 90.1em) {
    .video__intro {
      bottom: 0;
      padding: 3rem 6rem;
      position: absolute; } }

.video__summary {
  position: relative; }
  @media (min-width: 60em) {
    .video__summary {
      padding-left: 1.5rem; }
      .video__summary:before {
        background: #ffffff;
        content: '';
        display: block;
        height: 95%;
        left: 0;
        position: absolute;
        top: 4px;
        width: 1px; } }

.video__caption {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  padding: 1.5rem 1.5rem 1.5rem 5.25rem;
  position: absolute;
  width: 100%; }
  .video__caption:before, .video__caption:after {
    content: "";
    display: table; }
  .video__caption:after {
    clear: both; }
  @media (min-width: 48em) {
    .video__caption {
      padding: 1.5rem 1.5rem 1.5rem 7.5rem; } }

.video__badge {
  margin: 24px 12px;
  position: relative;
  z-index: 1; }
  @media (min-width: 48em) {
    .video__badge {
      margin: 2.25rem; } }

.video__icon {
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid #ffffff;
  height: 60px;
  left: 12px;
  position: absolute;
  top: 20px;
  transition: background-color 0.5s ease-in-out;
  width: 60px; }
  @media (min-width: 48em) {
    .video__icon {
      left: 2.25rem;
      top: 30px; } }
  .video__icon:after {
    border: solid transparent;
    border-left-color: #ffffff;
    border-width: 10px 0 10px 15px;
    content: " ";
    height: 0;
    left: 40%;
    margin-top: -10px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 0; }

.skip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .skip .btn-skip {
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    top: -100px;
    left: 0; }
    .skip .btn-skip:focus {
      position: fixed;
      height: auto;
      width: auto;
      margin: 0 auto;
      display: block;
      left: 0;
      right: 0;
      top: 70px;
      text-align: center;
      z-index: 100; }

.btn {
  background-color: #f4f4f4;
  border: 1px solid #e6e6e6;
  color: #4d4d4d;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.4;
  outline: none;
  padding: 0.75rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none; }
  .btn:hover {
    background-color: #e6e6e6;
    border: 1px solid #e6e6e6;
    color: #333333; }
  .btn:focus, .btn.active, .btn:active {
    background-color: #f4f4f4;
    color: #333333; }

.btn--disabled,
button:disabled,
button[disabled] {
  opacity: 0.6;
  cursor: not-allowed; }

.btn--primary {
  background-color: #00a4e3;
  border-color: #00a4e3;
  color: #fff; }
  .btn--primary:hover, .btn--primary:focus, .btn--primary:active {
    background-color: #007fb0;
    border-color: #00a4e3;
    color: #fff; }

.btn--secondary {
  background-color: #ffffff;
  border-color: #00a4e3;
  color: #00a4e3;
  font-size: 1rem; }
  .btn--secondary:hover, .btn--secondary:focus, .btn--secondary:active {
    background-color: #e6f6fc;
    border-color: #99dbf4;
    color: #007fb0; }

.btn--tertiary {
  background-color: #ffffff;
  border-color: #232323;
  color: #232323;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase; }
  .btn--tertiary:hover, .btn--tertiary:focus, .btn--tertiary:active {
    background-color: #f4f4f4;
    border-color: #a7a7a7;
    color: #393939; }

.btn--reversed {
  border-color: #00a4e3;
  background-color: transparent;
  color: #46bbee;
  font-size: 1rem; }
  .btn--reversed:hover, .btn--reversed:focus, .btn--reversed:active {
    background-color: #007fb0;
    border-color: #00a4e3;
    color: #ffffff; }

.btn--pill {
  margin: 0 0.1875rem;
  margin-bottom: 0.375rem; }
  .btn--pill svg {
    margin-left: 0.375rem; }
    .btn--pill svg polygon {
      fill: #808080; }
  .btn--pill:hover svg polygon {
    fill: #333333; }

.btn--link {
  background-color: transparent;
  border-color: transparent;
  color: #00A0C4; }
  .btn--link:hover, .btn--link:focus, .btn--link.active, .btn--link:active {
    background-color: transparent;
    color: #33b3d0; }
  .btn--link:visited {
    color: #33b3d0; }

.btn-svg:hover ellipse, .btn-svg:hover circle {
  fill: #0083b6; }

.btn-svg:hover path {
  fill: #ffffff; }

.btn-svg--pressed ellipse, .btn-svg--pressed circle {
  fill: #42c570; }

.btn-svg--pressed path {
  fill: #ffffff; }

a:hover
.btn-delete {
  background: #EF5350; }
  a:hover
.btn-delete .outline {
    fill: #EF5350; }
  a:hover
.btn-delete path {
    fill: #ffffff; }

.btn--large {
  font-size: 1.3rem;
  line-height: 1.3;
  height: 3rem;
  line-height: 3rem;
  padding: 0 1.8rem; }
  @media (min-width: 60em) {
    .btn--large {
      font-size: 1.4rem; } }

.btn--small {
  font-size: 0.875rem;
  line-height: 1.4;
  height: 2.1rem;
  line-height: 2.1rem;
  padding: 0 0.66rem; }

.btn--beta {
  background-color: #dfa111;
  border-color: #bc870e;
  color: #232323;
  font-size: 0.8rem; }
  .btn--beta:hover, .btn--beta:focus, .btn--beta:active {
    background-color: #8d650b;
    border-color: #8d650b;
    color: #ffffff; }

.btn--tags {
  font-size: 0.75rem;
  line-height: 1.4;
  background-color: #e6e6e6;
  border: 1px solid transparent;
  color: #333333;
  text-transform: uppercase;
  font-weight: 400;
  height: 2.1rem;
  line-height: 2.1rem;
  padding: 0 0.99rem;
  margin-right: 0.375rem;
  margin-top: 0.375rem; }
  .btn--tags:hover, .btn--tags:focus {
    background-color: #f4f4f4;
    border: 1px solid rgba(0, 0, 0, 0.05);
    color: #333333; }

.btn-bookmark {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100; }

.btn--icon {
  padding-right: 3.5rem;
  position: relative;
  overflow: hidden; }
  .btn--icon .icon {
    color: #fff;
    fill: #fff;
    height: 100%;
    right: 14px;
    position: absolute;
    top: -1px;
    z-index: 1; }

.btn--block {
  display: block;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%; }

.btn--icon-rotate {
  text-align: left;
  padding-left: 3rem; }
  .btn--icon-rotate:before {
    background: url("../img/icons/icon-chevron-right-large.svg") no-repeat;
    content: '';
    display: block;
    width: 9px;
    height: 15px;
    position: absolute;
    left: 18px;
    top: 16px;
    transition: 0.2s all; }
  .active .btn--icon-rotate:before {
    transform: rotate(90deg); }

.btn--collapse {
  display: block;
  background-color: transparent;
  border-left: 0;
  border-right: 0;
  margin-top: -1px; }
  .btn--collapse:hover {
    border-left: 0;
    border-right: 0; }

.btn--filter {
  border: 1px solid #cccccc;
  color: #333333;
  font-size: 1.05rem;
  font-weight: 400;
  padding: 0.75rem 0.75rem;
  text-align: center; }
  .btn--filter svg {
    margin-left: 0.375rem;
    position: relative;
    top: -1px; }

.btn-load {
  color: #00A0C4;
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  font-size: 1.125rem;
  margin: 0 auto;
  transition-duration: 0.2s; }
  .btn-load svg {
    display: block;
    margin: 0.375rem auto 0 auto;
    transform: translateZ(0);
    transition-duration: 0.2s;
    transition-property: transform;
    transition-timing-function: ease-out; }
  .btn-load:hover {
    color: #2197CA; }
    .btn-load:hover svg {
      transform: translateY(4px); }
      .btn-load:hover svg polygon {
        fill: #2197CA; }
  .btn-load.load-previous:hover svg {
    transform: translateY(-4px); }

.btn--grid {
  background: #f4f4f4;
  border: none;
  color: #232323;
  display: block;
  height: 5rem;
  padding: 0.375rem;
  position: relative; }
  .btn--grid:hover {
    background: #e6e6e6;
    border: none; }
  @media (min-width: 37.5em) {
    .btn--grid {
      height: 4rem; } }
  @media (min-width: 48em) {
    .btn--grid {
      height: 6rem; } }
  @media (min-width: 90.1em) {
    .btn--grid {
      height: 4rem; } }

.btn--grid__label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; }

.btn-baseline {
  background: #28363d;
  bottom: 0;
  left: 0;
  display: block;
  font-weight: 500;
  padding: 0.75rem 0;
  text-align: center;
  width: 100%;
  position: absolute; }

.btn .form--is-expanded {
  display: none; }

.active > .btn .form--is-collapsed {
  display: none; }

.active > .btn .form--is-expanded {
  display: block; }

.active .btn--arrow {
  background-color: #393939;
  border-color: #393939; }
  .active .btn--arrow:after {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #393939;
    bottom: -13px;
    content: '';
    height: 0;
    margin-right: -12px;
    right: 50%;
    position: absolute;
    transition: border 0.2s ease-in-out;
    width: 0;
    z-index: 100; }
  .active .btn--arrow:hover:after, .active .btn--arrow:focus:after {
    border-top: 12px solid #393939; }

.btn-group {
  list-style: none outside none; }
  .btn-group:before, .btn-group:after {
    content: "";
    display: table; }
  .btn-group:after {
    clear: both; }
  .btn-group .btn {
    border-radius: 0;
    display: block;
    margin-right: -1px; }
  .btn-group .active .btn {
    background-color: #c1c1c1;
    color: #333333; }

.btn-group > * {
  float: left;
  margin: 0 0 0 -1px; }

.btn-group > *:first-child {
  margin-left: 0; }

.btn--toggle {
  background: #ffffff;
  border-left: 1px solid #e6e6e6;
  color: #00a4e3;
  line-height: 60px;
  position: absolute;
  height: 55px;
  width: 50px;
  text-align: center;
  top: 0;
  z-index: 999; }
  @media (min-width: 48em) {
    .btn--toggle {
      background: transparent;
      color: #333333;
      line-height: 82px;
      height: 78px;
      border-left: 1px solid #e6e6e6;
      padding-left: 1.2rem;
      padding-right: 1.2rem; } }
  .btn--toggle:before, .btn--toggle:after {
    z-index: 10;
    height: 2px;
    line-height: 3px;
    width: 21px;
    position: absolute;
    left: 14px;
    background: #ffffff;
    opacity: 0;
    content: ''; }
    @media (min-width: 48em) {
      .btn--toggle:before, .btn--toggle:after {
        background: #ffffff;
        left: 14px; } }
  .btn--toggle:before {
    top: 27px;
    transform: rotate(45deg); }
    @media (min-width: 48em) {
      .btn--toggle:before {
        top: 35px; } }
  .btn--toggle:after {
    top: 34px;
    transform: rotate(-45deg) translateX(5px) translateY(-5px); }
    @media (min-width: 48em) {
      .btn--toggle:after {
        top: 42px; } }

.product-link {
  position: relative;
  padding-right: 1.5rem;
  transition-duration: 0.2s; }

.product-link__icon {
  position: absolute;
  top: 4px;
  right: 0;
  transform: translateZ(0);
  transition-duration: 0.2s;
  transition-property: transform;
  transition-timing-function: ease-out; }
  .product-link:hover .product-link__icon {
    transform: translateX(4px); }

.btn--search {
  right: 0; }
  @media (min-width: 48em) {
    .btn--search {
      right: 24px; } }
  @media (min-width: 60em) {
    .btn--search {
      min-width: 118px;
      right: 0; } }

.btn--search__label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  @media (min-width: 60em) {
    .btn--search__label {
      position: static;
      visibility: visible;
      width: auto;
      height: auto;
      padding: 0;
      margin: auto;
      overflow: inherit;
      clip: auto;
      position: relative;
      top: -2px; } }

.btn--search__close {
  display: none;
  position: relative;
  top: -2px; }

.on--search-modal .btn--search {
  background: rgba(0, 34, 87, 0.9); }
  .on--search-modal .btn--search:before, .on--search-modal .btn--search:after {
    opacity: 1; }

.on--search-modal .btn--search__label {
  display: none; }

.on--search-modal .btn--search__close {
  color: #ffffff; }
  @media (min-width: 60em) {
    .on--search-modal .btn--search__close {
      display: inline-block; } }

.on--search-modal .btn--search__icon {
  opacity: 0; }

.btn-hamburger {
  color: #ffffff;
  position: absolute;
  right: 50px;
  top: 0;
  height: 55px;
  line-height: 67px;
  width: 50px;
  text-align: center;
  z-index: 1000; }
  @media (min-width: 47.938em) {
    .btn-hamburger {
      display: none; } }
  .btn-hamburger:before, .btn-hamburger:after {
    background: #232323; }

.btn-hamburger--right {
  right: 0; }

.on--navigation .btn-hamburger {
  color: #ffffff; }
  .on--navigation .btn-hamburger:before, .on--navigation .btn-hamburger:after {
    opacity: 1; }

.on--navigation .btn-hamburger svg {
  opacity: 0; }

.on--navigation .btn-close {
  width: 100%;
  left: 410px;
  opacity: 1;
  visibility: visible;
  background: transparent;
  z-index: 3;
  cursor: pointer; }

.animating--navigation .btn-hamburger {
  transition: opacity 60ms ease; }

.btn--cart {
  display: none;
  border-left: 0;
  border-right: 1px solid #e6e6e6;
  right: 99px;
  width: 60px; }
  .btn--cart:before, .btn--cart:after {
    left: 18px; }
  @media (max-width: 47.938em) {
    .btn--cart {
      display: block; } }

.on--cart-menu .btn--cart {
  background: #002257; }
  .on--cart-menu .btn--cart:before, .on--cart-menu .btn--cart:after {
    opacity: 1; }

.on--cart-menu .btn--cart__icon {
  opacity: 0; }

.btn--cart__items {
  position: relative;
  top: -5px; }

.btn-svg--circle-white {
  background-color: #00a4e3;
  border-radius: 50%;
  margin-right: 0.75rem; }
  .btn-svg--circle-white:hover {
    background-color: #007fb0;
    color: #00a4e3; }

.in-beta {
  background: #ffffff;
  padding-top: 100px; }
  @media (min-width: 48em) {
    .in-beta {
      padding-top: 163px; } }
  .in-beta .banner {
    top: 45px; }
  @media (max-width: 47.938em) {
    .in-beta .nav-primary {
      top: 100px; } }
  .in-beta .search-overlay {
    top: 100px; }
    @media (min-width: 48em) {
      .in-beta .search-overlay {
        top: 163px; } }
  @media (min-width: 48em) {
    .in-beta .nav-tabs.is--stuck {
      top: 163px; } }

.notification {
  width: 100%; }

.notification--beta {
  background: #EBA912;
  padding: 0.375rem 0;
  position: fixed;
  left: 0;
  top: 0; }

.notification__beta-box {
  background-color: #ffffff;
  color: #232323;
  font-size: 1.3rem;
  padding: 0.375rem 0.375rem 0.1875rem 0.375rem;
  position: absolute;
  top: -6px;
  left: 0;
  letter-spacing: 1px;
  font-weight: 900;
  text-transform: uppercase; }

.notification__summary {
  color: #232323;
  display: none;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 0.75rem; }
  @media (min-width: 37.5em) {
    .notification__summary {
      display: inline-block;
      font-size: 0.75rem;
      margin-left: 5.25rem;
      margin-bottom: 0;
      width: 40%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); } }
  @media (min-width: 48em) {
    .notification__summary {
      font-size: 0.875rem;
      width: 48%; } }
  @media (min-width: 60em) {
    .notification__summary {
      font-size: 1rem;
      width: auto; } }

.notification__actions {
  display: inline-block;
  float: right; }

.ratio-5x29 {
  width: 100%;
  height: 0;
  padding-bottom: 58%; }

.ratio-5x3 {
  width: 100%;
  height: 0;
  padding-bottom: 60%; }

.ratio-2x1 {
  width: 100%;
  height: 0;
  padding-bottom: 50%; }

.ratio-1x1 {
  width: 100%;
  height: 0;
  padding-bottom: 100%; }

.ratio-21x9 {
  width: 100%;
  height: 0;
  padding-bottom: 42.85714%; }

.ratio-1x1-5x3 {
  width: 100%;
  height: 0;
  padding-bottom: 100%; }
  @media (min-width: 37.5em) {
    .ratio-1x1-5x3 {
      width: 100%;
      height: 0;
      padding-bottom: 60%; } }

.ratio-5x3-1x1 {
  width: 100%;
  height: 0;
  padding-bottom: 60%; }
  @media (min-width: 37.5em) {
    .ratio-5x3-1x1 {
      width: 100%;
      height: 0;
      padding-bottom: 100%; } }

.wrap {
  margin: 0 auto;
  width: 100%; }
  .wrap:before, .wrap:after {
    content: "";
    display: table; }
  .wrap:after {
    clear: both; }
  @media (min-width: 48em) {
    .wrap {
      padding-left: 12px;
      padding-right: 12px; } }
  @media (min-width: 60em) {
    .wrap {
      max-width: 1100px; } }
  @media (min-width: 90.1em) {
    .wrap {
      max-width: 1400px; } }

@media (min-width: 48em) {
  .wrap--large {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 60em) {
  .wrap--large {
    padding-left: 12px;
    padding-right: 12px; } }

.wrap-flex {
  padding-left: 12px;
  padding-right: 12px; }
  @media (min-width: 48em) {
    .wrap-flex {
      display: flex;
      margin-left: -5px;
      margin-right: -5px; } }

.row {
  clear: both;
  margin-left: -12px;
  margin-right: -12px; }
  .row:before, .row:after {
    content: "";
    display: table; }
  .row:after {
    clear: both; }
  .row:after {
    content: '';
    display: table; }

.col {
  max-width: 1360px;
  float: left;
  margin-left: calc(24px / 2);
  margin-right: calc(24px / 2); }

.col--inline {
  display: inline-block;
  float: none;
  vertical-align: top; }

.col--centered {
  float: none;
  margin-left: auto !important;
  margin-right: auto  !important; }

.small-1 {
  width: 3.33333%;
  width: calc(((99.999% / 12) * 1) - (24px)); }

.small-2 {
  width: 11.66667%;
  width: calc(((99.999% / 12) * 2) - (24px)); }

.small-3 {
  width: 20%;
  width: calc(((99.999% / 12) * 3) - (24px)); }

.small-4 {
  width: 28.33333%;
  width: calc(((99.999% / 12) * 4) - (24px)); }

.small-5 {
  width: 36.66667%;
  width: calc(((99.999% / 12) * 5) - (24px)); }

.small-6 {
  width: 45%;
  width: calc(((99.999% / 12) * 6) - (24px)); }

.small-7 {
  width: 53.33333%;
  width: calc(((99.999% / 12) * 7) - (24px)); }

.small-8 {
  width: 61.66667%;
  width: calc(((99.999% / 12) * 8) - (24px)); }

.small-9 {
  width: 70%;
  width: calc(((99.999% / 12) * 9) - (24px)); }

.small-10 {
  width: 78.33333%;
  width: calc(((99.999% / 12) * 10) - (24px)); }

.small-11 {
  width: 86.66667%;
  width: calc(((99.999% / 12) * 11) - (24px)); }

.small-12 {
  width: 95%;
  width: calc(((99.999% / 12) * 12) - (24px)); }

.small-12--flush {
  width: 100%; }

@media (min-width: 37.5em) {
  .small-medium-1 {
    width: 3.33333%;
    width: calc(((99.999% / 12) * 1) - (24px)); }
  .small-medium-2 {
    width: 11.66667%;
    width: calc(((99.999% / 12) * 2) - (24px)); }
  .small-medium-3 {
    width: 20%;
    width: calc(((99.999% / 12) * 3) - (24px)); }
  .small-medium-4 {
    width: 28.33333%;
    width: calc(((99.999% / 12) * 4) - (24px)); }
  .small-medium-5 {
    width: 36.66667%;
    width: calc(((99.999% / 12) * 5) - (24px)); }
  .small-medium-6 {
    width: 45%;
    width: calc(((99.999% / 12) * 6) - (24px)); }
  .small-medium-7 {
    width: 53.33333%;
    width: calc(((99.999% / 12) * 7) - (24px)); }
  .small-medium-8 {
    width: 61.66667%;
    width: calc(((99.999% / 12) * 8) - (24px)); }
  .small-medium-9 {
    width: 70%;
    width: calc(((99.999% / 12) * 9) - (24px)); }
  .small-medium-10 {
    width: 78.33333%;
    width: calc(((99.999% / 12) * 10) - (24px)); }
  .small-medium-11 {
    width: 86.66667%;
    width: calc(((99.999% / 12) * 11) - (24px)); }
  .small-medium-12 {
    width: 95%;
    width: calc(((99.999% / 12) * 12) - (24px)); }
  .small-medium-5--flush {
    float: left;
    width: 41.66667%; }
  .small-medium-6--flush {
    float: left;
    width: 50%; }
  .small-medium-7--flush {
    float: left;
    width: 58.33333%; } }

@media (min-width: 48em) {
  .medium-1 {
    width: 3.33333%;
    width: calc(((99.999% / 12) * 1) - (24px)); }
  .medium-2 {
    width: 11.66667%;
    width: calc(((99.999% / 12) * 2) - (24px)); }
  .medium-3 {
    width: 20%;
    width: calc(((99.999% / 12) * 3) - (24px)); }
  .medium-4 {
    width: 28.33333%;
    width: calc(((99.999% / 12) * 4) - (24px)); }
  .medium-5 {
    width: 36.66667%;
    width: calc(((99.999% / 12) * 5) - (24px)); }
  .medium-6 {
    width: 45%;
    width: calc(((99.999% / 12) * 6) - (24px)); }
  .medium-7 {
    width: 53.33333%;
    width: calc(((99.999% / 12) * 7) - (24px)); }
  .medium-8 {
    width: 61.66667%;
    width: calc(((99.999% / 12) * 8) - (24px)); }
  .medium-9 {
    width: 70%;
    width: calc(((99.999% / 12) * 9) - (24px)); }
  .medium-10 {
    width: 78.33333%;
    width: calc(((99.999% / 12) * 10) - (24px)); }
  .medium-11 {
    width: 86.66667%;
    width: calc(((99.999% / 12) * 11) - (24px)); }
  .medium-12 {
    width: 95%;
    width: calc(((99.999% / 12) * 12) - (24px)); }
  .pull-1--medium {
    margin-left: calc(((99.999% / 12) * 1 ) + (12px)); }
  .pull-3--medium {
    margin-left: calc(((99.999% / 12) * 3 ) + (12px)); }
  .pull-4--medium {
    margin-left: calc(((99.999% / 12) * 4 ) + (12px)); }
  .pull-6--medium {
    margin-left: calc(((99.999% / 12) * 6 ) + (12px)); }
  .pull-8--medium {
    margin-left: calc(((99.999% / 12) * 8 ) + (12px)); }
  .push-1--medium {
    margin-right: calc(((99.999% / 12) * 1 ) + (12px)); }
  .push-2--medium {
    margin-right: calc(((99.999% / 12) * 2 ) + (12px)); }
  .push-3--medium {
    margin-right: calc(((99.999% / 12) * 3 ) + (12px)); }
  .push-4--medium {
    margin-right: calc(((99.999% / 12) * 4 ) + (12px)); }
  .push-5--medium {
    margin-right: calc(((99.999% / 12) * 5 ) + (12px)); }
  .medium-5--flush {
    float: left;
    width: 41.66667%; }
  .medium-6--flush {
    float: left;
    width: 50%; }
  .medium-7--flush {
    float: left;
    width: 58.33333%; } }

@media (min-width: 60em) {
  .large-1 {
    width: 3.33333%;
    width: calc(((99.999% / 12) * 1) - (24px)); }
  .large-2 {
    width: 11.66667%;
    width: calc(((99.999% / 12) * 2) - (24px)); }
  .large-3 {
    width: 20%;
    width: calc(((99.999% / 12) * 3) - (24px)); }
  .large-4 {
    width: 28.33333%;
    width: calc(((99.999% / 12) * 4) - (24px)); }
  .large-5 {
    width: 36.66667%;
    width: calc(((99.999% / 12) * 5) - (24px)); }
  .large-6 {
    width: 45%;
    width: calc(((99.999% / 12) * 6) - (24px)); }
  .large-7 {
    width: 53.33333%;
    width: calc(((99.999% / 12) * 7) - (24px)); }
  .large-8 {
    width: 61.66667%;
    width: calc(((99.999% / 12) * 8) - (24px)); }
  .large-9 {
    width: 70%;
    width: calc(((99.999% / 12) * 9) - (24px)); }
  .large-10 {
    width: 78.33333%;
    width: calc(((99.999% / 12) * 10) - (24px)); }
  .large-11 {
    width: 86.66667%;
    width: calc(((99.999% / 12) * 11) - (24px)); }
  .large-12 {
    width: 95%;
    width: calc(((99.999% / 12) * 12) - (24px)); }
  .pull-1--large {
    margin-left: calc(((99.999% / 12) * 1 ) + (12px)); }
  .pull-6--large {
    margin-left: calc(((99.999% / 12) * 6 ) + (12px)); }
  .push-1--large {
    margin-right: calc(((99.999% / 12) * 1 ) + (12px)); }
  .push-2--large {
    margin-right: calc(((99.999% / 12) * 2 ) + (12px)); }
  .pull-3--large {
    margin-left: calc(((99.999% / 12) * 3 ) + (12px)); }
  .push-3--large {
    margin-right: calc(((99.999% / 12) * 3 ) + (12px)); }
  .push-4--large {
    margin-right: calc(((99.999% / 12) * 4 ) + (12px)); }
  .large-6--flush {
    float: left;
    width: 50%; } }

@media (min-width: 90.1em) {
  .xlarge-1 {
    width: 3.33333%;
    width: calc(((99.999% / 12) * 1) - (24px)); }
  .xlarge-2 {
    width: 11.66667%;
    width: calc(((99.999% / 12) * 2) - (24px)); }
  .xlarge-3 {
    width: 20%;
    width: calc(((99.999% / 12) * 3) - (24px)); }
  .xlarge-4 {
    width: 28.33333%;
    width: calc(((99.999% / 12) * 4) - (24px)); }
  .xlarge-5 {
    width: 36.66667%;
    width: calc(((99.999% / 12) * 5) - (24px)); }
  .xlarge-6 {
    width: 45%;
    width: calc(((99.999% / 12) * 6) - (24px)); }
  .xlarge-7 {
    width: 53.33333%;
    width: calc(((99.999% / 12) * 7) - (24px)); }
  .xlarge-8 {
    width: 61.66667%;
    width: calc(((99.999% / 12) * 8) - (24px)); }
  .xlarge-9 {
    width: 70%;
    width: calc(((99.999% / 12) * 9) - (24px)); }
  .xlarge-10 {
    width: 78.33333%;
    width: calc(((99.999% / 12) * 10) - (24px)); }
  .xlarge-11 {
    width: 86.66667%;
    width: calc(((99.999% / 12) * 11) - (24px)); }
  .xlarge-12 {
    width: 95%;
    width: calc(((99.999% / 12) * 12) - (24px)); }
  .push-1--xlarge {
    margin-right: calc(((99.999% / 12) * 1 ) + (12px)); }
  .push-3--xlarge {
    margin-right: calc(((99.999% / 12) * 3 ) + (12px)); }
  .push-4--xlarge {
    margin-right: calc(((99.999% / 12) * 4 ) + (12px)); } }

@media (min-width: 48em) {
  .col--border-right-medium-up {
    overflow: visible;
    position: relative; }
    .col--border-right-medium-up:after {
      background-color: #cccccc;
      bottom: 0;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px; } }

body {
  background: #ffffff;
  padding-top: 55px; }
  @media (min-width: 48em) {
    body {
      padding-top: 118px; } }

.centered {
  margin-left: auto;
  margin-right: auto; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

@media (min-width: 48em) {
  .pull-left--medium {
    float: left; } }

@media (min-width: 48em) {
  .pull-right--medium {
    float: right; } }

@media (min-width: 60em) {
  .pull-right--large {
    float: right; } }

.clearfix:before, .clearfix:after,
.cf:before,
.cf:after {
  content: "";
  display: table; }

.clearfix:after,
.cf:after {
  clear: both; }

.block {
  display: block; }

.inline-block {
  display: inline-block !important; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.bottom-0 {
  bottom: 0; }

.text-right {
  text-align: right; }

@media (min-width: 48em) {
  .text-right--medium {
    text-align: right; } }

@media (min-width: 60em) {
  .text-right--large {
    text-align: right; } }

.vertical-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

@media (min-width: 48em) {
  .vertical-align--medium {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); } }

.vertical-align--centered {
  left: 0;
  right: 0; }

.clip {
  overflow: hidden; }

.video-wrapper {
  cursor: pointer;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }

.video-wrapper iframe, .video-wrapper object, .video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.flex__equal-height {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  .flex__equal-height .col {
    float: none; }

.image-cover {
  position: absolute;
  max-width: none;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0; }

@media (min-width: 48em) {
  .image-cover-medium-up {
    position: absolute;
    max-width: none;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0; } }

@media (min-width: 48em) {
  .image-cover-medium-up--top {
    top: 0;
    transform: translate(-50%, 0); } }

@media (min-width: 37.5em) {
  .address {
    min-height: 330px; } }

.box-min-height {
  padding-bottom: 4.5rem !important;
  min-height: 250px; }
  @media (min-width: 37.5em) {
    .box-min-height {
      min-height: 250px; } }
  @media (min-width: 48em) {
    .box-min-height {
      min-height: 250px; } }
  @media (min-width: 60em) {
    .box-min-height {
      min-height: 280px; } }
  @media (min-width: 90.1em) {
    .box-min-height {
      min-height: 250px; } }

/*=Z-Index scale
------------------------------------------------*/
.z1 {
  z-index: 100; }

.z2 {
  z-index: 200; }

.z3 {
  z-index: 300; }

.z4 {
  z-index: 400; }

.z5 {
  z-index: 500; }

.z6 {
  z-index: 600; }

.z7 {
  z-index: 700; }

.z8 {
  z-index: 800; }

.z9 {
  z-index: 900; }

.z10 {
  z-index: 1000; }

.push {
  margin: 1.5rem !important; }

.push--top {
  margin-top: 1.5rem !important; }

.push--right {
  margin-right: 1.5rem !important; }

.push--bottom {
  margin-bottom: 1.5rem !important; }

.push--left {
  margin-left: 1.5rem !important; }

.push--ends {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.push--sides {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.push-half {
  margin: 0.75rem !important; }

.push-half--top {
  margin-top: 0.75rem !important; }

.push-half--right {
  margin-right: 0.75rem !important; }

.push-half--bottom {
  margin-bottom: 0.75rem !important; }

.push-half--left {
  margin-left: 0.75rem !important; }

.push-half--ends {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important; }

.push-half--sides {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important; }

.push-double--sides {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.push-double--bottom {
  margin-bottom: 1.5rem !important; }
  @media (min-width: 48em) {
    .push-double--bottom {
      margin-bottom: 3rem !important; } }

.push-double--ends {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }
  @media (min-width: 48em) {
    .push-double--ends {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; } }

.push-double--top {
  margin-top: 1.5rem !important; }
  @media (min-width: 48em) {
    .push-double--top {
      margin-top: 3rem !important; } }

.push-quarter--bottom {
  margin-bottom: 0.375rem !important; }

.push-quarter--top {
  margin-top: 0.375rem !important; }

.push-up--line {
  margin-top: 1px; }

.flush {
  margin: 0 !important; }

.flush--top {
  margin-top: 0 !important; }

.flush--right {
  margin-right: 0 !important; }

.flush--bottom {
  margin-bottom: 0 !important; }

.flush--left {
  margin-left: 0 !important; }

.flush--ends {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.flush--sides {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.soft {
  padding: 1.5rem !important; }

.soft--top {
  padding-top: 1.5rem !important; }

.soft--right {
  padding-right: 1.5rem !important; }

.soft--bottom {
  padding-bottom: 1.5rem !important; }

.soft--left {
  padding-left: 1.5rem !important; }

.soft--ends {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.soft--sides {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

@media (min-width: 60em) {
  .soft--right--large {
    padding-right: 1.5rem !important; } }

@media (min-width: 60em) {
  .soft--double-right--large {
    padding-right: 3rem !important; } }

.soft-double {
  padding: 1.5rem !important; }
  @media (min-width: 60em) {
    .soft-double {
      padding: 3rem !important; } }

.soft-double--ends {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }
  @media (min-width: 48em) {
    .soft-double--ends {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; } }

.soft-double--top {
  padding-top: 1.5rem !important; }
  @media (min-width: 60em) {
    .soft-double--top {
      padding-top: 3rem !important; } }

.soft-double--bottom {
  padding-bottom: 1.5rem !important; }
  @media (min-width: 60em) {
    .soft-double--bottom {
      padding-bottom: 3rem !important; } }

.soft-triple--bottom {
  padding-bottom: 4.5rem !important; }

.soft-double--sides {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }
  @media (min-width: 60em) {
    .soft-double--sides {
      padding-right: 3rem !important;
      padding-left: 3rem !important; } }

.soft-triple--sides {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }
  @media (min-width: 60em) {
    .soft-triple--sides {
      padding-right: 4.5rem !important;
      padding-left: 4.5rem !important; } }

.soft-half {
  padding: 0.75rem !important; }

.soft-half--top {
  padding-top: 0.75rem !important; }

.soft-half--right {
  padding-right: 0.75rem !important; }

.soft-half--bottom {
  padding-bottom: 0.75rem !important; }

.soft-half--left {
  padding-left: 0.75rem !important; }

.soft-half--ends {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important; }

.soft-half--sides {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important; }

.soft-quarter--ends {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important; }

.soft-quarter--bottom {
  padding-bottom: 0.375rem !important; }

.hard {
  padding: 0 !important; }

.hard--top {
  padding-top: 0 !important; }

.hard--right {
  padding-right: 0 !important; }

.hard--bottom {
  padding-bottom: 0 !important; }

.hard--left {
  padding-left: 0 !important; }

.hard--ends {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.hard--sides {
  padding-left: 0 !important;
  padding-right: 0 !important; }

@media (min-width: 60em) {
  .gutter-right--large {
    padding-right: 2.25rem !important; } }

@media (min-width: 60em) {
  .gutter-left--large {
    padding-left: 2.25rem !important; } }

@media (min-width: 48em) {
  .gutter-left--medium {
    padding-left: 2.25rem !important; } }

@media (min-width: 48em) {
  .show-for-small-only {
    display: none !important; } }

@media (max-width: 47.95em) {
  .show-for-medium-up {
    display: none !important; } }

@media (min-width: 48em) {
  .show-for-medium-up {
    display: inherit !important; } }

@media (max-width: 47.95em) {
  .show-for-medium-only {
    display: none !important; } }

@media (min-width: 48em) {
  .show-for-medium-only {
    display: inherit !important; } }

@media (min-width: 60em) {
  .show-for-medium-only {
    display: none !important; } }

@media (max-width: 59.95em) {
  .show-for-large-up {
    display: none !important; } }

@media (max-width: 59.95em) {
  .show-for-large-only {
    display: none !important; } }

@media (min-width: 60em) {
  .show-for-large-only {
    display: inherit !important; } }

@media (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media (max-width: 74.95em) {
  .show-for-xlarge-up {
    display: none !important; } }

@media (max-width: 74.95em) {
  .show-for-xlarge-only {
    display: none !important; } }

.hide-for-small-only {
  display: none; }
  @media (min-width: 48em) {
    .hide-for-small-only {
      display: inherit !important; } }

@media (min-width: 48em) {
  .hide-for-medium-up {
    display: none !important; } }

@media (min-width: 48em) {
  .hide-for-medium-only {
    display: none !important; } }

@media (min-width: 60em) {
  .hide-for-medium-only {
    display: inherit !important; } }

@media (min-width: 59.95em) {
  .hide-for-large-up {
    display: none !important; } }

@media (min-width: 60em) {
  .hide-for-large-only {
    display: none !important; } }

@media (min-width: 75em) {
  .hide-for-large-only {
    display: inherit !important; } }

@media (min-width: 74.95em) {
  .hide-for-xlarge-up {
    display: none !important; } }

.hide-for-xlarge-only {
  display: none; }
  @media (max-width: 74.95em) {
    .hide-for-xlarge-only {
      display: inherit !important; } }

@media (max-width: 48em) {
  .hide-on-small {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media (min-width: 60em) {
  .hide-on-large {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.hide,
.toggle {
  display: none; }

.toggle.show {
  display: block; }

/* Cards
--------------------------------------------------------------*/
.card {
  background-color: #ffffff;
  display: block; }

.card__block {
  padding: 1.5rem 1.5rem 1.5rem 1.5rem; }

.card__icon {
  position: absolute;
  bottom: -1px;
  left: -1px; }

.related {
  background-color: #333333;
  margin-bottom: 1.5rem; }
  @media (min-width: 48em) {
    .related {
      background-color: transparent; } }
  .related .badge {
    z-index: 99;
    margin: 1.5rem 0 0.75rem 1.5rem; }
    @media (min-width: 48em) {
      .related .badge {
        margin: 0;
        position: absolute;
        top: 1.5rem;
        left: 1.5rem; } }

@media (min-width: 48em) {
  .related--flexi {
    background-color: transparent;
    display: flex;
    width: 33.33%;
    margin: 0;
    padding: 0.375rem; } }

.related__ratio {
  width: 100%;
  height: 0;
  padding-bottom: 60%; }
  @media (min-width: 37.5em) {
    .related__ratio {
      width: 100%;
      height: 0;
      padding-bottom: 100%; } }
  @media (min-width: 48em) {
    .related__ratio {
      width: 100%;
      height: 0;
      padding-bottom: 60%; } }

@media (min-width: 48em) {
  .related__ratio--2x1 {
    width: 100%;
    height: 0;
    padding-bottom: 50%; } }

@media (min-width: 37.5em) {
  .related__content {
    float: left;
    width: 60%; } }

@media (min-width: 48em) {
  .related__content {
    float: none;
    width: auto; } }

@media (min-width: 48em) {
  .related--2col {
    width: 50%; } }

.related__link {
  cursor: pointer;
  width: 100%;
  position: relative; }
  .related__link:before, .related__link:after {
    content: "";
    display: table; }
  .related__link:after {
    clear: both; }
  @media (min-width: 48em) {
    .related__link {
      flex: 1 0 auto; } }

.related__image {
  background-size: cover;
  background-position: center center; }
  @media (min-width: 37.5em) {
    .related__image {
      float: left;
      width: 40%; } }
  @media (min-width: 48em) {
    .related__image {
      float: none;
      width: auto; } }

.related__summary {
  background: #333333;
  padding: 0 1.5rem 1.5rem 1.5rem; }
  @media (min-width: 48em) {
    .related__summary {
      padding: 1.5rem; } }
  @media (min-width: 60em) {
    .related__summary {
      background-color: rgba(0, 0, 0, 0.6);
      bottom: 0;
      position: absolute;
      width: 100%; } }

.related--report .related__content {
  float: none;
  padding-bottom: 1.5rem; }
  @media (min-width: 48em) {
    .related--report .related__content {
      padding-bottom: 0; } }

.related--report .related__summary {
  background-color: transparent;
  margin-bottom: 3rem;
  position: static; }
  @media (min-width: 48em) {
    .related--report .related__summary {
      padding-top: 3rem; } }
  @media (min-width: 60em) {
    .related--report .related__summary {
      padding-top: 3rem; } }

.related--event .related__summary {
  width: 100%; }
  @media (min-width: 90.1em) {
    .related--event .related__summary {
      top: 0;
      width: 60%;
      padding-top: 4.5rem; } }

.map {
  width: 100%;
  height: 700px; }

.infobox {
  background: #051b3f;
  padding: 12px 16px 16px 16px;
  color: #fff; }

.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-prev-next-button {
  display: none;
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media (min-width: 90.1em) {
    .flickity-prev-next-button {
      display: block; } }

.flickity-prev-next-button:hover {
  background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F; }

.flickity-prev-next-button:active {
  opacity: 0.6; }

.flickity-prev-next-button.previous {
  left: -50px; }

.flickity-prev-next-button.next {
  right: -50px; }

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto; }

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-prev-next-button .arrow {
  fill: #333; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.carousel-cell {
  width: 100%;
  margin-right: 24px; }
  @media (min-width: 48em) {
    .carousel-cell {
      width: 40%; } }
  @media (min-width: 60em) {
    .carousel-cell {
      width: 34%; } }
  @media (min-width: 90.1em) {
    .carousel-cell {
      width: 28%; } }

.carousel-cell__item {
  min-height: 15rem; }
  @media (min-width: 48em) {
    .carousel-cell__item {
      min-height: 18rem; } }
  @media (min-width: 60em) {
    .carousel-cell__item {
      min-height: 16rem; } }

.gallery {
  border: 1px solid #e6e6e6;
  width: 100%;
  position: relative; }
  .gallery .nav-actions--inline {
    left: 1rem;
    top: 1.5rem; }
    @media (min-width: 60em) {
      .gallery .nav-actions--inline {
        left: 1.5rem; } }
    @media (min-width: 90.1em) {
      .gallery .nav-actions--inline {
        left: 2rem; } }

.gallery__image {
  width: 100%;
  height: auto;
  display: block; }

.gallery__trigger {
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%; }

.gallery__trigger-icon {
  display: block;
  fill: #fff;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem; }

.gallery__trigger:hover {
  background-color: rgba(0, 0, 0, 0.1); }
  .gallery__trigger:hover .gallery__trigger-icon circle {
    fill: #ffffff; }
  .gallery__trigger:hover .gallery__trigger-icon g {
    fill: #00a4e3; }

.gallery__overlay {
  background-color: #333333;
  padding: 1.5rem 1.5rem 1.5rem 4.5rem;
  color: #fff;
  min-height: 90px; }
  @media (min-width: 48em) {
    .gallery__overlay {
      padding: 1.5rem 8.5%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.8); } }

@media (min-width: 60em) {
  .gallery__intro {
    max-width: 80%; } }

.modal-gallery__outer {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity .5s ease; }

.modal-gallery__outer.active {
  display: block;
  opacity: 1; }

.modal-gallery__img-container {
  text-align: center; }

.modal-gallery__img {
  margin: 80px auto 0 auto;
  max-width: 80%;
  max-height: 80vh; }

.modal-gallery__img--scrollable {
  max-height: none; }

.modal-gallery__item {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden; }

.modal-gallery__item.active {
  opacity: 1;
  visibility: visible; }

.modal-gallery__next {
  position: fixed;
  bottom: 50%;
  right: 25px; }

.modal-gallery__previous {
  position: fixed;
  bottom: 50%;
  left: 25px; }

.modal-gallery__close {
  position: fixed;
  top: 15px;
  right: 25px; }

.modal-gallery__close:hover svg,
.modal-gallery__previous:hover svg,
.modal-gallery__next:hover svg {
  opacity: .8; }

.modal-gallery__details {
  position: fixed;
  bottom: 0;
  left: 120px;
  right: 120px;
  padding: 0 0 40px 0;
  color: #fff; }

.modal-gallery__total {
  position: absolute;
  bottom: 15px;
  right: 25px;
  color: #fff; }

.loading:after {
  color: #fff;
  z-index: 1;
  width: 100%;
  position: fixed;
  display: block;
  text-align: center;
  top: 45%;
  content: 'Loading...'; }

.modal {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out; }

.modal.active {
  opacity: 1;
  visibility: visible;
  overflow: auto;
  position: fixed;
  width: auto;
  height: auto;
  bottom: 0;
  right: 0;
  z-index: 1000; }

.modal__close-btn {
  position: absolute;
  right: 20px;
  top: 20px; }

.modal__close {
  cursor: pointer;
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100; }

.js-wall-item {
  will-change: height; }

.js-wall-trigger {
  width: 100%;
  display: block;
  cursor: pointer;
  background-color: transparent;
  text-align: left; }

.js-wall-trigger:after,
.js-wall-row-trigger:after {
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 18px solid #28363d;
  bottom: -18px;
  content: '';
  display: none;
  height: 0;
  margin-right: -18px;
  right: 50%;
  position: absolute;
  width: 0;
  z-index: 100; }

.js-wall-trigger--mono:after,
.js-wall-row-trigger--mono:after {
  border-top: 18px solid #ffffff; }

.js-wall-trigger[aria-expanded=true]:after,
.js-wall-row-trigger[aria-expanded=true]:after {
  display: block; }

.js-wall-child {
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  border: 0;
  max-height: 0;
  z-index: 2;
  background: #262626; }

.js-wall-close,
.js-wall-previous,
.js-wall-next {
  position: absolute;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  top: 40%;
  z-index: 1; }
  .js-wall-close svg,
  .js-wall-previous svg,
  .js-wall-next svg {
    fill: #ffffff; }

.js-wall-previous,
.js-wall-next {
  visibility: hidden; }
  @media (min-width: 90.1em) {
    .js-wall-previous,
    .js-wall-next {
      visibility: visible; } }

.js-wall-close {
  top: 20px;
  right: 20px; }

.js-wall-previous {
  left: 30px; }

.js-wall-next {
  right: 30px; }

.js-wall-panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  background-color: #006288;
  clear: left;
  overflow: hidden;
  visibility: hidden; }

.js-wall--is-open .js-wall-panel {
  visibility: visible;
  z-index: 1; }

.js-wall-panel-inner {
  opacity: 0;
  will-change: opacity;
  transition: opacity 260ms ease;
  padding-top: 3rem; }
  @media (min-width: 60em) {
    .js-wall-panel-inner {
      padding-top: 1.5rem; } }

.js-is-animating.js-wall--is-open .js-wall-panel-inner {
  opacity: 0;
  transition: opacity 260ms ease; }

.js-wall--is-open .js-wall-panel-inner {
  opacity: 1; }

/*
 * Single row wall
 */
.js-wall-row-trigger {
  width: 100%;
  display: block;
  cursor: pointer;
  text-align: left; }

.js-wall-row-child {
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  border: 0;
  max-height: 0;
  z-index: 2;
  background: #262626; }

.js-wall-row-panel {
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  width: auto;
  background-color: #006288;
  clear: left;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  will-change: height;
  transition: height 260ms ease; }

.js-wall-row--is-open {
  will-change: margin-bottom;
  transition: margin-bottom 260ms ease; }

.js-wall-row--is-open .js-wall-row-panel {
  visibility: visible;
  z-index: 1; }

.js-wall-row-panel-inner {
  opacity: 0;
  will-change: opacity;
  transition: opacity 260ms ease; }

.js-wall-row--is-open .js-wall-row-panel-inner {
  opacity: 1; }

.js-wall-row-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent; }

.js-wall-row-close svg {
  fill: white; }

.js-wall-modal--on {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  visibility: visible;
  z-index: 200;
  overflow: inherit;
  height: auto; }
  @media (min-width: 48em) {
    .js-wall-modal--on {
      top: 116px; } }

.js-wall-row-panel.js-wall-modal--on,
.js-wall-panel.js-wall-modal--on {
  display: block; }

.js-wall-modal--on .js-wall-panel-inner,
.js-wall-modal--on .js-wall-row-panel-inner {
  padding-top: 1.5rem;
  opacity: 1;
  height: 100%;
  overflow-y: scroll;
  position: static;
  -webkit-overflow-scrolling: touch; }

.noscroll {
  position: fixed;
  overflow: hidden; }

.hero {
  background-color: #232323;
  position: relative;
  overflow: hidden;
  min-height: 400px; }
  @media (min-width: 37.5em) {
    .hero {
      min-height: 480px; } }
  @media (min-width: 48em) {
    .hero {
      min-height: 580px; } }

.hero__wrap {
  padding: 1.5rem 0; }
  @media (min-width: 48em) {
    .hero__wrap {
      background-color: rgba(0, 0, 0, 0.7);
      padding: 2.25rem 0 2.25rem 0; } }

.hero__title {
  line-height: 1.05;
  margin-bottom: 0.75rem;
  font-size: 3rem;
  font-size: calc(28px + (38 - 28)*(100vw - 300px)/(599 - 300)); }
  @media (min-width: 37.5em) {
    .hero__title {
      font-size: 4.5rem;
      font-size: calc(40px + (58 - 40)*(100vw - 600px)/(767 - 600)); } }
  @media (min-width: 48em) {
    .hero__title {
      font-size: 5.2rem;
      font-size: calc(48px + (64 - 48)*(100vw - 768px)/(959 - 768));
      margin-bottom: 1.5rem; } }
  @media (min-width: 60em) {
    .hero__title {
      font-size: calc(48px + (72 - 48)*(100vw - 960px)/(1399 - 960)); } }
  @media (min-width: 90.1em) {
    .hero__title {
      font-size: 4.4rem;
      margin-bottom: 2.25rem; } }

.hero__panel {
  background-color: #333333;
  width: 100%; }
  @media (min-width: 48em) {
    .hero__panel {
      background-color: transparent;
      bottom: 0;
      position: absolute; } }

.hero__intro {
  background-color: #232323;
  padding: 24px; }
  @media (max-width: 59.938em) {
    .hero__intro {
      height: auto !important; } }
  @media (min-width: 48em) {
    .hero__intro {
      background-color: transparent;
      padding: 3rem 0 3rem 0;
      float: left;
      width: calc(60% - (24px));
      margin-left: 12px;
      margin-right: 12px; } }
  @media (min-width: 60em) {
    .hero__intro {
      width: calc(50% - (24px)); } }
  @media (min-width: 90.1em) {
    .hero__intro {
      width: calc(50% - (24px)); } }

.hero__intro--deep {
  padding: 24px 12px; }
  @media (min-width: 48em) {
    .hero__intro--deep {
      padding: 3rem 12px 18rem 12px; } }
  @media (min-width: 60em) {
    .hero__intro--deep {
      padding: 3rem 0 21rem 0; } }

.hero__summary {
  font-size: 1.2rem;
  line-height: 1.4; }
  @media (min-width: 60em) {
    .hero__summary {
      font-size: 1.2rem; } }
  @media (min-width: 90.1em) {
    .hero__summary {
      font-size: 1.3rem; } }

@media (min-width: 48em) {
  .hero-min-height {
    min-height: 420px; } }

@media (min-width: 48em) {
  .hero-form {
    min-height: 250px; } }

@media (min-width: 48em) {
  .hero__contact {
    position: absolute;
    right: 12px;
    top: 0; } }

@media (min-width: 48em) {
  .hero__contact-container {
    position: absolute;
    top: 0;
    width: 100%; } }

.hero__contact--sub {
  margin-top: 1.5rem; }
  @media (min-width: 48em) {
    .hero__contact--sub {
      top: 0;
      margin-top: 0; } }

.fakesubmit .success {
  opacity: 0;
  height: 0;
  transition: opacity .5s ease-in-out; }

.fakesubmit.active .btn--primary {
  display: none; }

.fakesubmit.active .success {
  opacity: 1;
  height: auto; }

.hero-home {
  position: relative; }

.hero-news {
  background: #051b3f;
  float: left;
  width: 100%; }
  .hero-news:before, .hero-news:after {
    content: "";
    display: table; }
  .hero-news:after {
    clear: both; }
  @media (min-width: 48em) {
    .hero-news {
      background: #cccccc;
      float: right;
      z-index: 999;
      width: calc(40% - (24px));
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      margin-left: 12px;
      margin-right: 12px; } }
  @media (min-width: 90.1em) {
    .hero-news {
      width: calc(30% - (24px)); } }

@media (min-width: 48em) {
  .hero-news--2up {
    background: #EBA912; } }

.hero-news__item {
  background-image: url("../img/branding/hexagon-dark.svg");
  background-repeat: no-repeat;
  background-position: 80px -400px;
  display: block;
  position: relative;
  padding: 24px 12px; }
  .hero-news__item:after {
    background-color: transparent;
    bottom: 0;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transition: background-color 0.2s ease-in-out; }
  @media (min-width: 48em) {
    .hero-news__item {
      float: none;
      width: 100%;
      min-height: auto;
      padding: 1.5rem 2.25rem 2.25rem 2.25rem; } }
  .hero-news__item:hover:after {
    background-color: rgba(0, 0, 0, 0.1); }

@media (min-width: 48em) {
  .hero-news__item--half {
    height: 50%; } }

.hero-news--overlay {
  background: #051b3f;
  padding: 1.5rem; }
  @media (min-width: 48em) {
    .hero-news--overlay {
      background: rgba(0, 0, 0, 0.7); } }
  @media (min-width: 60em) {
    .hero-news--overlay {
      padding: 2.25rem; } }

.hero-overlay__item {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  @media (min-width: 48em) {
    .hero-overlay__item {
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem; } }
  .hero-overlay__item:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0; }

.hero-overlay__title {
  font-size: 1.2rem;
  line-height: 1.3; }
  @media (min-width: 48em) {
    .hero-overlay__title {
      font-size: 1.1rem; } }
  @media (min-width: 60em) {
    .hero-overlay__title {
      font-size: 1.25rem; } }
  @media (min-width: 90.1em) {
    .hero-overlay__title {
      font-size: 1.4rem; } }

.hero-hexagon {
  overflow: visible;
  min-height: auto; }
  @media (min-width: 48em) {
    .hero-hexagon {
      background-image: url("../img/branding/hexagon-half.svg");
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-position: right -150px;
      min-height: auto; } }

@media (min-width: 48em) {
  .hero-hexagon--dark {
    background-image: url("../img/branding/hexagon-half--dark.svg"); } }

.hero-hexagon__summary {
  padding: 1.5rem 0; }
  @media (min-width: 48em) {
    .hero-hexagon__summary {
      padding: 2.25rem 0; } }
