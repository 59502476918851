.modal {
    position: absolute;
    overflow: hidden;
    width:	0;
    height:0;
    background-color: rgba(0,0,0,.9);
    top:0;
    left:0;
    opacity:0;
    visibility:hidden;
    transition:opacity 0.4s ease-in-out;
}
.modal.active {
    opacity:1;
    visibility: visible;
    overflow: auto;
    position: fixed;
    width: auto;
    height: auto;
    bottom: 0;
    right: 0;
    z-index: $zIndex-10;
}

.modal__close-btn{
    position: absolute;
    right:20px;
    top:20px;    
}

.modal__close{
    cursor:pointer;
    background:transparent;
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    z-index: $zIndex-1;
}