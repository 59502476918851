// Grid
//
// Flexible, efficient, responsive layout 
//
// Style guide: grid

// Using the grid
//
//<blockquote class="blockquote push-double--bottom"><p class="blockquote__text">The grid system is an aid, not a guarantee. It permits a number of possible uses and each designer can look for a solution appropriate to his personal style. But one must learn how to use the grid; it is an art that requires practice</p><cite>Josef Muller-Brockmann</cite></blockquote>
//
// A flexible responsive universal grid is the fundamental layout tool. This will enable users to create multi device layouts quickly and easily with proposed default 12-column nestable grid allowing a large range of layouts and templates across all required screen sizes. It provides a cohesive foundation and flexibility to deal with wide range of layout problems while maintaining a consistency across all products it is used in. Should the default 12 columns not prove adequate for the design task at hand then it can be easily customised via the SASS based code to modify. 
//
// Style guide: grid.Using the grid

// Default 12 col
//
// The default grid set up is 12 columns. Column width, gutter with and number of columns can all be customised in _grid.scss
//
//Markup:
//<div class="clearfix text--center">
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//  <div class="col small-6 medium-4 large-1 panel--grid">
//    <span class="show-for-small-only">6 col</span>
//    <span class="show-for-medium-only">4 col</span>
//    <span class="show-for-large-up">1 col</span>
//  </div>
//</div>
//
// Style guide: grid.Default 12 column

// Nesting the grid
//
//Markup:
//<div class="clearfix row push--bottom">          
//  <div class="col large-8 bg-light-grey-1 soft">
//    <p class="show-on-medium alpha push--bottom">
//      This is an eight column section, starting with this paragraph.
//      Below this you'll find another row with two sections.
//    </p>
//    <div class="row">
//      <div class="col medium-6 bg-white push--bottom">
//        <img src="http://placehold.it/500x300/666666/cccccc" class="push-half--bottom">
//        <h3 class="alpha primary-font soft-half--sides">
//          Section (.medium-6)
//        </h3>
//        <p class="soft-half">
//          This is a nested row with two six column sections.
//        </p>
//      </div>
//      <div class="col medium-6 bg-white push--bottom">
//        <img src="http://placehold.it/500x300/666666/cccccc" class="push-half--bottom">
//        <h3 class="alpha primary-font soft-half--sides">
//          Section (.medium-6)
//        </h3>
//        <p class="soft-half">
//          This is a nested row with two six column sections.
//        </p>
//      </div>
//    </div>
//    <p class="alpha push--bottom">
//      Now the nested row has been closed, and we're back to the original eight column section.
//    </p>
//  </div>
//  <div class="col large-4 bg-light-grey-1 soft">
//    <p class="alpha push-half--bottom">
//      This is an 4 column section
//    </p>
//    <div class="row">
//      <div class="col small-4">
//        <img src="http://placehold.it/150x150/666666/cccccc">
//      </div>
//      <div class="col small-8">
//        <p class="gamma">
//          This is a callout with three columns on the left (for the avatar) and nine columns here for the text. This row is inside the four column. 
//        </p>
//      </div>
//    </div>
//  </div>
//</div>
//
// Style guide: grid.Nesting

// Customising the grid
//
// <h2>Grid system</h2>
// <p>_grid.scss contains a repsonive grid. The default settings are 12 columns and 100% full width. </p>
// <p>Each set of columns must be contained in a wrapper. Each column width is created by adding classes to the element. One class to declare this is a column <code>class="col"</code>,  and successive classes depending on columns required at small, medium, large and xlarge screen sizes eg <code>class="col medium-6 large-4 xlarge-3"</code></p>
// <p>Each set of columns must be contained in a wrapper. Each column width is created by adding classes to the element. One class to declare this is a column <code>class="col"</code>,  and successive classes depending on size required at small, medium, large and xlarge screen sizes eg <code>class="col medium-6 large-4 xlarge-3"</code></p>
// <h3>Nesting the grid.</h3>
// <p>A <code>.row </code>class must be applied to the containing element of the nested columns.</p>
// <h3>Customising the grid.</h3>
// <p><code> _grid.scss</code> contains default variables which you can freely override to suit the project layout requirements.</p>
// <ul>
// <li><code> $column-width: 70px</code></li>
// <li><code> $gutter-width: 30px</code></li>
// <li><code> $columns: 12</code></li>
// </ul>
// <p>These pixel values are then converted to the appropriate percentage values.</p>
// <h3>Screen sizes</h3>
// <ul>
// <li><code> $small-medium:37.5em;</code>(600px)</li>
// <li><code> $medium:48em;</code>(768px)</li>
// <li><code> $large:60em;</code>(960px)</li>
// <li><code> $xlarge:90em;</code>(1440px)</li>
// </ul>
//
// Style guide: grid.Customise

// = Grid
//-----------------------------------------------------------------------------//

@function column-calc($num, $spacing:$gutter){
    @return calc(((99.999% / #{$columns}) * #{$num}) - (#{$spacing}));
    }

@function column-calc-fallback($num){
    @return ((100 / $columns) * $num) - 5%;
    }
    
@mixin column($num) {
    width: column-calc-fallback($num);
    width: column-calc($num);
    }

.wrap {
    @include clearfix();
    margin: 0 auto;
    width:100%;
    @media (min-width: $bp-medium) {
        padding-left:($gutter/2);
        padding-right:($gutter/2);
        }
    @media (min-width: $bp-large) {
        max-width: 1100px;
        }
    @media (min-width: $bp-xlarge) {
        max-width: $max-container-width;
        }
    }

.wrap--large {
    @media (min-width: $bp-medium) {
        padding-left:0;
        padding-right:0;
        }
    @media (min-width: $bp-large) {
        padding-left:($gutter/2);
        padding-right:($gutter/2);
        }
    }

.wrap-flex {
    padding-left:($gutter/2);
    padding-right:($gutter/2);
    @media (min-width: $bp-medium) {
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
    }
}

.row {
    @include clearfix;
    clear:both;
    margin-left:-$gutter / 2;
    margin-right:-$gutter / 2;
    &:after {
        content:'';
        display: table;
        }
    }

.col {
    max-width:1360px;
    float:left;
    margin-left:calc(#{$gutter} / 2);
    margin-right:calc(#{$gutter} / 2);
    }

.col--inline{
    display:inline-block;
    float:none;
    vertical-align:top;
}

.col--centered {
    float: none;
    margin-left:auto !important;
    margin-right:auto  !important;
    }

.small-1 {
    @include column(1);
    }

.small-2 {
    @include column(2);
    }

.small-3 {
    @include column(3);
    }

.small-4 {
    @include column(4);
    }

.small-5 {
    @include column(5);
    }

.small-6 {
    @include column(6);
    }

.small-7 {
    @include column(7);
    }

.small-8 {
    @include column(8);
    }

.small-9 {
    @include column(9);
    }

.small-10 {
    @include column(10);
    }

.small-11 {
    @include column(11);
    }

.small-12 {
    @include column(12);
    }

.small-12--flush {
    width:100%;
}

@media(min-width:$bp-small-medium){
   .small-medium-1 {
        @include column(1);
        }
    .small-medium-2 {
        @include column(2);
        }
    .small-medium-3 {
        @include column(3);
        }
    .small-medium-4 {
        @include column(4);
        }
    .small-medium-5 {
        @include column(5);
        }
    .small-medium-6 {
        @include column(6);
        }
    .small-medium-7 {
        @include column(7);
        }
    .small-medium-8 {
        @include column(8);
        }
    .small-medium-9 {
        @include column(9);
        }
    .small-medium-10 {
        @include column(10);
        }
    .small-medium-11 {
        @include column(11);
        }
    .small-medium-12 {
        @include column(12);
        }
    .small-medium-5--flush {  
        float:left;
        width:41.66667%;
        }
    .small-medium-6--flush {  
        float:left;
        width:50%;
        }
    .small-medium-7--flush {  
        float:left;
        width:58.33333%;
        }
    }

@media(min-width:$bp-medium){
   .medium-1 {
        @include column(1);
        }
    .medium-2 {
        @include column(2);
        }
    .medium-3 {
        @include column(3);
        }
    .medium-4 {
        @include column(4);
        }
    .medium-5 {
        @include column(5);
        }
    .medium-6 {
        @include column(6);
        }
    .medium-7 {
        @include column(7);
        }
    .medium-8 {
        @include column(8);
        }
    .medium-9 {
        @include column(9);
        }
    .medium-10 {
        @include column(10);
        }
    .medium-11 {
        @include column(11);
        }
    .medium-12 {
        @include column(12);
        }
    .pull-1--medium {
        margin-left:calc(((99.999% / #{$columns}) * 1 ) + (#{$gutter / 2}));
        }
    .pull-3--medium {
        margin-left:calc(((99.999% / #{$columns}) * 3 ) + (#{$gutter / 2}));
        }
    .pull-4--medium {
        margin-left:calc(((99.999% / #{$columns}) * 4 ) + (#{$gutter / 2}));
        }
    .pull-6--medium {
        margin-left:calc(((99.999% / #{$columns}) * 6 ) + (#{$gutter / 2}));
        }
    .pull-8--medium {
        margin-left:calc(((99.999% / #{$columns}) * 8 ) + (#{$gutter / 2}));
        }
    .push-1--medium {
        margin-right:calc(((99.999% / #{$columns}) * 1 ) + (#{$gutter / 2}));
        }
    .push-2--medium {
        margin-right:calc(((99.999% / #{$columns}) * 2 ) + (#{$gutter / 2}));
        }
    .push-3--medium {
        margin-right:calc(((99.999% / #{$columns}) * 3 ) + (#{$gutter / 2}));
        }
    .push-4--medium {
        margin-right:calc(((99.999% / #{$columns}) * 4 ) + (#{$gutter / 2}));
        }
    .push-5--medium {
        margin-right:calc(((99.999% / #{$columns}) * 5 ) + (#{$gutter / 2}));
        }
    .medium-5--flush {  
        float:left;
        width:41.66667%;
        }
    .medium-6--flush {  
        float:left;
        width:50%;
        }
    .medium-7--flush {  
        float:left;
        width:58.33333%;
        }
    }

@media(min-width:$bp-large){
   .large-1 {
        @include column(1);
        }
    .large-2 {
        @include column(2);
        }
    .large-3 {
        @include column(3);
        }
    .large-4 {
        @include column(4);
        }
    .large-5 {
        @include column(5);
        }
    .large-6 {
        @include column(6);
        }
    .large-7 {
        @include column(7);
        }
    .large-8 {
        @include column(8);
        }
    .large-9 {
        @include column(9);
        }
    .large-10 {
        @include column(10);
        }
    .large-11 {
        @include column(11);
        }
    .large-12 {
        @include column(12);
        }
    .pull-1--large {
        margin-left:calc(((99.999% / #{$columns}) * 1 ) + (#{$gutter / 2}));
        }
    .pull-6--large {
        margin-left:calc(((99.999% / #{$columns}) * 6 ) + (#{$gutter / 2}));
        }
    .push-1--large {
        margin-right:calc(((99.999% / #{$columns}) * 1 ) + (#{$gutter / 2}));
        }
    .push-2--large {
        margin-right:calc(((99.999% / #{$columns}) * 2 ) + (#{$gutter / 2}));
        }
    .pull-3--large {
        margin-left:calc(((99.999% / #{$columns}) * 3 ) + (#{$gutter / 2}));
        }
    .push-3--large {
        margin-right:calc(((99.999% / #{$columns}) * 3 ) + (#{$gutter / 2}));
        }
    .push-4--large {
        margin-right:calc(((99.999% / #{$columns}) * 4 ) + (#{$gutter / 2}));
        }
    .large-6--flush {  
        float:left;
        width:50%;
        }
    }

@media(min-width:$bp-xlarge){
   .xlarge-1 {
        @include column(1);
        }
    .xlarge-2 {
        @include column(2);
        }
    .xlarge-3 {
        @include column(3);
        }
    .xlarge-4 {
        @include column(4);
        }
    .xlarge-5 {
        @include column(5);
        }
    .xlarge-6 {
        @include column(6);
        }
    .xlarge-7 {
        @include column(7);
        }
    .xlarge-8 {
        @include column(8);
        }
    .xlarge-9 {
        @include column(9);
        }
    .xlarge-10 {
        @include column(10);
        }
    .xlarge-11 {
        @include column(11);
        }
    .xlarge-12 {
        @include column(12);
        }
    .push-1--xlarge {
        margin-right:calc(((99.999% / #{$columns}) * 1 ) + (#{$gutter / 2}));
        }
    .push-3--xlarge {
        margin-right:calc(((99.999% / #{$columns}) * 3 ) + (#{$gutter / 2}));
        }
    .push-4--xlarge {
        margin-right:calc(((99.999% / #{$columns}) * 4 ) + (#{$gutter / 2}));
        }
    }


.col--border-right-medium-up {
    @media(min-width:$bp-medium){
        overflow:visible;
        position:relative;
        &:after {
            background-color: $light-grey-3;
            bottom:0;
            content:'';
            position:absolute;
            right:0;
            top:0;
            width:1px;
        }
    }
}