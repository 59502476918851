.hero{
    background-color:$off-black;
	position: relative;
    overflow:hidden;
    min-height:400px;
    @media (min-width: $bp-small-medium){
        min-height: 480px;
    }
    @media (min-width: $bp-medium){
        min-height: 580px;
    }      	
}

.hero__wrap{
	padding:$baseline 0;
	@media (min-width: $bp-medium) {
		background-color:rgba(0, 0, 0, 0.7);
		padding:$baseline*1.5 0 $baseline*1.5 0;
	}
}

.hero__title{
	line-height:1.05;
	margin-bottom: $baseline/2;
	font-size:3rem;
	font-size: calc(28px + (38 - 28)*(100vw - #{$bp-small--unitless}px)/(#{$bp-small-medium--unitless - 1} - #{$bp-small--unitless}));
	@media (min-width: $bp-small-medium) {
		font-size:4.5rem;
		font-size: calc(40px + (58 - 40)*(100vw - #{$bp-small-medium--unitless}px)/(#{$bp-medium--unitless - 1} - #{$bp-small-medium--unitless}));
	}
	@media (min-width: $bp-medium) {
		font-size:5.2rem;
		font-size: calc(48px + (64 - 48)*(100vw - #{$bp-medium--unitless}px)/(#{$bp-large--unitless - 1} - #{$bp-medium--unitless}));
		margin-bottom: $baseline;
	}
	@media (min-width: $bp-large) {
		font-size: calc(48px + (72 - 48)*(100vw - #{$bp-large--unitless}px)/(#{$bp-xlarge--unitless - 1} - #{$bp-large--unitless}));
	}
	@media (min-width: $bp-xlarge) {
		font-size:4.4rem;
		margin-bottom: $baseline*1.5;
	}
}

.hero__panel{
	background-color:$dark-grey-3;
	width:100%;
	@media (min-width: $bp-medium) {
		background-color:transparent;
		bottom:0;
		position: absolute;
	}
}

.hero__intro{
	background-color:$off-black;
	padding:$gutter;
    @media (max-width: 59.938em) {
        height:auto !important;
    }
	@media (min-width: $bp-medium) {
		background-color:transparent;
		padding:$baseline*2 0 $baseline*2 0;
		float:left;
		width:calc(60% - (#{$gutter}));
		margin-left:$gutter/2;
		margin-right:$gutter/2;
	}
	@media (min-width: $bp-large) {
		width:calc(50% - (#{$gutter}));
	}
	@media (min-width: $bp-xlarge) {
		width:calc(50% - (#{$gutter}));
	}
}

.hero__intro--deep{
	padding:$gutter $gutter/2;
	@media (min-width: $bp-medium) {
		padding:$baseline*2 $gutter/2 $baseline*12 $gutter/2;
	}
	@media (min-width: $bp-large) {
		padding:$baseline*2 0 $baseline*14 0;
	}
}

.hero__summary{
    font-size:1.2rem;
    line-height: 1.4;
    @media (min-width: $bp-large){
        font-size:1.2rem;
        }
    @media (min-width: $bp-xlarge){
        font-size:1.3rem;
        }
    }

// = Hero form
//-----------------------------------------------------------------------------//

.hero-min-height{
    @media (min-width: $bp-medium){
        min-height:420px;
	}
}

.hero-form{
    @media (min-width: $bp-medium){
        min-height:250px;
	}
}

.hero__contact{
    @media (min-width: $bp-medium){
        position:absolute;
		right:$gutter/2;
		top:0;
	}	
}

.hero__contact-container{
    @media (min-width: $bp-medium){
		position:absolute;
		top:0;
		width:100%;
	}	
}

.hero__contact--sub{
	margin-top:$baseline;
    @media (min-width: $bp-medium){
		top:0;
		margin-top:0;
	}		
}

.fakesubmit{
	.success{
		opacity:0;
		height:0;
		transition: opacity .5s ease-in-out;
	}
	&.active{
		.btn--primary{
			display:none;
		}
		.success{
			opacity:1;
			height:auto;
		}
	}
}

// = Home
//-----------------------------------------------------------------------------//

.hero-home{
	position:relative;
}

.hero-news{
    @include clearfix;
    background:$dark-blue;
	float:left;
	width:100%;
    @media (min-width: $bp-medium) {
        background:$light-grey-3;
		float:right;
        z-index: 999;
		width:calc(40% - (#{$gutter}));
		margin-top:$baseline;
		margin-bottom:$baseline;
		margin-left:$gutter/2;
		margin-right:$gutter/2;
    }
    @media (min-width: $bp-xlarge) {
		width:calc(30% - (#{$gutter}));
    }
}

.hero-news--2up{
    @media (min-width: $bp-medium) {
        background:$yellow;
    }
}

.hero-news__item{
	background-image: url('../img/branding/hexagon-dark.svg');
	background-repeat:no-repeat;
	background-position: 80px -400px;
	display:block;
	position: relative;
	padding:$gutter $gutter/2;
	
	&:after{
		background-color:transparent;
		bottom:0;
		content:'';
		position: absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		right:0;
		z-index:1;
		transition: background-color 0.2s ease-in-out;
	}
    @media (min-width: $bp-medium) {
		float:none;
		width:100%;
		min-height:auto;
		padding:$baseline $baseline*1.5 $baseline*1.5 $baseline*1.5;
    }
	&:hover{
        &:after{
            background-color:rgba(0, 0, 0, 0.1);	
        }
	}	
}

.hero-news__item--half{
    @media (min-width: $bp-medium){
        height:50%;
    }	
}

.hero-news--overlay{
    background:$dark-blue;
	padding:$baseline;
    @media (min-width: $bp-medium) {
        background:rgba(0, 0, 0, 0.7);
    }
    @media (min-width: $bp-large) {
        padding:$baseline*1.5;
    }
}

.hero-overlay__item{
    padding-bottom:$baseline;
	margin-bottom:$baseline;
    border-bottom:1px solid rgba(255, 255, 255, 0.2);

    @media (min-width: $bp-medium){
		padding-bottom:$baseline/2;
		margin-bottom:$baseline/2;
        }

    &:last-child{
        border:none;
		margin-bottom:0;
		padding-bottom:0;
    }
}

.hero-overlay__title{
    font-size:1.2rem;
    line-height: 1.3;
    @media (min-width: $bp-medium){
        font-size:1.1rem;
        }
    @media (min-width: $bp-large){
        font-size:1.25rem;
        }
    @media (min-width: $bp-xlarge){
        font-size:1.4rem;
        }
    }

// = Hero Hexagon
//-----------------------------------------------------------------------------//

.hero-hexagon{
	overflow:visible;
	min-height:auto;
    @media (min-width: $bp-medium){
		background-image: url('../img/branding/hexagon-half.svg');
		background-attachment: fixed;
		background-repeat:no-repeat;
		background-position: right -150px;
        min-height: auto;
    }
}

.hero-hexagon--dark{
    @media (min-width: $bp-medium){
		background-image: url('../img/branding/hexagon-half--dark.svg');
    }
}

.hero-hexagon__summary{
	padding:$baseline 0;
	@media (min-width: $bp-medium) {
		padding:$baseline*1.5 0;
	}
}