// Layout utilities
//
// Classes to help responsive layout
//
// Style guide: layout

// Floats
//
// Markup:
//  <div class="clearfix push--bottom bg-light-grey-1">
//      <div class="bg-mid-grey-2 white soft inline-block pull-left">.pull-left <p class="gamma">Pull left on all breakpoints</p></div>
//  </div>
//  <div class="clearfix push--bottom bg-light-grey-1">
//      <div class="bg-mid-grey-2 white soft inline-block pull-left--medium">.pull-left--medium <p class="gamma">Pull left after medium breakpoint</p></div>
//  </div>
//  <div class="clearfix push--bottom bg-light-grey-1">
//      <div class="bg-mid-grey-2 white soft inline-block pull-left--large">.pull-left--large <p class="gamma">Pull left after large breakpoint</p></div>
//  </div>
//  <div class="clearfix push--bottom bg-light-grey-1">
//      <div class="bg-mid-grey-2 white soft inline-block pull-right">.pull-right <p class="gamma">Pull right on all breakpoints</p></div>
//  </div>
//  <div class="clearfix push--bottom bg-light-grey-1">
//      <div class="bg-mid-grey-2 white soft inline-block pull-right--medium">.pull-right--medium <p class="gamma">Pull right after medium breakpoint<p></div>
//  </div>
//  <div class="clearfix push--bottom bg-light-grey-1">
//      <div class="bg-mid-grey-2 white soft inline-block pull-right--large">.pull-right--large <p class="gamma">Pull right after large breakpoint</p></div>
//  </div>
//
// Style guide: layout.floats

// Spacing
//
// Classes to help add margin and padding between elements.

// Add margins
//
// .push - All sides
// .push--top - Top margin
// .push--right - Right margin
// .push--bottom - Bottom margin
// .push--left - Left margin
// .push--ends - Vertical margins
// .push--sides - Horizontal margin
// .push-half - Half margins
// .push-half--top - Half top margin
// .push-half--right - Half right margin
// .push-half--bottom - Half bottom margin
// .push-half--left - Half left margin
// .push-half--ends - Half vertical margins
// .push-half--sides  - Half horizontal margins
//
// Markup:
// <div class="bg-margin inline-block">
//   <div class="bg-light-grey-3 soft white inline-block {{modifier_class}}">.{{modifier_class}}</div>
// </div>
//
// Style guide: layout.margins

// Remove margins
//
// .flush - All sides
// .flush--top - Remove top margin
// .flush--right - Remove right margin
// .flush--bottom - Remove bottom margin
// .flush--left - Remove left margin
// .flush--ends - Remove vertical margins
// .flush--sides - Remove horizontal margins
//
// Markup:
// <div class="bg-margin inline-block">
//   <div class="bg-light-grey-3 soft white inline-block push {{modifier_class}}">.{{modifier_class}}</div>
// </div>
//
// Style guide: layout.remove margins

// Add padding
//
// .soft - All sides
// .soft--top - Top padding
// .soft--right - Right padding
// .soft--bottom - Bottom padding
// .soft--left - Left padding
// .soft--ends - Vertical padding
// .soft--sides - Horizontal padding
// .soft-half - Half padding
// .soft-half--top - Half top padding
// .soft-half--right - Half right padding
// .soft-half--bottom - Half bottom padding
// .soft-half--left - Half left padding
// .soft-half--ends - Half vertical padding
// .soft-half--sides  - Half horizontal padding
//
// Markup:
// <div class="bg-padding white inline-block {{modifier_class}}">.{{modifier_class}}</div>
//
// Style guide: layout.padding

// Remove padding
//
// .hard - remove all padding
// .hard--top - remove top padding
// .hard--right - remove right padding
// .hard--bottom - remove bottom padding
// .hard--left - remove left padding
// .hard--ends - remove vertical padding
// .hard--sides - remove horizontal padding
//
// Markup:
// <div class="bg-padding soft white inline-block {{modifier_class}}">.{{modifier_class}}</div>
//
// Style guide: layout.remove padding

// = Floats
//-----------------------------------------------------------------------------//

body{
    background:$white;
    padding-top:55px;
    @media (min-width: $bp-medium){
        padding-top:118px;
    }
}
.centered {
    margin-left:auto;
    margin-right:auto;
    }
.pull-left {
    float:left;
    }

.pull-right {
    float:right;
    }

.pull-left--medium {
    @media (min-width: $bp-medium){
        float:left;
        }
    }

.pull-right--medium {
    @media (min-width: $bp-medium){
        float:right;
        }
    }
.pull-right--large {
    @media (min-width: $bp-large){
        float:right;
        }
    }

.clearfix,
.cf {
    @include clearfix;
    }

.block {
    display:block;
    }

.inline-block {
    display:inline-block !important;
    }

.relative {
    position:relative;
    }

.absolute {
    position:absolute;
    }

.bottom-0{
    bottom:0;
}

.text-right {
    text-align:right;
    }

.text-right--medium {
    @media (min-width: $bp-medium){
        text-align:right;
        }
    }

.text-right--large {
    @media (min-width: $bp-large){
        text-align:right;
        }
    }

.vertical-align{
    @include vertical-align;
}

.vertical-align--medium{
    @media (min-width: $bp-medium){
        @include vertical-align;
    }
}

.vertical-align--centered{
    left:0;
    right:0;
}

.clip{
    overflow:hidden;
}

.video-wrapper {
    cursor:pointer;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.video-wrapper iframe, .video-wrapper object, .video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.flex__equal-height {
    width:100%;
    display:flex;
    flex-wrap:wrap;
        .col {
            float:none;
        }
}

// = Inline image cover
//-----------------------------------------------------------------------------//

.image-cover{
    position: absolute;
    max-width: none;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:0;
}

.image-cover-medium-up{
    @media (min-width: $bp-medium) {
        position: absolute;
        max-width: none;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index:0;
    }
}

.image-cover-medium-up--top{
    @media (min-width: $bp-medium) {
        top: 0;
        transform: translate(-50%, 0);
    }
}

.address{
    @media (min-width: $bp-small-medium) {
        min-height:330px;
    }
}

.box-min-height {
    padding-bottom:$baseline*3 !important;
    min-height:250px;
    @media (min-width: $bp-small-medium){
        min-height:250px;
    }
    @media (min-width: $bp-medium){
        min-height:250px;
    }
    @media (min-width: $bp-large){
        min-height:280px;
    }
    @media (min-width: $bp-xlarge){
        min-height:250px;
    }
}

/*=Z-Index scale
------------------------------------------------*/
.z1{z-index: $zIndex-1;}
.z2{z-index: $zIndex-2;}
.z3{z-index: $zIndex-3;}
.z4{z-index: $zIndex-4;}
.z5{z-index: $zIndex-5;}
.z6{z-index: $zIndex-6;}
.z7{z-index: $zIndex-7;}
.z8{z-index: $zIndex-8;}
.z9{z-index: $zIndex-9;}
.z10{z-index: $zIndex-10;}

// = Add or remove Margin
//-----------------------------------------------------------------------------//

.push { margin:$baseline !important; }
.push--top { margin-top:$baseline !important; }
.push--right { margin-right:$baseline !important; }
.push--bottom { margin-bottom:$baseline !important; }
.push--left { margin-left:$baseline !important; }
.push--ends { margin-top:$baseline !important; margin-bottom:$baseline !important; }
.push--sides { margin-right:$baseline !important; margin-left:$baseline !important; }

.push-half { margin:$baseline/2 !important; }
.push-half--top { margin-top:$baseline/2 !important; }
.push-half--right { margin-right:$baseline/2 !important; }
.push-half--bottom { margin-bottom:$baseline/2 !important; }
.push-half--left { margin-left:$baseline/2 !important; }
.push-half--ends { margin-top:$baseline/2 !important; margin-bottom:$baseline/2 !important; }
.push-half--sides { margin-right:$baseline/2 !important; margin-left: $baseline/2 !important; }
.push-double--sides { margin-right:$baseline * 2 !important; margin-left:$baseline * 2 !important; }

.push-double--bottom {
    margin-bottom:$baseline !important;
    @media (min-width: $bp-medium){
        margin-bottom:$baseline * 2 !important;
        }
    }

.push-double--ends {
    margin-top:$baseline !important;
    margin-bottom:$baseline !important;
    @media (min-width: $bp-medium){
        margin-top:$baseline * 2 !important;
        margin-bottom:$baseline * 2 !important;
        }
    }

.push-double--top {
    margin-top:$baseline !important;
    @media (min-width: $bp-medium){
        margin-top:$baseline * 2 !important;
        }
    }

.push-quarter--bottom { margin-bottom:$baseline/4 !important; }
.push-quarter--top { margin-top:$baseline/4 !important; }

.push-up--line{
    margin-top:1px;
}

.flush { margin:0!important; }
.flush--top { margin-top: 0!important; }
.flush--right { margin-right: 0!important; }
.flush--bottom { margin-bottom:0!important; }
.flush--left { margin-left: 0!important; }
.flush--ends { margin-top: 0!important; margin-bottom:0!important; }
.flush--sides { margin-right: 0!important; margin-left:  0!important; }


// = Add or remove Padding
//-----------------------------------------------------------------------------//

.soft { padding:$baseline !important; }
.soft--top { padding-top:$baseline !important; }
.soft--right { padding-right:$baseline !important; }
.soft--bottom { padding-bottom:$baseline !important; }
.soft--left { padding-left:$baseline !important; }
.soft--ends { padding-top:$baseline !important; padding-bottom:$baseline !important; }
.soft--sides { padding-right:$baseline !important; padding-left: $baseline !important; }

.soft--right--large {
    @media (min-width: $bp-large){
        padding-right:$baseline !important;
        }
    }
.soft--double-right--large {
    @media (min-width: $bp-large){
        padding-right:$baseline * 2 !important;
        }
    }

.soft-double {
    padding:$baseline !important;
    @media (min-width: $bp-large){
        padding:$baseline * 2 !important;
        }
    }
.soft-double--ends {
    padding-top:$baseline !important;
    padding-bottom:$baseline !important;
    @media (min-width: $bp-medium){
        padding-top:$baseline * 2 !important;
        padding-bottom:$baseline * 2 !important;
        }
    }
.soft-double--top {
    padding-top:$baseline !important;
    @media (min-width: $bp-large){
        padding-top:$baseline * 2 !important;
        }
    }

.soft-double--bottom {
    padding-bottom:$baseline !important;
    @media (min-width: $bp-large){
        padding-bottom:$baseline * 2 !important;
        }
    }

.soft-triple--bottom {
    padding-bottom:$baseline * 3 !important;
}

.soft-double--sides {
    padding-right:$baseline !important;
    padding-left: $baseline !important;
    @media (min-width: $bp-large){
        padding-right:$baseline * 2 !important;
        padding-left: $baseline * 2 !important;
        }
    }

.soft-triple--sides {
    padding-right:$baseline !important;
    padding-left: $baseline !important;
    @media (min-width: $bp-large){
        padding-right:$baseline * 3 !important;
        padding-left: $baseline * 3 !important;
        }
    }

.soft-half { padding:$baseline/2 !important; }
.soft-half--top { padding-top:$baseline/2 !important; }
.soft-half--right { padding-right:$baseline/2 !important; }
.soft-half--bottom { padding-bottom:$baseline/2 !important; }
.soft-half--left { padding-left:$baseline/2 !important; }
.soft-half--ends { padding-top:$baseline/2 !important; padding-bottom:$baseline/2 !important; }
.soft-half--sides { padding-right:$baseline/2 !important; padding-left: $baseline/2 !important; }
.soft-quarter--ends { padding-top:$baseline/4 !important; padding-bottom:$baseline/4 !important; }
.soft-quarter--bottom { padding-bottom:$baseline/4 !important; }

.hard { padding: 0!important; }
.hard--top { padding-top:0!important; }
.hard--right { padding-right: 0!important; }
.hard--bottom { padding-bottom:0!important; }
.hard--left { padding-left:0!important; }
.hard--ends { padding-top:0!important; padding-bottom:0!important; }
.hard--sides { padding-left:0!important; padding-right:0!important; }

.gutter-right--large {
    @media (min-width: $bp-large){
        padding-right:$baseline * 1.5 !important;
        }
    }

.gutter-left--large {
    @media (min-width: $bp-large){
        padding-left:$baseline * 1.5 !important;
        }
    }

.gutter-left--medium {
    @media (min-width: $bp-medium){
        padding-left:$baseline * 1.5 !important;
        }
    }