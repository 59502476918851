/* Cards
--------------------------------------------------------------*/
.card{
	background-color:$white;
	display:block;
}

.card__block{
	padding:$baseline $baseline $baseline $baseline;
}

.card__icon{
	position:absolute;
	bottom:-1px;
	left:-1px;
}