.panel-aside{
    .active &{
        margin-bottom:$baseline;
    }

    @media (min-width:$bp-large) {
        position:absolute;
        top:0;
        right:$gutter/2;
        z-index:$zIndex-2;
    }
}

.panel-skyscraper {
	@media (min-width: $bp-medium) {
		position: absolute;
		right: $gutter/2;
		top:0;
	}
}

.panel-aside--overlap{
    @media (min-width:$bp-medium) {
        top:-300px;
    }
}

.panel-feature{
    padding-bottom:$baseline*1.5;
    padding-top:$baseline*1.5;
    @media (min-width: $bp-large){
        padding-bottom:$baseline*2;
        padding-top:$baseline*2;
        min-height:400px;
    }
}

.panel-cookie {
    background-color: rgba(0, 0, 0, 0.9);
    border-top:2px solid $secondary-color;
    bottom:0;
    position:fixed;
    z-index:$zIndex-10;
    width:100%;
}
.off--banner {
    display: none;
}

.panel-share{
    @include border-radius (4px);
    background:$white;
    border:1px solid $light-grey-2;
    line-height:0;
    position:absolute;
    width:40px;
}

.panel-share__link{
    border-bottom:1px solid $light-grey-2;
    display:inline-block;
    &:last-child{
        border:none;
    }
}

.panel-share__svg{
    path{
        fill:$secondary-color;
    }
    &:hover{
        path{
            fill:darken($secondary-color, 10%);
        }
    }
}

// = Toggles
//-----------------------------------------------------------------------------//
.panel-toggle{
    clip: rect(0 0 0 0);
    opacity:0;
    max-height: 0;
    transition: max-height 260ms ease-out,opacity 300ms ease-out;
    overflow: hidden;
    will-change:opacity;
    .active > &{
        clip:auto;
        max-height: 1500px;
        height:auto;
        overflow:visible;
        transition: max-height 260ms ease-in,opacity 300ms ease-in;
        opacity:1;
    }
}

.panel-toggle-small{
    @media (max-width:47.938em) {
        clip: rect(0 0 0 0);
        opacity:0;
        max-height: 0;
        transition: max-height 260ms ease-out,opacity 300ms ease-out;
        overflow: hidden;
        will-change:opacity;
        .active > &{
            clip:auto;
            max-height: 1500px;
            height:auto;
            overflow:visible;
            transition: max-height 260ms ease-in,opacity 300ms ease-in;
            opacity:1;
        }
    }
}

.panel-toggle-medium{
    @media (max-width:59.938em) {
        clip: rect(0 0 0 0);
        opacity:0;
        max-height: 0;
        transition: max-height 260ms ease-out,opacity 300ms ease-out;
        overflow: hidden;
        will-change:opacity;
        .active > &{
            clip:auto;
            max-height: 1500px;
            height:auto;
            overflow:visible;
            transition: max-height 260ms ease-in,opacity 300ms ease-in;
            opacity:1;
        }
    }
}