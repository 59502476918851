.cart {
	display:none;
	@media (min-width: $bp-medium) {
		display:inline-block;
		float:left;
		font-size: $gamma-size;
	}
}

.cart__header{
    @media (max-width: $bp-small-max) {
        position: fixed;
        width: 100%;
        z-index: $zIndex-10;
    }
}

.panel-skyscraper__wrap{
    @media (max-width: $bp-small-max) {
        overflow-y: auto;
        height:100%;
    }
}

.cart__items{
    font-weight:$semibold;
    margin-right:$baseline/4;
}

.cart__total{
    font-weight:$semibold;
    margin-right:$baseline/2;
}

.cart__currency{
    float:right;
}

.wrap--store{
    @media (min-width: $bp-medium){
        float:left;
        &:before{
			background: $light-grey-1;
			bottom: 0;
			content: '';
			display: block;
			height: 100%;
			left: -300px;
			position: absolute;
			top: 0;
			width: 50%;
			z-index: -1;
        }
    }
}

.store-container{
    @media (min-width: $bp-medium){
        min-height:1100px;
        padding-left:$baseline*1.5;
    }
    @media (min-width: $bp-large){
        padding-left:$baseline*2.5;
    }
}

.store-vertical-line{
    @media (min-width: $bp-medium){
		background: $light-grey-1;
		border-right: 1px solid $light-grey-2;
		bottom: 0;
		height: 100%;
		left: 30px;
		position: absolute;
		top: 0;
    }
}

.store-facets{
	@media (max-width:47.938em) {
		background-color: $light-grey-1;
		height: 0;
		opacity: 0;
		padding: $baseline/2 $baseline;
		position: absolute;
		top: 74px;
		visibility: hidden;
		width: 100%;
		z-index: $zIndex-2;
	}
	.active & {
		height: auto;
		margin-bottom: $baseline;
		margin-top: -$baseline;
		opacity: 1;
		visibility: visible;
	}
	@media (min-width:$bp-medium) {
		background-color: transparent;
	}
}

.results-quickview{
	background-color:tint($primary-color, 15%);
    position:relative;
    &:after {
		border-left: 18px solid transparent;
		border-right: 18px solid transparent;
		border-top: 18px solid $white;
		content: '';
		height: 0;
		margin-right: -18px;
		position: absolute;
		right: 50%;
		top: 0;
		width: 0;
		z-index: $zIndex-1;
    }
}

.pipeline-steps{
    @include clearfix;
    display:none;
    position: relative;
    @media (min-width:$bp-small-medium) {
        display:block;
    }
}

.pipeline-steps__item{
    float:left;
    text-align:left;
    text-transform:uppercase;

    &:first-child{
        .pipeline-steps__link{
            padding-left:0;
        }
    }
}

.pipeline-steps__link{
	color: tint($primary-color, 50%);
	display: inline-block;
	font-size: $delta-size;
	font-weight: $semibold;
	letter-spacing: 3px;
	margin-right: $baseline/2;
	padding-left: $baseline/2;
	padding-right: $baseline/2;
	position: relative;
	z-index: $zIndex-1;
    @media (min-width:$bp-medium) {
        font-size:$gamma-size;
    }

    .active &{
       color:$white;
    }
}