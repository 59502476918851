.js-wall-item {
    will-change: height;
}
.js-wall-trigger {
    width:100%;
    display:block;
    cursor:pointer;
    background-color:transparent;
    text-align:left;  
}

.js-wall-trigger,
.js-wall-row-trigger{
    &:after {
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-top: 18px solid shade($slate, 40%);
        bottom: -18px;
        content: '';
        display: none;
        height: 0;
        margin-right: -18px;
        right: 50%;
        position: absolute;
        width: 0;
        z-index:$zIndex-1;
    }
}

.js-wall-trigger--mono,
.js-wall-row-trigger--mono{
    &:after {
        border-top: 18px solid $white;
    }
}

.js-wall-trigger[aria-expanded=true]:after,
.js-wall-row-trigger[aria-expanded=true]:after {
    display: block;
}

.js-wall-child {
    visibility: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0); 
    overflow: hidden;
    border: 0;
    max-height:0;
    z-index: 2;
    background: #262626;
}
.js-wall-close,
.js-wall-previous,
.js-wall-next {
    position: absolute;
    font-size:2rem;
    color:#fff;
    cursor:pointer;
    top:40%;
    z-index:1;
    svg {
        fill:$white;
    }
}
.js-wall-previous,
.js-wall-next {
    visibility: hidden;
    @media (min-width: $bp-xlarge){
        visibility: visible;
    }
}
.js-wall-close {
    top:20px;
    right:20px;
}
.js-wall-previous {
    left:30px;
}
.js-wall-next {
    right:30px;
}

.js-wall-panel {
    position: absolute;
    top:0;
    left:0;
    right:0;
    width:auto;
    background-color:shade($secondary-color, 40%);
    clear:left;
    overflow:hidden;
    visibility: hidden;
}

.js-wall--is-open .js-wall-panel {
    visibility: visible;
    z-index:1;
}
.js-wall-panel-inner {
    opacity:0;
    will-change:opacity;
    transition:opacity 260ms ease;
    padding-top:$baseline*2;
    @media (min-width: $bp-large){
        padding-top:$baseline;
    }
}
.js-is-animating.js-wall--is-open .js-wall-panel-inner {
    opacity:0;
    transition:opacity 260ms ease;
}
.js-wall--is-open .js-wall-panel-inner {
    opacity:1;
}

/*
 * Single row wall
 */
 .js-wall-row-trigger {
    width:100%;
    display:block;
    cursor:pointer;
    text-align:left;
}
.js-wall-row-child {
    visibility: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0); 
    overflow: hidden;
    border: 0;
    max-height:0;
    z-index: 2;
    background: #262626;
}
.js-wall-row-panel {
    position: absolute;
    top:auto;
    left:0;
    right:0;
    width:auto;
    background-color:shade($secondary-color, 40%);
    clear:left;
    overflow:hidden;
    visibility: hidden;
    height:0;
    will-change: height;
    transition: height 260ms ease;
}
.js-wall-row--is-open {
    will-change: margin-bottom;
    transition: margin-bottom 260ms ease;
}
.js-wall-row--is-open .js-wall-row-panel {
    visibility: visible;
    z-index:1;
}
.js-wall-row-panel-inner {
    opacity:0;
    will-change:opacity;
    transition:opacity 260ms ease;
}
.js-wall-row--is-open .js-wall-row-panel-inner {
    opacity:1;
}
.js-wall-row-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background:transparent;
}
.js-wall-row-close svg {
    fill:white
}

.js-wall-modal--on {
    position: fixed;
    top:50px;
    left:0;
    right:0;
    bottom:0;
    overflow-y: scroll;
    visibility: visible;
    z-index:$zIndex-2;
    overflow:inherit;
    height: auto;
    @media (min-width: $bp-medium) {
        top:116px;
    }
}

.js-wall-row-panel.js-wall-modal--on,
.js-wall-panel.js-wall-modal--on{
    display: block;
}
.js-wall-modal--on .js-wall-panel-inner,
.js-wall-modal--on .js-wall-row-panel-inner {
    padding-top:$baseline;
    opacity:1;
    height: 100%;
    overflow-y:scroll;
    position: static;
    -webkit-overflow-scrolling:touch;
}

.noscroll {
    position: fixed;
    overflow:hidden;
}