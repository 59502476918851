a, 
.btn, 
button,
[role=button]{
  transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out, opacity 0.2s ease-in-out, border 0.2s ease-in-out;
}

ellipse,circle, path {
  transition: fill 0.5s;
}
/*= Keyframe animations
-----------------------------------------------*/

@keyframes blink-animation {
  50% {
    opacity:0;
  }
}

@keyframes fade-in-out {
  0% { 
    opacity:0;
    } 
  50% { 
    opacity:1;
    }
  100% { 
    opacity:0;
    }
  }

.fade-in-out { 
  opacity:0;
  opacity: 1 \9; /*just in case ie*/
  animation:fade-in-out ease-in-out 1;
  animation-fill-mode:forwards;
  animation-duration:3s;
  animation-delay:0;
  }

@keyframes fade-in-up {
  from { 
    opacity:0;
    transform:  translate(0,20px)  
    } 
  to { 
    opacity:1;
    transform:  translate(0,0) 
    }
  }

.fade-in-up { 
  opacity:0;
  opacity: 1 \9; /*just in case ie*/
  animation:fade-in-up ease-out 1;
  animation-fill-mode:forwards;
  transform-origin: 50% 50%;
  animation-duration:.75s;
  animation-delay: .33s;
  }

.fade-in-up--1 { 
  animation-delay: .25s;
  }

.fade-in-up--2 { 
  animation-delay: .45s;
  }

.fade-in-up--3 { 
  animation-delay: .75s;
  }

.fade-in-up--4 { 
  animation-delay: 1s;
  }

/* Keyframes for the fade-in */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }


@mixin fade-in {
  opacity:0;
  opacity: 1 \9; /*just in case ie*/
  animation:fadeIn ease-in 1;
  animation-fill-mode:forwards;
  animation-duration:1s;
    }

.fade-in {
  @include fade-in;
  }

.fade-in--1 {
  animation-delay: .3s;
  }

.fade-in--2 {
  animation-delay: .6s;
  }

.fade-in--3 {
  animation-delay: .9s;
  }

.fade-in--4 {
  animation-delay: 1.2s;
  }

.fade-in--5 {
  animation-delay: 1.5s;
  }

.fade-in--6 {
  animation-delay: 1.8s;
  }

.fade-in--7 {
  animation-delay: 2.1s;
  }

.fade-in--8 {
  animation-delay: 2.4s;
  }

.fade-in--9 {
  animation-delay: 2.7s;
  }

.fade-in--10 {
  animation-delay: 3s;
  }

.fade-in--11 {
  animation-delay: 3.3s;
  }

/*= Thumbnail image overlay
-----------------------------------------------*/
.img-overlay{
	display:block;
}

.img-overlay__wrap{
    position: relative;
    overflow: hidden;
    display: block;
    animation: anima 2s;
    backface-visibility: hidden;
}

.img-overlay__image{
	transform: scale(1);
}

.img-overlay:hover .img-overlay__image,
.img-overlay:focus .img-overlay__image,
.img-overlay__wrap:hover .img-overlay__image,
.img-overlay__wrap:focus .img-overlay__image{
    transform: scale(1.03);
}

.img-overlay__wrap .img-overlay__image,
.img-overlay__wrap:hover img,
.img-overlay__wrap:focus img{
	 transition: all 0.8s ease;
}

.img-overlay__blend{
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  position: absolute;
  left:0;
  bottom:0;
  width:100%;
  height:50%;
}

.img-overlay__content{
  padding:$baseline;
  position: absolute;
  bottom:0;
  left:0;
  right:0;
}