.badge{
    background:$red;
    color:$white;
    display: inline-block;
    font-weight: $semibold;
    font-size:11px;
    padding:3px 4px;
    letter-spacing:1px;
    line-height: 1;
    text-align: center;
    text-transform:uppercase;
    white-space: nowrap;
    vertical-align: baseline;
}