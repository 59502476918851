// Path alias
$assetPath:                '/content';
$uiImgPath:                '#{$assetPath}/img';

$body-font-color:#232323 !default;
$primary-color:#002257 !default;
$secondary-color:#00a4e3 !default;
$tertiary-color:#adafb2 !default;
$dark-blue:#051b3f !default;

$country-report:#126474 !default;
$company-report:#85102E !default;
$commodity-report:#5A1B47 !default;
$asset-report:#776791 !default;
$insight-report:#14472A !default;

$light-grey-1: #f4f4f4;
$light-grey-2: #e6e6e6;
$light-grey-3: #cccccc;
$mid-grey-1: #b3b3b3;
$mid-grey-2: #999999;
$mid-grey-3: #808080;
$dark-grey-1: #666666;
$dark-grey-2: #4d4d4d;
$dark-grey-3: #333333;
$off-black:#232323;
$off-white:#e7e6dd;
$white:#ffffff;
$red:#EF5350;
$link:#00A0C4;
$link-hover:#2197CA;
$slate:#425A66;
$yellow:#EBA912;
$green:#2dbe60;

$zIndex-1:   100;
$zIndex-2:   200;
$zIndex-3:   300;
$zIndex-4:   400;
$zIndex-5:   500;
$zIndex-6:   600;
$zIndex-7:   700;
$zIndex-8:   800;
$zIndex-9:   900;
$zIndex-10: 1000;

// Type
// --------------------------------------------------
// Font weights
$thin:                      100;
$light:                     300;
$regular:                   400;
$semibold:                  500;
$bold:                      700;
$thick:                     900;

$baseline:                  1.5rem !default;
$base-font-family:          sans-serif !default;
$base-font-weight:          $light !default;
$base-font-size:            100% !default;
$base-line-height:          1.5 !default;
$body-font-family:       	$base-font-family !default;
$header-font-family:        $base-font-family !default;
$primary-font:              'Roboto', sans-serif !default;

// Sizes
$tera-size:                 2.8125rem !default;
$giga-size:                 2.12rem !default;
$mega-size:                 1.5rem !default;
$kilo-size:                 1.3rem !default;
$alpha-size:                1.125rem !default;
$beta-size:                 1rem !default;
$gamma-size:                .875rem !default;
$delta-size:                .75rem !default;
$epsilon-size:              .625rem !default;

// Layout
// --------------------------------------------------
$max-container-width:       1400px !default;
$gutter: 24px !default;
$columns: 12 !default;
$fallback-gutter: 4%;
$margin-left: ($gutter/2);
$margin-right: ($gutter/2);

// Shared
$border-radius:             0 !default;
$border-color:              none !default;
$border-width:              0 !default;

// Breakpoints
// --------------------------------------------------
$bp-small-max:				47.938em !default;
$bp-small-medium:          37.5em !default;
$bp-medium:                 48em !default;
$bp-large:                  60em !default;
$bp-xlarge:                 90.1em !default;

$bp-small--unitless:                 300;
$bp-small-medium--unitless:          600;
$bp-medium--unitless:                768;
$bp-large--unitless:                 960;
$bp-xlarge--unitless:                1400;