.global-search{
	margin-top: 3rem;
	position: relative;	
}

.global-search__input{	
	background: transparent;
	border-bottom: 1px solid #fff;
	color: $white;
	font-size: 1.4rem;
	font-weight: 300;
	line-height:normal;
	padding: 1rem 3rem 1rem 0;
	width: 100%;
	transition : border 500ms ease-out;
	&:focus{
		border-bottom:1px solid $secondary-color;
	}
    @media (min-width: $bp-medium){
        font-size: 2rem;
    }
	&::-webkit-input-placeholder {
		color: $white;
	}
	&::-moz-placeholder {
		color: $white;
	}
	&:-ms-input-placeholder {
		color: $white;
	}
	&:-moz-placeholder {
		color: $white;
	}
}

.global-search__btn{
    color: $white;
    font-size: 1.3rem;
    position: absolute;
    right: 0;
    top: 10px;
    @media (min-width: $bp-medium){
        font-size: 2rem;
    }
}

.search-overlay{
	background: rgba(0, 34, 87, 0.9);
	height: 100%;
	left: 0;
	opacity: 0;
	overflow: visible;
	padding: 0;
	position: fixed;
	right:0;
	top: 55px;
	transition: opacity .4s;
	width: 100%;
	z-index: -1;
	margin: 0 auto;
	@media (min-width: $bp-medium) {
		top: 118px;
	}
}

.on--search-modal{
	.search-overlay{
	    opacity: 1;
	    visibility: visible;
	    z-index: $zIndex-10;
	}
}

.search-inset{
	position:relative;
}

.search-inset__input{
	border: 1px solid $light-grey-2;
	font-size: $alpha-size;
	font-weight: $regular;
	padding: $baseline/1.5 $baseline;
	transition: box-shadow 500ms ease-out,background 500ms ease-out;
	width: 100%;
	&:focus{
		box-shadow: inset 0px -2px 0px 0px $secondary-color;
	}	
}

.search-inset__input--flush{
	border:0;	
}

.search-inset__input--small{
	font-size:$beta-size;
	padding:$baseline/2 $baseline*2 $baseline/2 $baseline/2;	
}

.search-inset__btn{
	position:absolute;
	right:15px;
	top:16px;
}

.search-inset__btn--small{
	top:16px;
}

.search-inset--store{
	.search-inset__input{
		&:focus{
			background:$white;
		}	
	}
	.search-inset__btn{
		svg{
			path{
				fill:$off-black;
			}
		}		
	}
}

.search-inset__input:focus + .search-inset__btn{
	svg{
		path{
			fill:$secondary-color;
		}		
	}
}