.in-beta{
    background:$white;
    padding-top:100px;
    @media (min-width: $bp-medium){
        padding-top:163px;
    }

    .banner {
        top:45px;
    }
    .nav-primary {
        @media (max-width: $bp-small-max){
            top: 100px;
        } 
    }
    .search-overlay{
        top: 100px;
        @media (min-width: $bp-medium) {
            top: 163px;
        }
    }
    .nav-tabs{
        @media (min-width: $bp-medium){
            &.is--stuck {
                top:163px;
            }
        }
    }
}

.notification{
	width:100%;
}

.notification--beta{
	background:$yellow;
	padding:$baseline/4 0;
	position: fixed;
	left:0;
	top:0;
}

.notification__beta-box{
	background-color: $white;
	color:$off-black;
	font-size:$kilo-size;
	padding:$baseline/4 $baseline/4 $baseline/8 $baseline/4;
	position:absolute;
	top:-6px;
	left:0;
	letter-spacing:1px;
	font-weight:$thick;
	text-transform: uppercase;
}

.notification__summary{
	color:$off-black;
	display:none;
	font-weight:$semibold;
	line-height:1.1;
	margin-bottom:$baseline/2;
	@media (min-width: $bp-small-medium) {
		display:inline-block;
		font-size:$delta-size;
		margin-left:$baseline*3.5;
		margin-bottom:0;
		width:40%;
		@include vertical-align;
	}
	@media (min-width: $bp-medium) {
		font-size:$gamma-size;
		width:48%;
	}
	@media (min-width: $bp-large) {
		font-size:$beta-size;
		width:auto;
	}
}

.notification__actions{
	display:inline-block;
	float:right;
}