// Buttons
//
// Button styles can be applied to anything with the `.btn` class applied.
// However, typically you'll want to apply these to only `<a>` and `<button>` elements for the best rendering.
//
// Style guide: buttons

// Base styles & states
//
// :hover       - Hover state.
// :active      - Active state.
// .btn--disabled - If you use <button> element, it is acceptable to add disabled attribute instead, can be used in conjunction with a skin class.
//
// Markup:
// <a class="btn {{modifier_class}}">Button</a>
// <button type="submit" class="btn {{modifier_class}}">Button</button>
//
// Weight: 1
//
// Style guide: buttons.base


// Sizing
//
// Fancy larger or smaller buttons?
// Add `.btn--large`, `.btn--small` for additional sizes.
//
// .btn--large - Large button.
// .btn--small - Small button.
// .btn--block - Block level button.
//
// Markup:
// <button type="submit" class="btn btn-primary {{modifier_class}}">Button</button>
// <button type="submit" class="btn {{modifier_class}}">Button</button>
//
// Weight: 2
//
// Style guide: buttons.sizing


// Skins
//
// Adds an additonal modifier class to style button
//
// .btn--primary   - Provides extra visual weight and identifies the primary action in a set of buttons.
// .btn--secondary - Used as an alternative to the default styles.
// .btn--tertiary - Used as an alternative to the default styles, always appears in conjuction with btn--small.
// .btn--link     - Deemphasize a button by making it look like a link while maintaining button behavior.
//
// Markup:
// <a href="#" class="btn {{modifier_class}}">Button</a>
// <button type="submit" class="btn {{modifier_class}}">Button</button>
//
// Weight: 3
//
// Style guide: buttons.skins

// Button Groups
//
// Use the `.btn-group` class on a `<div>` element surrounding several `<a>` elements with class `.btn`.
//
// Markup:
//<div class="btn-group">
//  <a href="#" class="btn">Button Link</a>
//  <a class="btn">Button Link</a>
//  <a class="btn">Button Link</a>
//</div>
//
// Weight: 4
//
// Style guide: buttons.groups



// Skip to content
// --------------------------------------------------
.skip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .btn-skip {
        height: 1px;
        width: 1px;
        position: absolute;
        overflow: hidden;
        top: -100px;
        left: 0;
        &:focus {
            position: fixed;
            height: auto;
            width: auto;
            margin:0 auto;
            display: block;
            left: 0;
            right: 0;
            top:70px;
            text-align: center;
            z-index:$zIndex-1;
        }
    }
}

// Button styles
// --------------------------------------------------

.btn {
    background-color:$light-grey-1;
    border:1px solid $light-grey-2;
    color: $dark-grey-2;
    cursor: pointer;
    display: inline-block;
    font-weight:$semibold;
    font-size:1.1rem;
    line-height:1.4;
    outline: none;
    padding:$baseline/2 $baseline;
    position: relative;
    text-align: center;
    text-decoration: none;

    &:hover {
        background-color:$light-grey-2;
        border:1px solid $light-grey-2;
        color: $dark-grey-3;
        }

    // Active state
    &:focus,
    &.active,
    &:active {
        background-color:$light-grey-1;
        color: $dark-grey-3;
    }
}

.btn--disabled,
button:disabled,
button[disabled]{
    opacity:0.6;
    cursor:not-allowed;
}

.btn--primary {
    background-color:$secondary-color;
    border-color:$secondary-color; 
    color:#fff;
    &:hover,
    &:focus,
    &:active {
        background-color:darken($secondary-color, 10%);
        border-color:$secondary-color; 
        color:#fff;
        }
    }

.btn--secondary {
    background-color:$white;
    border-color:$secondary-color;
    color:$secondary-color;
    font-size:1rem;
    &:hover,
    &:focus,
    &:active {
        background-color:tint($secondary-color, 90%);
        border-color:tint($secondary-color, 60%);
        color:darken($secondary-color, 10%);
        }
    }

.btn--tertiary {
    background-color:$white;
    border-color:$off-black;
    color:$off-black;
    font-weight: $bold;
    font-size:1rem;
    text-transform:uppercase;

    &:hover,
    &:focus,
    &:active {
        background-color:tint($off-black, 95%);
        border-color:tint($off-black, 60%);
        color:tint($off-black, 10%);
        }
    }

 .btn--reversed {
    border-color:$secondary-color;
    background-color:transparent;
    color:#46bbee;
    font-size:1rem;
    &:hover,
    &:focus,
    &:active {
        background-color:darken($secondary-color, 10%);
        border-color:$secondary-color;
        color:$white;
        }
    }   

.btn--pill{
    margin:0 $baseline/8;
    margin-bottom:$baseline/4;
    svg{
        polygon{
            fill:$mid-grey-3;
        }
        margin-left:$baseline/4;
    }
    &:hover{
        svg{
            polygon{
                fill:$dark-grey-3;
            }
        }        
    }
}

.btn--link {
    background-color:transparent;
    border-color:transparent;
    color:$link;
    
    &:hover,
    &:focus,
    &.active,
    &:active {
        background-color:transparent;
        color:tint($link, 20%)
        }
    &:visited {
        color:tint($link, 20%)
        }
        
    }

.btn-svg{
    &:hover {
        ellipse,circle {
          fill: shade($secondary-color, 20%);
        }
        path {
          fill: $white;
        }
    }
}

.btn-svg--pressed{
    ellipse,circle {
        fill: tint($green, 10%);
    }
    path {
        fill:$white;
    }
}

a:hover 
.btn-delete{
    background:$red;
    .outline {
      fill: $red;
    }
    path {
      fill: $white;
    }
}

.btn--large {
    @include mega;
    height:$baseline *2;
    line-height:$baseline *2;
    padding: 0 $baseline * 1.2;
    }

.btn--small {
    @include gamma;
    height:$baseline *1.4;
    line-height:$baseline *1.4;
    padding: 0 $baseline * .44;
    }

.btn--beta {
    background-color:shade($yellow, 5%);
    border-color:shade($yellow, 20%);
    color:$off-black;
    font-size:0.8rem;
    &:hover,
    &:focus,
    &:active {
        background-color:shade($yellow, 40%);
        border-color:shade($yellow, 40%);
        color:$white;
        }
    }

.btn--tags{
    @include delta;
    background-color:$light-grey-2;
    border:1px solid transparent;
    color: $dark-grey-3;
    text-transform: uppercase;
    font-weight: $regular;
    height:$baseline *1.4;
    line-height:$baseline *1.4;
    padding: 0 $baseline * .66;
    margin-right:$baseline/4;
    margin-top:$baseline/4;
    &:hover,
    &:focus {
        background-color:$light-grey-1;
        border:1px solid rgba(0,0,0, .05);
        color: $dark-grey-3;
        }
}

.btn-bookmark{
    cursor:pointer;
    position: absolute;
    right:10px;
    top:10px;
    z-index:$zIndex-1;
}

.btn--icon {
    padding-right:3.5rem;
    position:relative;
    overflow:hidden;
    .icon {
        color:#fff;
        fill:#fff;
        height:100%;
        right:14px;
        position:absolute;
        top:-1px;
        z-index:1;
    }

    // &:after {
    //     right: -6px;
    //     bottom:0;
    //     border: solid transparent;
    //     border-width: 70px 0px 164px 52px;
    //     border-color: transparent transparent  rgba(0,0,0,0.08) transparent;
    //     content: " ";
    //     height: 0;
    //     width: 0;
    //     position: absolute;
    //     pointer-events: none;
    //     transition: right .24s ease;   
    // }

    // &:hover{
    //     &:after{
    //         right:-50px;
    //     }
    // }
}

// Block button
// -------------------------

.btn--block {
    display:block;
    padding-left:$baseline/2;
    padding-right:$baseline/2;
    width:100%;
    }

// Toggle button
// -------------------------

.btn--icon-rotate{
    text-align: left;
    padding-left:$baseline*2;
	&:before{
		background:url("../img/icons/icon-chevron-right-large.svg") no-repeat;
		content:'';
		display:block;
		width:9px;
		height:15px;
		position:absolute;
		left:18px;
		top:16px;
		transition:0.2s all;
	}
    .active &{
        &:before{
            transform:rotate(90deg);
        }
    }    
}

// Block button
// -------------------------

.btn--collapse {
    display:block;
    background-color:transparent;
    border-left:0;
    border-right:0;
    margin-top:-1px;

    &:hover{
        border-left:0;
        border-right:0;
        }
    }

// Filter button
// -------------------------

.btn--filter {
    border:1px solid $light-grey-3;
    color: $dark-grey-3;
    font-size:1.05rem;
    font-weight:$regular;
    padding:$baseline/2 $baseline/2;
    text-align:center;
        svg{
           margin-left:$baseline/4;
           position: relative;
           top:-1px; 
        }
    }
// .active > .btn--filter:after {
//     content:'';
//     position: fixed;
//     top:0;
//     bottom:0;
//     left:0;
//     right:0;
//     z-index:2;
// }

// Load more button
// -------------------------
.btn-load{
    color:$link;
    cursor:pointer;
    display:inline-block;
    font-weight:$semibold;
    font-size:$alpha-size;
    margin:0 auto;
    transition-duration: 0.2s;
        svg{
            display:block;
            margin:$baseline/4 auto 0 auto;
            transform: translateZ(0);
            transition-duration: 0.2s;
            transition-property: transform;
            transition-timing-function: ease-out;
        }
    &:hover{
        color:$link-hover;
        svg{
            transform: translateY(4px);
            polygon{
                fill:$link-hover;
            }
        }
    }
    &.load-previous:hover{
        svg{
            transform: translateY(-4px);
        }
    }
}

// Button grid
// -------------------------

.btn--grid{
	background:$light-grey-1;
	border:none;
    color:$off-black;
    display:block;
    height:5rem;
    padding:$baseline/4;
    position:relative;
	&:hover{
		background:$light-grey-2;
        border:none;
	}
    @media (min-width: $bp-small-medium){
        height:4rem;
	}
    @media (min-width: $bp-medium){
        height:6rem;
	}
    @media (min-width: $bp-xlarge){
        height:4rem;
	}
}

.btn--grid__label{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:100%;
}

.btn-baseline{
	background:shade($slate, 40%);
	bottom:0;
	left:0;
	display:block;
	font-weight:$semibold;
	padding:$baseline/2 0;
	text-align:center;
	width:100%;
	position: absolute;
}

.btn .form--is-expanded {
    display: none;
}
.active > {
    .btn .form--is-collapsed {
        display: none;
    }
    .btn .form--is-expanded {
        display: block;
    }
}

.btn--arrow{
    .active &{
        background-color:tint($off-black, 10%);
        border-color:tint($off-black, 10%);
        &:after {
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid tint($off-black, 10%);
            bottom: -13px;
            content: '';
            height: 0;
            margin-right: -12px;
            right: 50%;
            position: absolute;
            transition: border 0.2s ease-in-out;
            width: 0;
            z-index:$zIndex-1;
        }
        &:hover,
        &:focus{
            &:after {
                border-top: 12px solid tint($off-black, 10%);
            }  
        }
    }
}

// Button groups
// -------------------------

.btn-group {
    @include clearfix();
    list-style: none outside none;
    .btn {
        @include border-radius(0);
        display:block;
        margin-right:-1px;
        }

    .active .btn {
        background-color:darken($light-grey-1, 20%);
        color: $dark-grey-3;
        }
        
    }
     
.btn-group > * {
    float: left;
    margin: 0 0 0 -1px;
    }
    
.btn-group > *:first-child {
    margin-left: 0;
    }

// Button toggles small screen navigation
// --------------------------------------------------

.btn--toggle{
    background:$white;
    border-left:1px solid $light-grey-2;
    color:$secondary-color;
    line-height:60px;
    position: absolute;
    height:55px;
    width:50px;
    text-align:center;
    top:0;
    z-index:999;
    @media (min-width: $bp-medium) {
        background:transparent;
        color:$dark-grey-3;
        line-height: 82px;
        height:78px;
        border-left: 1px solid $light-grey-2;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    &:before,
    &:after {
        z-index:10;
        height:2px;
        line-height:3px;
        width:21px;
        position: absolute;
        left:14px;
        background:$white;
        opacity:0;
        content:'';
        @media (min-width: $bp-medium) {
            background:$white;
            left:14px;
        }
    }
    &:before {
        top:27px;
        transform: rotate(45deg);
        @media (min-width: $bp-medium) {
            top:35px;
        }
    }
    &:after {
        top:34px;
        transform: rotate(-45deg) translateX(5px) translateY(-5px);
        @media (min-width: $bp-medium) {
            top:42px;
        }
    }
}

.product-link{
    position:relative;
    padding-right:$baseline;
    transition-duration: 0.2s;
}

.product-link__icon{
    position:absolute;
    top:4px;
    right:0;
    transform: translateZ(0);
    transition-duration: 0.2s;
    transition-property: transform;
    transition-timing-function: ease-out;

    .product-link:hover &{
        transform: translateX(4px);
    }
}

.btn--search{
    right:0;
    @media (min-width: $bp-medium) {
        right:$gutter;
    }
    @media (min-width: $bp-large) {
        min-width:118px;
        right:0;
    }
}

.btn--search__label{
    @include visuallyhidden;
    @media (min-width: $bp-large) {
        @include visible;
        position: relative;
        top: -2px;
    }   
}

.btn--search__close{
    display:none;
    position: relative;
    top: -2px;
}

.on--search-modal{
    .btn--search{
        background: rgba(0, 34, 87, 0.9);
        &:before,
        &:after{
            opacity:1;
        }
    }

    .btn--search__label{
        display:none; 
    }

    .btn--search__close{
        color:$white;
        @media (min-width: $bp-large) {
            display:inline-block;
        }  
    }

    .btn--search__icon{
        opacity:0;
    }
}

// Hamburger menu
// --------------------------------------------------
.btn-hamburger {
    color:$white;
    position: absolute;
    right: 50px;
    top: 0;
    height: 55px;
    line-height: 67px;
    width: 50px;
    text-align: center;
    z-index:$zIndex-10;
    @media (min-width: $bp-small-max) {
        display:none;
    }
    &:before,
    &:after {
        background:$off-black;
    }
}

.btn-hamburger--right{
    right:0;
}

.on--navigation {
    .btn-hamburger {
        color: $white;
        &:before,
        &:after {
            opacity:1;
        }
    }
    .btn-hamburger {
            svg {
                opacity:0;
            }
    }
    .btn-close {
        width: 100%;
        left: 410px;
        opacity:1;
        visibility:visible;
        background:transparent;
        z-index:3;
        cursor: pointer;
    }
}

.animating--navigation {
    .btn-hamburger {
        transition:opacity 60ms ease;
    }
}

// Cart button small screen navigation
// --------------------------------------------------

.btn--cart{
    display:none;
    border-left:0;
    border-right:1px solid $light-grey-2;
    right:99px;
    width:60px;
    &:before,
    &:after {
        left:18px;
    }
    @media (max-width: $bp-small-max) {
        display:block;
    }
}

.on--cart-menu{
    .btn--cart{
        background:$primary-color;
        &:before,
        &:after{
            opacity:1;
        }
    }

    .btn--cart__icon{
        opacity:0;
    }
}

.btn--cart__items{
    position:relative;
    top:-5px;
}


 .btn-svg--circle-white {
    background-color:$secondary-color;
    border-radius:50%;
    margin-right:$baseline / 2;
    &:hover {
        background-color:darken($secondary-color, 10%);
        color:$secondary-color;
    }
 }