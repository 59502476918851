// Colour
//
// Style guide: Colour

// Brand colours
//
//<div class="row push--ends js-equal-height">
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold white bg-primary-color swatch">Dark blue</div>
//    <div class="gamma">
//      <strong>Hex</strong> #002257 <br />
//      <strong>CSS</strong> .primary-color, bg-primary-color
//    </div>
//  </div>
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold white bg-secondary-color swatch">Bright blue</div>
//    <div class="gamma">
//      <strong>Hex</strong> #00a4e3 <br />
//      <strong>CSS</strong> .secondary-color, bg-secondary-color
//    </div>
//  </div>
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold bg-tertiary-color swatch">Cool grey</div>
//    <div class="gamma">
//      <strong>Hex</strong> #adafb2 <br />
//      <strong>CSS</strong> .tertiary-color, bg-tertiary-color
//    </div>
//  </div>
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold white bg-off-black swatch">Off black</div>
//    <div class="gamma">
//      <strong>Hex</strong> #232323 <br />
//      <strong>CSS</strong> .off-black, bg-off-black
//    </div>
//  </div>
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold bg-light-grey-1 swatch">Light grey</div>
//    <div class="gamma">
//      <strong>Hex</strong> #f8f8f8 <br />
//      <strong>CSS</strong> .light-grey-1, bg-light-grey-1
//    </div>
//  </div>
//</div>
//<p class="gamma">Grey is only for body copy online, drop shadows,instances where we need to convey the disabled status of links/buttons and for progress bars. It can’t be used for any other elements.</p>
//
//
// Weight: 1
//
// Style guide: Colour.Report colours

// Report colours
//
//<p class="gamma">Each report type has a distinct colour, these colours should only be applied to report components and PDP pages.</p>
//
//<div class="row push--ends js-equal-height">
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold white bg-country-report swatch">Country report</div>
//    <div class="gamma">
//      <strong>Hex</strong> #126474 <br />
//      <strong>CSS</strong> bg-country-report
//    </div>
//  </div>
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold white bg-company-report swatch">Company report</div>
//    <div class="gamma">
//      <strong>Hex</strong> #85102E <br />
//      <strong>CSS</strong> bg-company-report
//    </div>
//  </div>
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold white bg-commodity-report swatch">Commodity report</div>
//    <div class="gamma">
//      <strong>Hex</strong> #5A1B47 <br />
//      <strong>CSS</strong> bg-commodity-report
//    </div>
//  </div>
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold white bg-asset-report swatch">Asset report</div>
//    <div class="gamma">
//      <strong>Hex</strong> #776791 <br />
//      <strong>CSS</strong> bg-asset-report
//    </div>
//  </div>
//  <div class="col small-12 medium-4 push--bottom">
//    <div class="font-bold white bg-insight-report swatch">Insight report</div>
//    <div class="gamma">
//      <strong>Hex</strong> #14472A <br />
//      <strong>CSS</strong> bg-insight-report
//    </div>
//  </div>
//</div>
//
// Weight: 2
//
// Style guide: Colour.Brand colours

.bg-country-report{
    background-color:$country-report;
    .btn-baseline{
        background-color:shade($country-report, 30%);   
    }
    .js-wall-trigger,
    .js-wall-row-trigger{
        &:after {
            border-top: 18px solid shade($country-report, 30%);
        }
    }
}

.bg-company-report{
    background-color:$company-report;
    .btn-baseline{
        background-color:shade($company-report, 20%);   
    }
    .js-wall-trigger,
    .js-wall-row-trigger{
        &:after {
            border-top: 18px solid shade($company-report, 20%);
        }
    }
}

.bg-commodity-report{
    background-color:$commodity-report;
    .btn-baseline{
        background-color:shade($commodity-report, 20%);   
    }
    .js-wall-trigger,
    .js-wall-row-trigger{
        &:after {
            border-top: 18px solid shade($commodity-report, 20%);
        }
    }
}

.bg-asset-report{
    background-color:$asset-report;
    .btn-baseline{
        background-color:shade($asset-report, 40%);   
    }
    .js-wall-trigger,
    .js-wall-row-trigger{
        &:after {
            border-top: 18px solid shade($asset-report, 40%);
        }
    }
}

.bg-insight-report{
    background-color:$insight-report;
    .btn-baseline{
        background-color:shade($insight-report, 20%);   
    }
    .js-wall-trigger,
    .js-wall-row-trigger{
        &:after {
            border-top: 18px solid shade($insight-report, 20%);
        }
    }
}

.bg-yellow{
    background-color:$yellow;
}

.swatch{
    padding:$baseline;
    margin-bottom:$baseline/2;
    min-height:100px;
}

.primary-color {
    color:$primary-color;
    }

.bg-primary-color {
    background-color:$primary-color;
    }

.secondary-color {
    color:$secondary-color;
    }

.bg-secondary-color {
    background-color:$secondary-color;
    }

.tertiary-color{
    color:$tertiary-color;
}

.bg-tertiary-color{
    background-color:$tertiary-color;
}

.bg-primary-color-tint--10 {
    background-color:tint($primary-color, 90%);
}

.bg-primary-color-tint--60 {
    background-color:tint($primary-color, 40%);
}

.bg-primary-color-shade--20 {
    background-color:shade($primary-color, 20%);
}

.bg-secondary-color-shade--40{
    background-color:shade($secondary-color, 40%);   
}

.bg-secondary-color-shade--20{
    background-color:shade($secondary-color, 20%);   
}

.bg-secondary-color-tint--10 {
    background-color:tint($secondary-color, 90%);
}

.bg-slate{
    background-color:$slate;
}

.bg-slate-shade--20 {
    background-color:shade($slate, 20%);
}

// = Transparencies
//-----------------------------------------------------------------------------//

.bg-primary-color--20{
    background-color:rgba(0, 34, 87, 0.2);
}

// = Grayscale
//-----------------------------------------------------------------------------//
.light-grey-1 {
    color:$light-grey-1;
    }
.light-grey-2 {
    color:$light-grey-2;
    }
.light-grey-3 {
    color:$light-grey-3;
    }
.bg-light-grey-1 {
    background-color:$light-grey-1;
    }
.bg-light-grey-2 {
    background-color:$light-grey-2;
    }
.bg-light-grey-3 {
    background-color:$light-grey-3;
    }
.mid-grey-1 {
    color:$mid-grey-1;
    }
.mid-grey-2 {
    color:$mid-grey-2;
    }
.mid-grey-3 {
    color:$mid-grey-3;
    }
.bg-mid-grey-1 {
    background-color:$mid-grey-1;
    }
.bg-mid-grey-2 {
    background-color:$mid-grey-2;
    }
.bg-mid-grey-3 {
    background-color:$mid-grey-3;
    }
.dark-grey-1 {
    color:$dark-grey-1;
    }
.dark-grey-2 {
    color:$dark-grey-2;
    }
.dark-grey-3 {
    color:$dark-grey-3;
    }
.bg-dark-grey-1 {
    background-color:$dark-grey-1;
    }
.bg-dark-grey-2 {
    background-color:$dark-grey-2;
    }
.bg-dark-grey-3 {
    background-color:$dark-grey-3;
    }
.white {
    color:#fff;
    }
.bg-white {
    background-color:#fff;
    } 
.off-black {
    color:$off-black;
    }
.bg-off-black {
    background:$off-black;
    }

.green{
    color:$green;
}

/* Border position ---------------------- */    

.border {
    border:1px solid $light-grey-2;
    }

.border--top {
    border-top:1px solid $light-grey-2;
    }
        
.border--bottom {
    border-bottom:1px solid $light-grey-2;
    }

.border--bottom:last-child {
    border-bottom:10px solid $light-grey-2;
    }

.border--dotted {
    border-style:dotted;
    }

.border--top-thin {
    border-top:1px solid $light-grey-2;
    }

.border--ends {
    border-bottom:1px solid $light-grey-2;
    border-top:1px solid $light-grey-2;
    }

.border--top-opacity{
    border-top:1px solid rgba(255, 255, 255, 0.2);
}  

/* Border size ---------------------- */	

.border--2 {
	border-width:2px;
	}

/* Border style ---------------------- */       
.border--dotted {
    border-style:dotted;
    }