// Tables
//
// For basic styling—light padding and only horizontal dividers—add the base class `.table` to any `<table>`.
//
// Style guide: tables

// Table styles
//
// Add any of the following classes to the `.table` base class.
//
// .table--striped  - Adds zebra-striping to any table row within the `<tbody>` via the `:nth-child` CSS selector (not available in IE7-8).
// .table--plain  - Adds horizontal row underline only
// .table--hover  - Enable a hover state on table rows within a `<tbody>`.
//
// Markup:
// <table class="table {{modifier_class}}" summary="Interest rates start at 1.6% for Young Savers accounts and at 2% for Smart accounts">
//   <thead>
//     <tr>
//       <th>No.</th>
//       <th>First Name</th>
//       <th>Last Name</th>
//       <th>Username</th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <td>1</td>
//       <td>Tom</td>
//       <td>Patterson</td>
//       <td>TomP</td>
//     </tr>
//     <tr>
//       <td>2</td>
//       <td>Sandra</td>
//       <td>Watkins</td>
//       <td>SWatkins</td>
//     </tr>
//     <tr>
//       <td>3</td>
//       <td>David</td>
//       <td>Beech</td>
//       <td>DBeech</td>
//     </tr>
//   </tbody>
// </table>
//
// Style guide: tables.styles

.table {
	border-top:2px solid $dark-grey-1;
	margin:0 0 $baseline 0;
	width:100%;
		
	tr {
		border-bottom:1px solid $light-grey-2;
		}
		
	th {
		background-color:$light-grey-1;
		color:$dark-grey-1;
		font-family:$primary-font;
		font-style:normal;
		font-weight:normal;
		line-height:lh(.75);
		min-height:lh(2);
		padding:$baseline/2 3%;
		text-align:left;
		vertical-align:bottom;
		}		
	td {
		padding:$baseline/2 3%;
		text-align:left;
		}		 
	}
	
.table--plain {
	border-top:none;
	th {
		background-color:#fff;
		color:$dark-grey-3;
		}		 
	}

.table--striped {
  tbody {
    > tr:nth-child(even) > td,
    > tr:nth-child(even) > th {
      background-color: $light-grey-1;
    }
  }
}

.table--hover {
  tbody {
    tr:hover > td,
    tr:hover > th {
      background-color: tint($light-grey-1, 20%);
    }
  }
}