/* Navigation
-------------------------------------------------------------- */
//primary nav

.nav-primary {
    @media (max-width: $bp-small-max){
        bottom:0;
        display:none;
        background:$white;
		position: fixed;
		left: 0;
		right: 0;
		top: 55px;
        z-index:100;
    } 
    display:block;
    @media (min-width: $bp-large){
        text-align:center;
        position: relative;
    } 
    .on--navigation & {
        display:block;
        @media (max-width: $bp-small-max) {
            overflow-y: auto;
        }
    }
}

.nav-primary__inner{
    @media (min-width: $bp-large){
        display:inline-block;
    } 
}

.nav-primary__list {
	@include clearfix;
    @media (min-width: $bp-medium){
        float:right;
        margin-right:$gutter*4;
        padding-left:$baseline*2;
    }
    @media (min-width: $bp-large){
        float:none;
        margin-right: 0;
        padding-left:0;
    }
}

.nav-primary__item {
    @media (min-width: $bp-medium){
        float:left; 
        margin-left:$baseline;  
        @media (min-width: $bp-large){
            margin-right:$baseline/1.5;
            margin-left:$baseline/1.5;
        }
        @media (min-width: $bp-xlarge){
            margin-right:$baseline;
            margin-left:$baseline;
        }
        &:last-child{
            .nav-primary__link{
                margin-right:0;
            }
        }
    }
}

.nav-primary__link {
	color:$off-black;
    @media (max-width: $bp-small-max){
        border-bottom:1px solid $light-grey-2;
        display:block;
        font-size: $alpha-size;
        padding:$baseline/2 $baseline;
    } 
    @media (min-width: $bp-medium){
        display:block;
        font-size: $beta-size;
        font-weight:$light;
        line-height:78px;
		height:78px;
        position: relative;
    }

    @media (min-width: $bp-large){
        font-size: $alpha-size;
    }
    &.active{
        font-weight:$bold;
        &:hover{
            color:$off-black;            
        }
        &:before{
            @media (min-width: $bp-medium){
            background:$secondary-color;
            bottom:$baseline /1.5;
            display:block;
            content:'';
            width: 100%;
            height:1px;
            position: absolute;
            }            
        }
    }
}


/* Secondary nav -------------------------------------------------------------- */

.nav-secondary {
    @include clearfix;
	max-height: 0;
	overflow: hidden;
	width: 0;
    @media (max-width: $bp-small-max){
        width:auto;
    }
    @media (min-width: $bp-medium) {
		background-color: $primary-color;
		clip: rect(0 0 0 0);
		display: block;
		left: $gutter/2;
		max-height: none;
		overflow: hidden;
		padding: $baseline;
		position: absolute;
		text-align: left;
		top: 78px;
		visibility: hidden;
        width: calc(100% - #{$gutter});
        }
    @media (min-width: $bp-large){
        padding:$baseline*2 $baseline*3;
    }
}

.nav-secondary__link{
    @media (max-width: $bp-small-max) {
        border-bottom:1px solid $light-grey-1;
        color:$dark-grey-1;
        display:block;
        padding:$baseline/2 $baseline $baseline/2 $baseline*1.5;
    }    
    @media (min-width: $bp-medium) {
        color:$white;
        display:block;
        font-size:$gamma-size;
        font-weight:$light;
        padding:0 0 $baseline /4 0;
    }
    @media (min-width: $bp-large) {
        font-size:$beta-size;
    }
}

.nav-secondary__item {
    @media (max-width: $bp-small-max) {
        &:last-child{
            .nav-secondary__link{
                border-bottom:1px solid $light-grey-2;
            }
        }
    }
}

.nav-primary__item {
    @media (max-width: $bp-small-max) {
        position:relative;
    }
    @media (min-width: $bp-medium) {
        // &:hover,
        &.is--active {
            > .nav-secondary {
                clip: auto;
                height: auto;
                opacity:1;
                overflow: visible;
                visibility: visible;
                }
            }
        }
    }

.no-js{
 .nav-primary__item {
    @media (min-width: $bp-medium) {
        &:hover {
            > .nav-secondary {
                clip: auto;
                height: auto;
                opacity:1;
                overflow: visible;
                visibility: visible;
                }
            }
        }
    }   
}

.nav-secondary-toggle{
	background: $white;
	border-left: 1px solid $light-grey-2;
	display: block;
	height: 51px;
	line-height: 59px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	width: 51px;
    @media (min-width: $bp-small-max) {
        display:none;
    }

    svg{
        .active &{
            transform: rotate(90deg);
        }
    }
}

.nav-primary__item.active > .nav-secondary {
    @media (max-width: $bp-small-max){
        margin:0;
        max-height:1000px;
        }
    }

.nav-secondary__intro{
    @media (min-width: $bp-medium) {
        float: left;
        width:33.33333%;
        padding-right:$baseline;
    }
    @media (min-width: $bp-large) {
        padding-right:$baseline*2;
    }
    @media (min-width: 80rem) {
        width: 38%;
        padding-right:$baseline*4;
    }
}

.nav-secondary__title{
    @media (max-width: $bp-small-max) {
		color: $dark-grey-3;
		display: block;
		font-size: 1.1rem;
		font-weight: $semibold;
		padding: $baseline $baseline $baseline/2 $baseline*1.5;
    } 
    @media (min-width: $bp-medium) {
		color: $white;
		font-size: 1.2rem;
		font-weight: $light;
        margin-bottom:$baseline/2;
    } 
    @media (min-width: $bp-large) {
		font-size: 1.4rem;
    }  
}

.nav-secondary__col{
    @media (min-width: $bp-medium) {
        float: left;
        margin-right: $margin-right*2;
        width: calc(33.33333% - #{$margin-right*2});
    }
    @media (min-width: 80rem) {
        width: calc(28% - #{$margin-right*2});
    }
}

.nav-secondary__col,
.nav-secondary__intro{
    @media (min-width: $bp-medium) {
        opacity:0;
        transition: opacity 0.8s ease-in-out;
        .is--active &{
            opacity:1; 
        }
    }
}

.no-js{
    .nav-secondary__col,
    .nav-secondary__intro{
        @media (min-width: $bp-medium) {
            opacity:1;
        }
    }  
}

/* Nav utility -------------------------------------------------------------- */

.nav-utility{
    @media (max-width: $bp-small-max){
        background:$light-grey-1;
    }
    @media (min-width: $bp-medium){
		margin-right: $gutter;
		position: absolute;
		right: 0;
		text-align: right;
		top: -30px;
    }
    @media (min-width: $bp-large){
        margin-right:$gutter/2;
    }
}

.nav-utility__link{
    @media (max-width: $bp-small-max){
		border-bottom: 1px solid $light-grey-2;
		color: $dark-grey-2;
		display: block;
		padding: $baseline/2 $baseline;
    }
    @media (min-width: $bp-medium){
		color: $white;
		display: inline-block;
		float: left;
		font-size: $gamma-size;
		margin-left: $baseline;
		position: relative;
        &:hover{
            color:$light-grey-3;
        }
        &:last-of-type{
            &:before{
                background: tint($primary-color, 40%);
                content: '';
                display: block;
                height: 12px;
                left: -13px;
                position: absolute;
                top: 5px;
                width: 1px;
            }
        }
    }
}

/* Nav tabs -------------------------------------------------------------- */

.nav-tabs__container {
    height:67px;
}
.nav-tabs{
    will-change: transform;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	-webkit-overflow-scrolling: touch;
	background-color: rgba(0, 0, 0, 0.3);
    box-shadow: inset 1px 0px 18px 1px rgba(0,0,0,0.3);
	overflow-x: auto;
	padding: $baseline/2 0;
	white-space: nowrap;
    @media (min-width: $bp-medium){
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow:none;
    }
    @media (min-width: $bp-medium){
        &.is--stuck {
            background-color: rgba(0, 0, 0, 0.9);
            position:fixed;
            top:118px;
            width:100%;
            z-index: $zIndex-10;
        }
    }
}

.nav-tabs__list{
    @include clearfix;
    margin-left:$gutter/2;
    margin-right:$gutter/2;
}

.nav-tabs__item{
    display:inline-block;
}

.nav-tabs__link{
	color: $white;
	display: block;
	font-size: $alpha-size;
	font-weight: $light;
	margin-right: $baseline*1.5;
	padding: $baseline/3 0;
	position: relative;
    .active &{
        color:$white;
        font-weight:$semibold;
        &:after{
			background: $secondary-color;
			bottom: 3px;
			content: '';
			display: block;
			height: 2px;
			position: absolute;
			width: 100%;
        }
    }
}

.banner__kicker{
    display:none;
    @media (min-width: $bp-medium){
		color: $light-grey-2;
		display: block;
		font-size: $gamma-size;
		left: $gutter;
		position: absolute;
		top: -30px;
    }
}