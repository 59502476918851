.filter{
	margin-bottom:$baseline/6;
	position: relative;
	width:100%;
	@media (min-width: $bp-medium){
		z-index:$zIndex-2;
	}
	@media (min-width: $bp-small-medium){
		float:left;
		margin: 0 $baseline/4;
		width:auto;
	}
	.btn{
		width:100%;
		@media (min-width: $bp-small-medium){
			width:170px;
		}
		@media (min-width: $bp-medium){
			width:220px;
		}
	}
}

.filter__inner{
	@media (min-width: $bp-small-medium){
		display:inline-block;
	}
}

.filter__panel{
	background:$light-grey-1;
	border:1px solid $light-grey-3;
	position: absolute;
	left:0;
	top:45px;
	opacity:0;
	height:0;
	z-index:$zIndex-3;
	visibility: hidden;
	width:100%;
	@media (min-width: $bp-small-medium){
		width:170px;
	}
	@media (min-width: $bp-medium){
		width:220px;
	}
	.active &{
		opacity:1;
		height:auto;
		visibility: visible;
	}	
}
.filter__close {
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	z-index:$zIndex-1;
	display:none;
}
.filter__close.active {
	display: block;
}