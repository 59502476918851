.related{
	background-color:$dark-grey-3;
	margin-bottom:$baseline;
	@media (min-width: $bp-medium){
		background-color:transparent;
	}
	.badge{
		z-index:99;
		margin:$baseline 0 $baseline/2 $baseline;
		@media (min-width: $bp-medium){
			margin:0;
			position: absolute;
			top:$baseline;
			left:$baseline;
		}
	}
}
.related--flexi{
	@media (min-width: $bp-medium){
		background-color:transparent;
		display: flex;
		width:33.33%;
		margin:0;
		padding:$baseline/4;
	}	
}
.related__ratio{
	@include maintain-ratio(5 3);
	@media (min-width: $bp-small-medium){
		@include maintain-ratio(1 1);
	}
	@media (min-width: $bp-medium){
		@include maintain-ratio(5 3);
	}
}

.related__ratio--2x1{
	@media (min-width: $bp-medium){
		@include maintain-ratio(2 1);
	}
}

.related__content{
	@media (min-width: $bp-small-medium){
		float:left;
		width:60%;
	}
	@media (min-width: $bp-medium){
		float:none;
		width:auto;
	}
}

.related--2col{
	@media (min-width: $bp-medium){
		width:50%;
	}
}

.related__link{
	@include clearfix;
	cursor:pointer;
	width:100%;
	position:relative;
	@media (min-width: $bp-medium){
		flex: 1 0 auto;
	}
}

.related__image{
	@media (min-width: $bp-small-medium){
		float:left;
		width:40%;
	}
	@media (min-width: $bp-medium){
		float:none;
		width:auto;
	}
	background-size: cover;
	background-position: center center;	
}

.related__summary{
	background:$dark-grey-3;
	padding:0 $baseline $baseline $baseline;

	@media (min-width: $bp-medium){
		padding:$baseline;
	}

    @media (min-width: $bp-large){
		background-color:rgba(0, 0, 0, 0.6);
		bottom:0;
		position: absolute;
		width:100%;
	}
}

.related--report{	
	.related__content{
		float:none;
		padding-bottom:$baseline;
	    @media (min-width: $bp-medium){
			padding-bottom:0;
		}
	}	
	.related__summary{
		background-color:transparent;
		margin-bottom:$baseline*2;
		position:static;
	    @media (min-width: $bp-medium){
			padding-top:$baseline*2;
		}
	    @media (min-width: $bp-large){
			padding-top:$baseline*2;
		}
	}
}

.related--event{
	.related__summary{
		width:100%;
	    @media (min-width: $bp-xlarge){
			top:0;
			width:60%;
			padding-top:$baseline*3;
		}
	}
}