// Basic form
//
// Covers styles used for forms, such as the `<input>` and `<select>` elements.
//
// Markup:
//<form class="form" role="form">
//    <div class="form-row">
//      <label class="form-control-label" for="exampleText1">Textfield</label>
//      <span class="gamma">(Optional)</span>
//      <input class="form-control" type="text" id="exampleText1" name="exampleText1" />
//    </div>
//    <div class="form-row">
//      <label class="form-control-label" for="exampleInputEmail1">Email address</label>
//      <input class="form-control" type="email" placeholder="Enter email" title="Example help text" />
//      <p class="gamma">Example help text</p>
//    </div>
//    <div class="form-row">
//      <label class="form-control-label" for="exampleInputPassword1">Password</label>
//      <input class="form-control" type="password" placeholder="Password" id="exampleInputPassword1" />
//    </div>
//    <div class="form-row" tabindex="7">
//      <label class="form-control-label" for="exampleInputPassword2">Confirm Password</label>
//      <input class="form-control" type="password" placeholder="Confirm Password" id="exampleInputPassword2" />
//    </div>
//    <div class="form-row">
//      <label class="form-control-label" for="exampleTextarea1">Text area</label> 
//      <textarea class="form-control" cols="30" rows="4" id="exampleTextarea1"></textarea>  
//    </div>
//    <div class="form-row">
//      <label class="form-control-label" for="exampleDropdown1">Dropdown</label>  
//      <select class="form-select" id="exampleDropdown1">
//        <option value="">Please select ...</option>
//        <option value="Edinburgh">Edinburgh</option>
//        <option value="New York">New York</option>
//        <option value="London">London</option>
//      </select>
//    </div>
//    <div class="form-row">
//      <label for="exampleInputFile">File input</label>
//      <input type="file" id="exampleInputFile" />
//    <div>
//    <div class="form-row">
//      <fieldset>
//        <legend>Checkbox</legend> 
//        <div class="checkbox">
//            <div class="checkbox__item">
//                <input id="checkbox" class="checkbox__input" type="checkbox">
//                <label for="checkbox" class="checkbox__label">
//                  This is a custom styled checkbox
//                </label>
//            </div>
//        </div>
//      </fieldset>
//    </div>
//    <div class="form-row">
//      <fieldset>
//        <legend>Radio</legend> 
//        <div class="radio">
//            <div class="radio__item">
//                <input id="radio" class="radio__input" type="radio" value="One">
//                <label for="radio" class="radio__label">
//                  This is a custom styled radio button
//                </label>
//            </div>
//        </div>
//      </fieldset>
//    </div>
//    <div class="form-row">
//      <fieldset>
//        <legend>Toggle checkbox</legend>
//          <div class="checkbox">
//              <div class="checkbox__item">
//                  <input type="checkbox" id="sector-filter-1" name="sector-filter" value="Asset report" class="checkbox__input js-toggle-input" aria-expanded="false">
//                  <label for="sector-filter-1" class="checkbox__label">Asset report</label>
//                  <ul class="push-half--left panel-toggle js-toggle-input__target">
//                      <li class="checkbox__item">
//                          <input type="checkbox" id="sector-filter-2" name="sector-filter" value="Field" class="checkbox__input">
//                          <label for="sector-filter-2" class="checkbox__label">Field</label>
//                      </li>
//                      <li class="checkbox__item">
//                          <input type="checkbox" id="sector-filter-3" name="sector-filter" value="Unconventional play" class="checkbox__input">
//                          <label for="sector-filter-3" class="checkbox__label">Unconventional play</label>
//                      </li>
//                      <li class="checkbox__item">
//                          <input type="checkbox" id="sector-filter-4" name="sector-filter" value="Exploration basin" class="checkbox__input">
//                          <label for="sector-filter-4" class="checkbox__label">Exploration basin</label>
//                      </li>
//                  </ul>
//              </div>
//          </div>
//          <div class="checkbox">
//              <div class="checkbox__item">
//                  <input type="checkbox" id="sector-filter-5" name="sector-filter" value="Commodity market report" class="checkbox__input js-toggle-input" aria-expanded="false">
//                  <label for="sector-filter-5" class="checkbox__label">Commodity market report</label>
//                  <ul class="push-half--left panel-toggle js-toggle-input__target">
//                      <li class="checkbox__item">
//                          <input type="checkbox" id="sector-filter-6" name="sector-filter" value="Short-term outlook (weekly update)" class="checkbox__input">
//                          <label for="sector-filter-6" class="checkbox__label">Short-term outlook (weekly update)</label>
//                      </li>
//                      <li class="checkbox__item">
//                          <input type="checkbox" id="sector-filter-7" name="sector-filter" value="Short-term outlook" class="checkbox__input">
//                          <label for="sector-filter-7" class="checkbox__label">Short-term outlook</label>
//                      </li>
//                      <li class="checkbox__item">
//                          <input type="checkbox" id="sector-filter-8" name="sector-filter" value="Long-term outlook" class="checkbox__input">
//                          <label for="sector-filter-8" class="checkbox__label">Long-term outlook</label>
//                      </li>
//                  </ul>
//              </div>
//          </div>
//        </div>
//      </fieldset>
//    </div>
//  <button type="submit" class="btn btn--primary">Submit</button>
//</form>
//
// Weight: 1
//
// Style guide: Forms.base

/* Forms
-------------------------------------------------------------- */
label,
.label {
    cursor:pointer;
    display:block;
    margin-bottom:.5rem;
    font-size: 99%;
    width:100%;
}

input,
textarea {
    display:block;
    padding:0 $gutter/2;
    line-height:1.25rem;
    min-height:2.5rem;
    border:$border-width solid $border-color;
    border-radius:$border-radius;
}

legend {
    font-size: 1rem;
    margin-bottom:$baseline/2;
}

.form-control-label {
    @include gamma;
    color:$off-black;
    display:block;
    margin-bottom: $baseline / 6;
    }

.form-control {
    background:$light-grey-1;
    border:1px solid $light-grey-3;
    border-radius:2px;
    color:$off-black;
    font-size:1rem;
    margin-bottom:$baseline / 3;
    max-width:100%;
    padding:$baseline / 2 $baseline / 2;
    position:relative;
    transition: border-color 0.15s ease;
    width:100%;
    &:hover {
        border-color:$light-grey-3;
        }
    &:focus {
        background:$white;
        border-color:$link;
        color:$off-black;
        outline: none;
        }
    }

.form-control--small{
    max-width:3rem;
}

.form-row {
    margin-bottom:$baseline;
    clear:both;
}

.form-row-checkbox,
.form-row-radio {
    position:relative;
    padding-left:2rem;
    input {
        position: absolute;
        left:0;
        top:.5rem;
    }
}

@mixin form-select {
    @include beta;
    background:$light-grey-1 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11.4" height="7.1" viewBox="0 0 11.4 7.1"><title>Fill 1 Copy</title><path d="M11.4 1.1L10.3 0 5.7 4.9 1.1 0 0 1.1l5.8 6"/></svg>') no-repeat 90% center;
    border:1px solid $light-grey-3;
    color:$dark-grey-3;
    display: inline-block;
    font-family:$primary-font;
    max-width: 100%;
    padding:$baseline / 2.5 $baseline $baseline / 2.5 $baseline / 2;
    vertical-align: middle;
    &:focus {
        border-color:$link;
        outline: none;
        }
    }

.form-select {
    @include form-select;
    }

.form-select--cart{
    background: transparent url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11.4px" height="7.1px" viewBox="0 0 11.4 7.1" enable-background="new 0 0 11.4 7.1" xml:space="preserve"><title>Fill 1 Copy</title><path fill="#FFFFFF" d="M11.4,1.1L10.3,0L5.7,4.9L1.1,0L0,1.1l5.8,6"/></svg>') no-repeat 90% center;    
    border:0;
    color:$white;
    font-size:inherit;
    padding:0 $baseline 0 $baseline / 4;
    option{
        background:$primary-color;
        border:0;
        padding:$baseline / 4 $baseline $baseline / 4 $baseline / 4;
    }
}

.sorting{
    float:left;
    width:50%;
    @media (min-width: $bp-small-medium){
        display:inline-block;
        float:left;
        width:auto;
    }
}

.sorting__input{
    @media (min-width: $bp-small-medium){
        margin-right:$baseline/2;
    }
}

@media screen and (min-width:0\0) and (min-resolution:.001dpcm) {
    //ie9 hack
    .form-select {
        padding-right:10px !important;
        }
    .form-select__icon {
        display:none;
        }
    }

.form--lined{
    input{
        padding:$baseline/4 0 $baseline/4 0;
    }

    input,
    textarea,
    select {
        background:transparent;
        border-bottom:1px solid $light-grey-3;
        width:100%;
        font-size:$beta-size;
        font-weight:$regular;
        transition:border 500ms ease-out;
        &:focus{
            border-bottom:1px solid $secondary-color;
        }
        &::-webkit-input-placeholder {
        color: $light-grey-3;
        }
        &::-moz-placeholder {
        color: $light-grey-3;
        }
        &:-ms-input-placeholder {
        color: $light-grey-3;
        }
        &:-moz-placeholder {
        color: $light-grey-3;
        }
    } 

    select{
        background-color:$off-black;
        background-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="11.4" height="7.1" viewBox="0 0 11.4 7.1"><title>Fill 1 Copy</title><path fill="#ffffff" d="M11.4 1.1L10.3 0 5.7 4.9 1.1 0 0 1.1l5.8 6"/></svg>');
        background-repeat:no-repeat;
        background-position:90% center;
        border:1px solid $light-grey-3;
        color:$light-grey-3;
        transition:border 500ms ease-out;
        &:focus{
            border:1px solid $secondary-color;
            color:$white;
        }
    }

    textarea{
        color:$white;
        padding:$baseline/4; 
        &:focus{
            background-color:transparent;
            color:$white;
        }
    }
}

.form--inset{
    position:relative;
    .form__input{
        border:1px solid $white;
        font-size:1.1rem;
        font-weight:$regular;
        line-height:1.4;
        padding:$baseline/2 $baseline $baseline/2 $baseline;
        width:100%;
        margin-bottom:$baseline/4;
        @media (min-width: $bp-small-medium){
            padding:$baseline/2 $baseline*8 $baseline/2 $baseline;
            margin:0;
        }
    }
    .form__btn{
        display:block;
        width:100%;
        @media (min-width: $bp-small-medium){
            width:auto;
            position: absolute;
            right:0;
            top:0;
        }
    }
}

.checkbox,
.radio{
    @include clearfix;
    list-style-type:none;
}

.checkbox__item,
.radio__item{
    padding-bottom:$baseline/4;
}

.checkbox__input,
.radio__input{
    opacity: 0;
    position: absolute;   
}

.checkbox__label,
.checkbox__input,
.radio__label,
.radio__input{
    display: inline-block;
    vertical-align: middle;
    text-align:left;
    cursor: pointer;
}

.checkbox__label,
.radio__label{
    color:$dark-grey-3;
    font-size:$beta-size;
    font-weight:$regular;
    padding-left:$baseline*1.2;
    position: relative;
    &:before{
        content: '';
        background:$light-grey-1;
        border: 1px solid $mid-grey-2;
        display: inline-block;
        left:0;
        position: absolute;
        width: 20px;
        height: 20px;
        padding: 2px;
        text-align: center;
    }

}

.checkbox__input:checked ~ .checkbox__label:before,
.radio__input:checked ~ .radio__label:before {
    background: $white;
    background-image:url("../img/icons/icon-tick.svg");
    background-repeat:no-repeat;
    background-position: center center;
    color: $dark-grey-1;
    content: "";
}

.checkbox__item input:focus ~ .checkbox__label:before,
.radio__item input:focus ~ .radio__label:before {
    border:1px solid $link;
}

.radio__label{
    &:before{
        @include border-radius(100%);
    }
}

.radio__input:checked + .radio__label:before {
    background-image:url("../img/icons/icon-radio.svg");
}

.checkbox--store{
    padding:0; 
    .checkbox__item{
        padding:0;
        margin-bottom:$baseline/4;
    }  
    .checkbox__label{
        font-size:$delta-size;
        line-height:21px;
        margin-bottom:0;
        text-transform:uppercase;
        background:$white;
        padding:$baseline/2 $baseline/2 $baseline/2 $baseline*2;
        margin-bottom:$baseline/4;
        transition: color 0.2s ease-in-out,background-color 0.2s ease-in-out;
        &:before{
            left:$baseline/2;
        }
    } 

    .checkbox__input:checked + .checkbox__label {
        background: $dark-grey-1;
        color:$white;
    }
    .checkbox--store-more {
        @include visuallyhidden;
    }
    &.active {
        .checkbox--store-more {
            @include visible;
        }
        .checkbox--store-more__btn {
            display: none;
        }
    }
}

.field-validation-error{
    @include gamma;
    color:$red;
    font-weight:$regular;
}

.input-validation-error{
    border:1px solid $red;
}

.validation-summary{
    background:tint($red, 90%);
    padding:$baseline;

    .list{
        counter-reset: numbers;
    }

    .list__item{
        @include gamma;
        font-weight:$semibold;
        margin-bottom:$baseline /4;
        padding-bottom:$baseline /4;
        padding-left:1rem;
        position:relative;

        &:before{
	        content: counter(numbers);
	        counter-increment: numbers;
            position:absolute;
            left:0;
            top:0;
        }

        &:last-child{
	        margin-bottom:0;
            padding-bottom:0;
        }

        a{
            color:$red;

            &:hover{
                color:shade($red, 20%);
            }
        }
    }
}
.hide-title .Form__Title {
    display:none;
}
